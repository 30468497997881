import React, { useEffect, useState } from "react";
import { Button, DatePicker, Input, Modal, Table, Space, message, Typography } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { FileSpreadsheet } from "lucide-react";

const API_BASE_URL = "https://parnetslink.xyz/api";
const { Title } = Typography;

const ClubCustomers = () => {
  const token = sessionStorage.getItem("token");

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (customers.length > 0) {
      applyFilters();
    }
  }, [customers, searchText, dateRange]);

  const fetchCustomers = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${API_BASE_URL}/user/getAllUser`,{ headers: {
        Authorization: `Bearer ${token}`,
      }});
      if (response.status === 200) {
        const clubCustomers = response.data.success.filter((e) => e.club === true);
        setCustomers(clubCustomers);
        setFilteredCustomers(transformData(clubCustomers));
      }
    } catch (error) {
      message.error("Failed to fetch customers");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleStatusChange = async (userId, action) => {
    setLoading(true); // Start loading
    try {
      const endpoint = action === 'block' ? 'changeBlockStatus' : 'changeunBlockStatus';
      const response = await axios.get(`${API_BASE_URL}/user/${endpoint}/${userId}`,{ headers: {
        Authorization: `Bearer ${token}`,
      }});
      if (response.status === 200) {
        toast.success(`User ${action === 'block' ? 'blocked' : 'unblocked'} successfully`);
        fetchCustomers();
      }
    } catch (error) {
      toast.error("Failed to change user status");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const showDeleteConfirm = (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteUser(userId);
      },
    });
  };

  const deleteUser = async (userId) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.delete(`${API_BASE_URL}/user/deleteUser/${userId}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      if (response.status === 200) {
        toast.success("User deleted successfully");
        fetchCustomers();
      }
    } catch (error) {
      toast.error("Failed to delete user");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const transformData = (data) => {
    return data.map((item, index) => ({
      key: item._id,
      Sno: index + 1,
      ID: item._id,
      name: item.name,
      email: item.email,
      mobile: item.mobile,
      Amobile: item.Amobile,
      houseno: item.Houseno,
      address1: item.address1,
      address2: item.address2,
      landmark: item.landmark,
      pincode: item.pincode,
      Status: item.status,
      RegisteredDate: moment(item.createdAt).format("DD/MM/YYYY"),
      Date: item.createdAt,
      action: (
        <Space>
          <Button
            type={item.status === "Approved" ? "primary" : "danger"}
            onClick={() => handleStatusChange(item._id, item.status === "Approved" ? "block" : "unblock")}
          >
            {item.status === "Approved" ? "Block" : "Unblock"}
          </Button>
          {/* <Button type="danger" onClick={() => showDeleteConfirm(item._id)}>
            Delete
          </Button> */}
        </Space>
      ),
    }));
  };

  const columns = [
    { title: "SNo", dataIndex: "Sno", width: 80 },
    { title: "CustomerID", dataIndex: "ID", width: 150 },
    { title: "Name", dataIndex: "name", width: 150 },
    { title: "Email", dataIndex: "email", width: 200 },
    { title: "Mobile", dataIndex: "mobile", width: 150 },
    { title: "Alternative Mobile", dataIndex: "Amobile", width: 150 },
    { title: "House No", dataIndex: "houseno", width: 120 },
    { title: "Address 1", dataIndex: "address1", width: 200 },
    { title: "Address 2", dataIndex: "address2", width: 200 },
    { title: "Landmark", dataIndex: "landmark", width: 150 },
    { title: "Pincode", dataIndex: "pincode", width: 120 },
    { title: "Status", dataIndex: "Status", width: 120 },
    { title: "Registered Date", dataIndex: "RegisteredDate", width: 150 },
    { title: "Action", dataIndex: "action", width: 200 },
  ];

  const applyFilters = (dates) => {
    let filteredData = customers;
    if (searchText) {
      filteredData = filteredData.filter((customer) =>
        Object.values(customer).some((val) =>
          val && typeof val === 'string' && val.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
    filteredData = filteredData.filter((record) => {
      const startDate =
        dateRange && dateRange[0] ? new Date(dateRange[0].startOf("day").toDate()) : null;
      const endDate =
        dateRange && dateRange[1] ? new Date(dateRange[1].endOf("day").toDate()) : null;
      const recordDate = new Date(record.createdAt);

      const result =
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate);

      return result;
    });
    

    if (filteredData.length === 0) {
      message.warning("No customers found with the applied filters.");
    }

    setFilteredCustomers(transformData(filteredData));
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };
  const exportToExcel = () => {
    const dataToExport = filteredCustomers.map(({ action, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Club Customers");
    XLSX.writeFile(workbook, "club_customers.xlsx");
  };

  return (
    <div>
      <ToastContainer />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
        <Title level={3} className="title">Club Members</Title>
        <Space>
          <Input
            placeholder="Search customers"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200 }}
          />
          <DatePicker.RangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
          />
          <Button type="primary" onClick={exportToExcel}   icon={<FileSpreadsheet size={16} />}>
            Export to Excel
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={filteredCustomers}
        scroll={{ x: 'max-content' }}
        pagination={{
          pageSize: 5,
          showSizeChanger: true, 
        }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),}}}
        loading={loading} // Add loading state to the table
      />
    </div>
  );
};

export default ClubCustomers;
