import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
export default function ClubLoginForm() {
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);
  const token = sessionStorage.getItem("token");
  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/user/loginclubUser",
        method: "post",
        baseURL: "https://parnetslink.xyz/api",
        hearder: { "content-type": "application/json" },
         headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          email: email,
          password: password,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          if (res.data.success.status === "pending") {
            toast.info("You're account is not approved yet"
            );
          } else {
            if (res.data) {
              toast.info("Login success"
              );
              sessionStorage.setItem("clubuser", JSON.stringify(res.data.success));
              window.location.assign("/");
            } else {
              handleerror();
            }
          }
        }
      });
    } catch (error) {
      toast.info(error.response.data.error
      );
      ////console.log(error.response.data.error);
    }
  };
  const forgetPassword = async () => {
if(!email){
  toast.info("Please enter email address"
  );
} else {
    try {
      const config = {
        url: "/clubuserForgetPwd",
        method: "post",
        baseURL: "https://parnetslink.xyz/api/user",
        headers: { "content-type": "application/json" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {email:email},
      };
      let res = await axios(config);
      if (res.status === 200) {
        toast.info("Successfully sent new password to your mail"
        );
        window.location.reload(true);
      } else {
        toast.info("Something Wrong"
        );
      }
    } catch (error) {
      if (error.response) {
        toast.info(error.response.data.error
        );
      }
    }
  }
  };
  return (
    <div>
        <ToastContainer />
      <div class="logo text-center"></div>
      <div class="wrapper">
        <div class="inner-warpper text-center">
          <h4 class="title">Login to Your Club Account</h4>

          <div class="input-group">
            <input
              class="form-control1"
              name="userName"
              id="userName"
              type="text"
              placeholder="Email / Mobile Number"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></input>
            <span class="lighting"></span>
          </div>
          <div className="input-group2">
      <input
        className="form-control2"
        name="userPassword"
        id="userPassword"
        type={!showPassword ? "text" : "password"}
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <span
        className="password-toggle-icon2"
        onClick={togglePasswordVisibility}
      >
        <i
          className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}
        ></i>
      </span>
    </div>

          <div class="clearfix supporter">
            {/* <div class="pull-left remember-me">
          <input id="rememberMe" type="checkbox"/>
          <label for="rememberMe">Remember Me</label>
        </div> */}
            <a class="forgot pull-right" onClick={()=>forgetPassword()}>
              Forgot Password?
            </a>
          </div>
          <br />
          <button
            class="button1"
            type="submit"
            id="login"
            onClick={handleClick}
          >
            Login
          </button>
        </div>
        <div class="signup-wrapper text-center" style={{fontSize:"20px"}}>
          <a href="/clubregister">
            Don't have an account? <span class="text-primary" style={{fontSize:"20px"}}>Sign up</span>
          </a>
        </div>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src="../img/success.png"
                style={{ margin: "auto" }}
              width="100px"
            />

            <p>Login Successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src="../img/close.png"
                style={{ margin: "auto" }}
              width="100px"
            />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
