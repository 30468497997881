import React, { useEffect, useState } from "react";
import { Button, DatePicker, Input, Modal, Space, Table, Typography } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { getUsers } from "../features/cutomers/customerSlice";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import * as XLSX from "xlsx";
import moment from "moment/moment";
import { toast, ToastContainer } from "react-toastify";
import { FileSpreadsheet, Search, X } from "lucide-react";
const { Title } = Typography;

const columns = [
  {
    title: "SNo",
    dataIndex: "Sno",
    width:100
  },
  {
    title: "DriverID",
    dataIndex: "ID",
    width:150
  },
  {
    title: "Name",
    dataIndex: "name",
    width:150
  },
  {
    title: "Email",
    dataIndex: "email",
    width:150
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    width:150
  },
 
  { title: "Registered Date", dataIndex: "RegisteredDate", width: 150 },
  // {
  //   title: "Status",
  //   dataIndex: "Status",
  //   width:150
  // },
  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   width:150
  // },
];

const Drivers = () => {
  const [data1, setdata1] = useState([]);
  const [Data, setdata] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setdata(item);
  };
  const token = sessionStorage.getItem("token");

  const getuser = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/user/getAlldriver",{ headers: {
      Authorization: `Bearer ${token}`,
    },});
    if (res.status === 200) {
      setdata1(res.data.success);
    }
  };
  
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "user/deleteUser/" + Data,
        baseURL: "https://parnetslink.xyz/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          toast.info(res.data.success
          );
          handleClose3();
          getuser();
        }
      });
    } catch (error) {
      toast.info(error.response.data.error
        );
    }
  };
  useEffect(() => {
    getuser();
  }, []);
  const block = async (data) => {
    try {
      axios
        .get(`https://parnetslink.xyz/api/user/changeBlockStatus/` + data._id,{ headers: {
          Authorization: `Bearer ${token}`,
        },})
        .then(function (response) {
          if (response.status === 200) {
             toast.info("Blocked successfully"
            );
            window.location.reload();
          }
        })
        .catch(function (error) {

          toast.info("Not able to complete"
          );
        });
    } catch (error) {
      console.warn(error);
      toast.info("Not able to complete"
          );
      return error;
    }
  };
  const unblock = async (data) => {
    try {
      axios
        .get(`https://parnetslink.xyz/api/user/changeunBlockStatus/` + data._id,{ headers: {
          Authorization: `Bearer ${token}`,
        },})
        .then(function (response) {
          if (response.status === 200) {
            
            toast.info("Unblocked successfully"
            );
            window.location.reload();
          }
        })
        .catch(function (error) {

        
          toast.info("Not able to complete"
          );
        });
    } catch (error) {
      console.warn(error);
      toast.info("Not able to complete"
          );
      return error;
    }
  };
  const [data2, setData2] = useState([]);
  const [filteredData, setFilteredData] = useState([]);



  useEffect(() => {
   
    getuser().then((result) => {
      setdata1(result);
    });
  }, []);

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const transformedData = transformData(data1);
      setData2(transformedData);
      setFilteredData(transformedData);
    }
  }, [data1]);

  const transformData = (data1) => {
    return data1?.map((item, i) => {
      return {
        Sno: i + 1,
        ID: item._id,
        name: item.name,
        email: item.email,
       
        mobile: item.mobile,
        RegisteredDate: moment(item.createdAt).format("DD/MM/YYYY"),
        Date: item.createdAt,
        Status: item.status,

        action: (
          <div >
             {item.status !== "Approved" ? (
              <button
                className="button1"
                style={{backgroundColor:'red'}}
                onClick={() => unblock(item)}
              >
                Un-Block
              </button>
            ) : (
              <button
                className="button1" 
                onClick={() => block(item)}
              >
                Block
              </button>
            )}
            {/* {item.status === "pending" ? (
              <button
                className="button1"
                onClick={() => approve(item)}
              >
                Approve
              </button>
            ) : (
             ""
            )} */}
            {/* <MdDelete
              className="text-danger"
              size={24}
              style={{ marginLeft: 20 ,color:'red'}}
              onClick={() => handleShow3(item?._id)}
            /> */}
              {/* <Button className="button1" style={{marginLeft:'5px',marginTop:'10px',backgroundColor:'red'}} type="danger" onClick={showDeleteConfirm}>
      <i className="fa fa-cancel me-2"></i>
      Delete
    </Button> */}
          </div>
        ),
      };
    });
  };
  const exportToExcel = () => {
    const dataToExport = filteredData.map(
      ({ RegisteredDate, ...rest }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Drivers");
    XLSX.writeFile(workbook, "Drivers.xlsx");
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handles = (value) => {
    setSearchTerm(value);

    const filteredDataSource = data2.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data2);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{ width: "40%" }}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;

  const [dateRange, setDateRange] = useState([null, null]);

  const handleDateRangeChange = (dates) => {

    const filteredDataSource = data2.filter((record) => {
      const startDate =
        dates && dates[0] ? new Date(dates[0].startOf("day").toDate()) : null;
      const endDate =
        dates && dates[1] ? new Date(dates[1].endOf("day").toDate()) : null;
      const recordDate = new Date(record.Date);

      const result =
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate);

      return result;
    });

    if (filteredDataSource.length === 0) {
   
      setFilteredData([]);
    } else {
      setFilteredData(filteredDataSource);
      setDateRange(dates);
    }
  };
  return (
    <div style={{ padding: "20px" }}>
      <ToastContainer />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
        <Title level={3}>Drivers</Title>
        <Space>
          <Input
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => handles(e.target.value)}
            style={{ width: 200 }}
            prefix={<Search size={16} />}
            suffix={
              searchTerm && (
                <X
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => handles("")}
                />
              )
            }
          />
          <DatePicker.RangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
          />
          <Button
            type="primary"
            icon={<FileSpreadsheet size={16} />}
            onClick={exportToExcel}
          >
            Export to Excel
          </Button>
        </Space>
      </div>
      <div>
        <Table
          columns={columns}
          className="custom-table"
          dataSource={filteredData}
          // scroll={{ x: "calc(700px + 50%)", y: 240 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: true,
          }}
          scroll={{ x: "max-content" }}
          components={{
            header: {
              cell: (props) => (
                <th
                  {...props}
                  style={{
                    ...props.style,
                    background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                    color: "white",
                  }}
                />
              ),
            },
          }}
        />
      </div>
    
    </div>
  );
};

export default Drivers;
