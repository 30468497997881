import React, { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { toast, ToastContainer } from "react-toastify";
const Login = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBn3eGlq2UU4Z5EVnCBOcKTy6UF2WdYGaw",
    authDomain: "abhinandhan-43620.firebaseapp.com",
    projectId: "abhinandhan-43620",
    storageBucket: "abhinandhan-43620.appspot.com",
    messagingSenderId: "674275225981",
    appId: "1:674275225981:web:8c57cdca0427003658dc14",
    measurementId: "G-82D0TPD4PS"
  };
  const [deviceToken,setdeviceToken] =useState("");
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      getToken(messaging, {
        vapidKey:
          "BBiWNyygG3eiMDLFIwTUdpNlY2Lhk_YC_XdDSFWMI0qhBEJ_s263UOC0zXzn_viyFfO8ywf44zyLMcIk8GLyl_U",
      }).then((currentToken) => {
        if (currentToken) {
       
          setdeviceToken(currentToken)
        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}
requestPermission();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const login = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/login",
        method: "post",
        baseURL: "https://parnetslink.xyz/api",
        hearder: { "content-type": "application/json" },

        data: {
          email: email,
          password: password,
          deviceTokens:deviceToken,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          toast.info("Login Success"
          );
          
          sessionStorage.setItem("admin", JSON.stringify(res.data.success));
          sessionStorage.setItem("token",res.data.token);
          setTimeout(() => {
            setTimeout(() => {
              window.location.assign("/admindashboard");
            }, 2000);
       
        }, 1000);
          
        
        } else {
          toast.info("Something Wrong"
        );
        }
      });
    } catch (error) {
     
      toast.info("Please enter registered email id or mobile and password!"
      );
      ////console.log(error.response.data.error);
    }
  };

  return (
    <div className="py-5" style={{ background: "#f7c46d", minHeight: "100vh" }}>
       <ToastContainer />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="my-2 bg-white rounded-3 mx-auto p-4" style={{width:"400px"}}>
        <h3 className="text-center title">Admin Login</h3>

        <CustomInput
          type="text"
          label="Email Address"
          id="email"
          name="email"
          onChng={(e) => setEmail(e.target.value)}
          val={email}
        />
       <div style={{ position: 'relative', width: '350px' }}>
  <CustomInput
    type={!showPassword ? "text" : "password"}
    label="Password"
    id="pass"
    name="password"
    onChng={(e) => setPassword(e.target.value)}
    val={password}
    style={{ width: "100%" }}
  />
  <i
    className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
    onClick={togglePasswordVisibility}
    style={{
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    }}
  ></i>
</div>
        <button
          className="border-0 px-3 py-2 mt-2 text-white fw-bold w-100 text-center text-decoration-none fs-5"
          style={{ background: "#f7c46d" }}
          onClick={login}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
