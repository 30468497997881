import React, { useState, useEffect } from "react";
import { Table, Input, Button, Typography, Space } from "antd";
import { Search, FileDown, FileSpreadsheet } from "lucide-react";
import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";

const { Title } = Typography;

const Checkins = () => {
  const token = sessionStorage.getItem("token");

  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const res = await axios.get("https://parnetslink.xyz/api/user/getAllCheckin",{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      if (res.status === 200) {
        setUsers(res.data.success);
        setFilteredData(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchText(searchValue);
    const filteredUsers = users.filter((user) =>
      user.userId?.name.toLowerCase().includes(searchValue)
    );
    setFilteredData(filteredUsers);
  };

  const handleClick = (data) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${data.lat},${data.long}`;
    window.open(url, "_blank");
  };

  const handleExportToExcel = () => {
    const exportData = filteredData.map((item) => ({
      "Imp ID": item._id.slice(18, 24),
      "Driver Name": item.userId?.name,
      Date: moment(item.createdAt).format("DD-MM-YYYY"),
      "Login Time": moment(item.createdAt).format("HH:mm:ss"),
      "Logout Time": moment(item.updatedAt).format("HH:mm:ss"),
      "Email ID": item.userId?.email,
      "Phone Number": item.userId?.mobile,
      Address: item.address,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Checkins");
    XLSX.writeFile(workbook, "CheckinsData.xlsx");
  };

  const columns = [
    {
      title: "Imp ID",
      dataIndex: "_id",
      key: "_id",
      render: (text) => text.slice(18, 24),
    },
    {
      title: "Driver Name",
      dataIndex: ["userId", "name"],
      key: "userId.name",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Login Time",
      dataIndex: "createdAt",
      key: "loginTime",
      render: (text) => moment(text).format("HH:mm:ss"),
    },
    {
      title: "Logout Time",
      dataIndex: "updatedAt",
      key: "logoutTime",
      render: (text) => moment(text).format("HH:mm:ss"),
    },
    {
      title: "Email ID",
      dataIndex: ["userId", "email"],
      key: "userId.email",
    },
    {
      title: "Phone Number",
      dataIndex: ["userId", "mobile"],
      key: "userId.mobile",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    // Uncomment this section if you want to include the 'Track' button
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (record) => (
    //     <Button type="primary" onClick={() => handleClick(record)} style={{ backgroundColor: "#1890ff" }}>
    //       Track
    //     </Button>
    //   ),
    // },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 16 }}>
        <Title level={3}>CheckIns</Title>
        <Space>
        <Input
          placeholder="Search Driver Name"
          onChange={handleSearch}
          prefix={<Search size={16} />}
        />
        <Button type="primary" onClick={handleExportToExcel}   icon={<FileSpreadsheet size={16} />} >
          Export to Excel
        </Button></Space>
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
        rowKey={(record) => record._id}
      />
    </div>
  );
};

export default Checkins;