import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Form,
  Modal,
  message,
  Typography,
  Space,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import * as XLSX from "xlsx";
import { FileSpreadsheet } from "lucide-react";

const { Title } = Typography;

const DeliveryChargesList = () => {
  const [deliveryCharges, setDeliveryCharges] = useState([]);
  const [filteredCharges, setFilteredCharges] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCharge, setEditingCharge] = useState(null);
  const [form] = Form.useForm();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    fetchDeliveryCharges();
  }, []);

  const fetchDeliveryCharges = async () => {
    try {
      const res = await axios.get("https://parnetslink.xyz/api/admin/getalldeliverycharges",{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      if (res.status === 200) {
        setDeliveryCharges(res.data?.deliveryCharges);
        setFilteredCharges(res.data?.deliveryCharges);
      }
    } catch (error) {
      console.error("Error fetching delivery charges:", error);
      message.error("Failed to fetch delivery charges");
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = deliveryCharges.filter((charge) =>
      Object.values(charge).some((val) =>
        val?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredCharges(filtered);
  };

  const showModal = (charge = null) => {
    setEditingCharge(charge);
    if (charge) {
      form.setFieldsValue({
        ...charge,
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingCharge(null);
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      let res;
      if (editingCharge) {
        res = await axios.post(
          `https://parnetslink.xyz/api/admin/editdeliverycharges/${editingCharge._id}`,
          values,{ headers: {
            Authorization: `Bearer ${token}`,
          },}
        );
      } else {
        res = await axios.post(
          "https://parnetslink.xyz/api/admin/adddeliverycharges",
          values,{ headers: {
            Authorization: `Bearer ${token}`,
          },}
        );
      }

      if (res.status === 200) {
        message.success(
          `Delivery charges ${editingCharge ? "updated" : "added"} successfully`
        );
        fetchDeliveryCharges();
        handleCancel();
      }
    } catch (error) {
      console.error("Error saving delivery charges:", error);
      message.error("Failed to save delivery charges");
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.post(`https://parnetslink.xyz/api/admin/deletedeliverycharges/${id}`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      if (res.status === 200) {
        message.success("Delivery charges deleted successfully");
        fetchDeliveryCharges();
      }
    } catch (error) {
      console.error("Error deleting delivery charges:", error);
      message.error("Failed to delete delivery charges");
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredCharges.map(({ _id, __v, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Delivery Charges");
    XLSX.writeFile(workbook, "DeliveryCharges.xlsx");
  };

  const columns = [
    { title: "Minimum Amount (₹)", dataIndex: "minAmount", key: "minAmount" },
    {
      title: "Delivery Charges (₹)",
      dataIndex: "deliveryCharges",
      key: "deliveryCharges",
    },
    {
      title: "Km",
      dataIndex: "perKmPrice",
      key: "perKmPrice",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => showModal(record)} />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id)}
            style={{ marginLeft: 8 }}
          />
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title level={3}>Delivery Charges Management</Title>
        <Space>
          <Input
            placeholder="Search delivery charges"
            onChange={(e) => handleSearch(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
          <Button type="primary" onClick={exportToExcel} icon={<FileSpreadsheet size={16} />}>
            Export to Excel
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          >
            Add Delivery Charges
          </Button>
        </Space>
      </div>
      <Table columns={columns} dataSource={filteredCharges} rowKey="_id" />
      <Modal
        title={editingCharge ? "Edit Delivery Charges" : "Add Delivery Charges"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="minAmount"
            label="Minimum Amount (₹)"
            rules={[
              { required: true, message: "Please input the minimum amount!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="deliveryCharges"
            label="Delivery Charges (₹)"
            rules={[
              {
                required: true,
                message: "Please input the delivery charges!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="perKmPrice"
            label="Km"
            rules={[
              {
                required: true,
                message: "Please input the price per kilometer!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingCharge ? "Update" : "Add"} Delivery Charges
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DeliveryChargesList;
