import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Badge, Input, Select, Slider, Menu, Breadcrumb } from "antd";
import { Card, CardContent, Typography } from "@mui/material";
import { Search, ChevronRight, ChevronDown } from "lucide-react";
import { addToCart, removeFromCart } from "../redux/CartSlice";
import Back from "./common/Back";

const { SubMenu } = Menu;

const Shop = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const user = JSON.parse(sessionStorage.getItem("user"));
const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const location = useLocation();
  const { title } = location?.state || "";
  const [categoryData, setCategoryData] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(title);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedWeight, setSelectedWeight] = useState({});
  const [priceRange, setPriceRange] = useState([0, 5000]);
  const [sortOrder, setSortOrder] = useState("Sort");

  const [categoryCounts, setCategoryCounts] = useState({});
  const [subcategoryCounts, setSubcategoryCounts] = useState({});

  useEffect(() => {
    getCategories();
    getSubcategories();
    getProducts();
  }, []);

  useEffect(() => {
    // Calculate category and subcategory counts
    const catCounts = {};
    const subCatCounts = {};

    data.forEach((product) => {
      if (product.category !== "Fruits & Vegetables") {
        catCounts[product.category] = (catCounts[product.category] || 0) + 1;
        subCatCounts[product.subcategory] =
          (subCatCounts[product.subcategory] || 0) + 1;
      }
    });

    setCategoryCounts(catCounts);
    setSubcategoryCounts(subCatCounts);
  }, [data]);

  const getCategories = async () => {
    const res = await axios.get(
      "https://parnetslink.xyz/api/admin/getCategory",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      setCategoryData(
        res.data?.category.filter(
          (e) => e.categoryName !== "Fruits & Vegetables"
        )
      );
    }
  };

  const getSubcategories = async () => {
    const res = await axios.get(
      "https://parnetslink.xyz/api/admin/getSubcategory",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      setSubcategoryData(res.data?.Subcategory);
    }
  };

  const getProducts = async () => {
    const res = await axios.get(
      "https://parnetslink.xyz/api/admin/Getproductlist",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      const activeProducts = res.data.ProductList.filter(
        (item) =>
          item.status === "Active" && item.category !== "Fruits & Vegetables"
      );
      setData(activeProducts);

      const initialSelectedWeight = {};
      activeProducts.forEach((product) => {
        initialSelectedWeight[product._id] = !user?.club
          ? product.weight?.[0]
          : product.Clubweight?.[0];
      });
      setSelectedWeight(initialSelectedWeight);
    }
  };

  const [itemQuantities, setItemQuantities] = useState({});

  const handleQuantityChange = (item, change) => {
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 1) + change, 1),
    }));
  };

  const handleAddToCart = (item) => {
    const weightToAdd =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    const quantity = itemQuantities[item._id] || 1;
    toast.info("Product added successfully")
    dispatch(
      addToCart({
        ...item,
        selectedWeight: weightToAdd,
        quantity,
      })
    );
  };
  const handleRemoveFromCart = (item) => {
    const weightToRemove =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    dispatch(
      removeFromCart({
        _id: item._id,
        selectedWeight: weightToRemove,
      })
    );

    // Update the itemQuantities state
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 0) - 1, 0),
    }));
  };

  const handleSubscribe = (val) => {
    if (!user) {
      toast.info("Please login as a user to subscribe.");
    } else {
      navigate(`/subscribe/${val._id}`, {
        state: { selectedWeight: selectedWeight[val._id] },
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const filteredItems = data.filter((d) => {
    const matchesCategory =
      !selectedCategory || d.category === selectedCategory;
    const matchesSubCategory =
      !selectedSubCategory || d.subcategory === selectedSubCategory;
    const matchesPrice =
      d.offerPrice >= priceRange[0] && d.offerPrice <= priceRange[1];
    return matchesCategory && matchesSubCategory && matchesPrice;
  });
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const handleAddToCart1 = (item) => {
    const weightToAdd =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);
    dispatch(addToCart({ ...item, selectedWeight: weightToAdd }));
  };
  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  console.log(selectedCategory, selectedSubCategory);
  const sortedItems = [...filteredItems].sort((a, b) => {
    if (sortOrder === "asc") {
      return a.productname.localeCompare(b.productname);
    } else if (sortOrder === "desc") {
      return b.productname.localeCompare(a.productname);
    } else if (sortOrder === "price_asc") {
      return (
        (!user?.club ? a.weight[0].price : a.Clubweight[0].price) -
        (!user?.club ? b.weight[0].price : b.Clubweight[0].price)
      );
    } else if (sortOrder === "price_desc") {
      return (
        (!user?.club ? b.weight[0].price : b.Clubweight[0].price) -
        (!user?.club ? a.weight[0].price : a.Clubweight[0].price)
      );
    }
    return 0;
  });

  const currentItems = sortedItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const scrollToTop = (val) => {
    navigate(`/shop-detail/${val?._id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
       <div className="back">
      <div className="overlay-back"></div>
      <h1>Products</h1>
      <div style={{ marginTop: "-10px" }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "10px" }}>
          <div  style={{ color: "#fff" }}>
            <h2 onClick={()=>navigate("/")}>Home</h2>
          </div>
          {selectedCategory && (
            <h2 style={{ color: "#fff" }}>{" " + " > "} {selectedCategory}</h2>
          )}
          {selectedSubCategory && (
            <h2 style={{ color: "#fff" }}> {" > "} {selectedSubCategory}</h2>
          )}
        </div>
      </div>
    </div>

      <ToastContainer />
      <div className="flex flex-col md:flex-row">
        {/* Sidebar */}
        <div className="w-full md:w-1/4 p-2 bg-white shadow-lg rounded-lg mb-4 md:mb-0">
          <Card bordered={false} className="mb-4">
            <CardContent>
              <Typography
                variant="h5"
                className="font-bold mb-4"
                style={{ color: "#5C870C" }}
              >
                Categories
              </Typography>
              <Menu
                mode="inline"
                openKeys={selectedCategory ? [selectedCategory] : []} // Control open keys based on selected category
                selectedKeys={[selectedSubCategory]}
                className="border-none"
              >
                {categoryData.map((category) => (
                  <SubMenu
                    key={category.categoryName}
                    title={
                      <span className="flex items-center justify-between text-gray-600 hover:text-[#5c870c] transition-all duration-200">
                        <span className="flex items-center">
                          <ChevronRight
                            size={16}
                            className="text-gray-400 mr-2"
                          />
                          {category.categoryName}
                        </span>
                        <span className="text-sm text-gray-400">
                          ({categoryCounts[category.categoryName] || 0})
                        </span>
                      </span>
                    }
                    onTitleClick={() => {
                      if (selectedCategory === category.categoryName) {
                        setSelectedCategory(""); // If the selected category is clicked again, close it
                        setSelectedSubCategory(""); // Clear the selected subcategory as well
                      } else {
                        setSelectedCategory(category.categoryName); // Open the selected category
                        setSelectedSubCategory(""); // Clear the subcategory
                      }
                    }}
                  >
                    {subcategoryData
                      .filter(
                        (sub) => sub.categoryName === category.categoryName
                      )
                      .map((subcategory) => (
                        <Menu.Item
                          key={subcategory.SubcategoryName}
                          onClick={() =>
                            setSelectedSubCategory(subcategory.SubcategoryName)
                          }
                          className="text-gray-500 hover:bg-blue-50 hover:text-blue-600 transition-all duration-150"
                        >
                          <span className="flex items-center justify-between">
                            {subcategory.SubcategoryName}
                            <span className="text-sm text-gray-400">
                              (
                              {subcategoryCounts[subcategory.SubcategoryName] ||
                                0}
                              )
                            </span>
                          </span>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                ))}
              </Menu>
            </CardContent>
          </Card>

          <Card bordered={false} className="mb-4 shadow-sm rounded-lg">
            <CardContent>
              <Typography
                variant="h6"
                className="font-semibold text-gray-700 mb-4"
              >
                Price Range
              </Typography>
              <Slider
                range
                min={0}
                max={5000}
                value={priceRange}
                onChange={(value) => setPriceRange(value)}
                className="text-[#5c870c]"
                trackStyle={[{ backgroundColor: "#5C870C" }]}
                handleStyle={[
                  { borderColor: "#5C870C" },
                  { borderColor: "#5C870C" },
                ]}
              />
              <div className="flex justify-between mt-2 text-gray-600">
                <Typography>₹{priceRange[0]}</Typography>
                <Typography>₹{priceRange[1]}</Typography>
              </div>
            </CardContent>
          </Card>

          <Card bordered={false} className="shadow-sm rounded-lg">
            <CardContent>
              <Typography
                variant="h6"
                className="font-semibold text-gray-700 mb-4"
              >
                Sort By
              </Typography>
              <Select
                style={{ width: "100%" }}
                value={sortOrder}
                onChange={(value) => setSortOrder(value)}
                className="text-gray-700"
                dropdownStyle={{ borderRadius: "8px" }}
              >
                <Select.Option value="asc">A-Z</Select.Option>
                <Select.Option value="desc">Z-A</Select.Option>
                <Select.Option value="price_asc">
                  Price: Low to High
                </Select.Option>
                <Select.Option value="price_desc">
                  Price: High to Low
                </Select.Option>
              </Select>
            </CardContent>
          </Card>
        </div>

        <div className="grid grid-cols-1 gap-2 mx-5 mt-10 md:grid-cols-3 xl:grid-cols-3 xl:gap-x-3 xl:gap-y-0">
          {currentItems.map((val) => {
            const currentSelectedWeight = selectedWeight[val._id];
            const itemQuantity = itemQuantities[val._id] || 1;
            return (
              <Badge.Ribbon text={val.tag} color="orange">
                <div
                  key={val._id}
                  className="box border rounded-lg border-orange-400 mx-auto w-full  max-w-xs shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white"
                  style={{ height: "300px", marginBottom: "5px" }}
                >
                  <div className="relative overflow-clip">
                    <img
                      className="hover:scale-105 transition-transform duration-500 ease-in-out w-full rounded-lg shadow-lg"
                      src={`https://parnetslink.xyz/product/${val.Photos[0]?.file}`}
                      alt="Product"
                      style={{
                        height: "130px",
                        width: "350px",
                        marginTop: "10px",
                        objectFit: "contain",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                      }}
                      onClick={() => scrollToTop(val)}
                    />

                    {/* <span
                  className="absolute top-1 text-white py-1 px-1 rounded-xl"
                  style={{ backgroundColor: "#ffb524", fontSize: "13px" }}
                >
                  {val.tag}
                </span> */}
                  </div>
                  <div className="text-center">
                    <h3
                      className="text-l mt-4 font-semibold"
                      style={{ color: "#45595b", cursor: "pointer" }}
                      onClick={() => scrollToTop(val)}
                    >
                      {val.productname.slice(0, 20)}
                    </h3>

                    <select
                      name="option[504]"
                      id="input-option504"
                      className="form-control1 weight resp-select-box"
                      style={{
                        maxWidth: "200px",
                        fontSize: "12px",
                        border: "1px solid #88ac46",
                      }}
                      onChange={(e) => {
                        const selectedWeightData = JSON.parse(e.target.value);

                        const selectedDiscount =
                          selectedWeightData.discountType === "discount"
                            ? val.discount
                            : val.clubdiscount;
                        setSelectedWeight((prev) => ({
                          ...prev,
                          [val._id]: {
                            ...selectedWeightData,
                            discount: selectedDiscount,
                            discountType: selectedWeightData.discountType,
                          },
                        }));
                      }}
                      value={JSON.stringify(currentSelectedWeight)}
                    >
                      {!user?.club &&
                        val.weight?.map((e) => (
                          <option
                            key={`weight-${e.id}`}
                            style={{ fontSize: "12px" }}
                            value={JSON.stringify({
                              ...e,
                              discount: val.discount,
                              discountType: "discount",
                            })}
                          >
                            {e.value} {e.units} - Rs{" "}
                            {(e.price - e.price * (val.discount / 100)).toFixed(
                              2
                            )}
                          </option>
                        ))}
                      {val.Clubweight?.map((e) => (
                        <option
                          key={`clubweight-${e.id}`}
                          value={JSON.stringify({
                            ...e,
                            discount: val.clubdiscount,
                            discountType: "clubdiscount",
                          })}
                          disabled={!user || user.club === false}
                          style={{
                            color:
                              !user || user.club === false ? "#aaa" : "inherit",
                            fontSize: "12px",
                          }}
                        >
                          {e.value} {e.units} - Rs{" "}
                          {(
                            e.price -
                            e.price * (val.clubdiscount / 100)
                          ).toFixed(2)}{" "}
                          {!user?.club && " (Club Members Only)"}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <div className="flex-grow flex justify-center">
                      {val.subscribe === "Subscription" ? (
                        <button
                          onClick={() => handleAddToCart(val)}
                          className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                          style={{ backgroundColor: "#5C870C", color: "#fff" }}
                        >
                          Buy Once
                        </button>
                      ) : (
                        <>
                          <div className="flex items-center mr-2">
                            <button
                              className="px-2 py-1 text-sm font-semibold"
                              style={{
                                backgroundColor: "#ffb524",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleRemoveFromCart(val)}
                            >
                              -
                            </button>
                            <span className="px-3">{itemQuantity}</span>
                            <button
                              className="px-2 py-1 text-sm font-semibold"
                              style={{
                                backgroundColor: "#ffb524",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleQuantityChange(val, 1)}
                            >
                              +
                            </button>
                          </div>
                          <button
                            onClick={() => handleAddToCart(val)}
                            className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                            style={{
                              backgroundColor: "#5C870C",
                              color: "#fff",
                            }}
                          >
                            Add to cart
                          </button>
                        </>
                      )}
                    </div>
                    {val.subscribe === "Subscription" && (
                      <button
                        onClick={() => handleSubscribe(val)}
                        className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                        style={{
                          backgroundColor: "#0c363b",
                          marginRight: "15px",
                        }}
                      >
                        Subscribe
                      </button>
                    )}
                  </div>
                </div>
              </Badge.Ribbon>
            );
          })}
        </div>
      </div>
      <ul className="flex justify-center items-center mx-4 mt-20 next">
        <li
          className="py-2 px-4 mx-1 border border-orange-400 rounded-lg cursor-pointer"
          style={{ color: currentPage === 1 ? "#d1d5db" : "#45595b" }}
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &#8656;
        </li>
        {[...Array(totalPages)].map((_, index) => (
          <li
            key={index}
            className={`py-2 px-4 mx-1 border border-orange-400 rounded-lg cursor-pointer ${
              currentPage === index + 1 ? "bg-[#5c870c] text-white" : ""
            }`}
            style={{ color: "#45595b" }}
            onClick={() => handleClick(index + 1)}
          >
            {index + 1}
          </li>
        ))}
        <li
          className="py-2 px-4 mx-1 border border-orange-400 rounded-lg cursor-pointer"
          style={{ color: currentPage === totalPages ? "#d1d5db" : "#45595b" }}
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &#8658;
        </li>
      </ul>
    </div>
  );
};

export default Shop;
