import React, { useState } from "react";
import Back from "./common/Back";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart, clearItem } from "../redux/CartSlice";
import { addToCart1, removeFromCart1, clearItem1 } from "../redux/CartSlice1";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = useSelector((state) => state.cart.items);
  const items1 = useSelector((state) => state.cart1?.items1);
  const subtotal = useSelector((state) => state.cart.subtotal);
  const total = useSelector((state) => state.cart.total);
  const subtotal1 = useSelector((state) => state.cart1.subtotal1);
  const total1 = useSelector((state) => state.cart1.total1);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [activeTab, setActiveTab] = useState("normal");

  const emptyCartMsg = (
    <h4 className="container mx-auto text-center py-4 text-2xl font-semibold text-gray-700">
      Your Cart is Empty
    </h4>
  );

  const handleCheckout = () => {
    if (!user) {
      toast.info("Please login as a user to proceed with checkout.");
      setTimeout(() => {  
      navigate("/login");
      }, 2000);
      return;
    } else {
      navigate("/checkout", { state: { orderType: activeTab } });
    }
  };

  const scrollToTop = (val) => {
    navigate(`/shop-detail/${val?._id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToTop1 = (val) => {
    navigate(`/shop-detail1/${val?._id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const renderCartItems = (items) => (
    <ul className="list-group list-group-flush">
      {items.map((item) => (
        <li
          key={item._id}
          className="list-group-item py-3 py-lg-0 px-0 border-top"
        >
          <div className="flex flex-wrap items-center">
            <div className="w-1/4 md:w-1/6">
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  activeTab === "normal"
                    ? scrollToTop(item)
                    : scrollToTop1(item)
                }
              >
                <img
                  src={`https://parnetslink.xyz/product/${item?.Photos[0]?.file}`}
                  alt="Ecommerce"
                  className="w-24 h-24 md:w-28 md:h-28 object-contain"
                />
              </div>
            </div>
            <div className="w-2/4 md:w-1/2">
              <h6 className="mb-1 text-lg md:text-xl">{item.productname}</h6>
              <small className="text-muted">
                {item?.selectedWeight?.value}
                {item?.selectedWeight?.units}
              </small>
              <div className="mt-2 small">
                <span
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    if (activeTab === "normal") {
                      dispatch(clearItem(item));
                    } else {
                      dispatch(clearItem1(item));
                    }
                  }}
                >
                  <i className="fa fa-trash text-l"></i>
                </span>
              </div>
            </div>
            <div className="w-1/4 md:w-1/6 flex items-center justify-center">
              <button
                onClick={() => {
                  if (activeTab === "normal") {
                    dispatch(removeFromCart(item));
                  } else {
                    dispatch(removeFromCart1(item));
                  }
                }}
                className="bg-gray-100 rounded-full px-2 py-2 mx-1"
              >
                <i className="fa fa-minus"></i>
              </button>
              <p className="text-center mx-2 mt-3">{item.quantity}</p>
              <button
                onClick={() => {
                  if (activeTab === "normal") {
                    dispatch(addToCart(item));
                  } else {
                    dispatch(addToCart1(item));
                  }
                }}
                className="bg-gray-100 rounded-full px-2 py-2 mx-1"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
            <div className="w-full md:w-1/6 md:mt-0 text-center md:text-right">
              <span className="font-bold text-lg md:text-xl">
                Rs.{" "}
                {parseFloat(
                  (item?.selectedWeight?.price -
                    item?.selectedWeight?.price *
                      ((item.selectedWeight?.discount
                        ? item.selectedWeight?.discount
                        : item?.discount) /
                        100)) *
                    item.quantity
                )?.toFixed(2)}
              </span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <Back title="Cart" />
      <ToastContainer />
      <section className="mb-8 mt-8">
        <div className="container mx-auto px-4 py-8 bg-gray-50">
          <div className="text-center">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
              Shop Cart
            </h1>
          </div>

          {/* Tabs */}
          <div className="mb-4">
            <ul className="nav nav-tabs justify-center">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "normal" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("normal")}
                >
                  Normal Orders
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "scheduled" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("scheduled")}
                >
                  Scheduled Orders
                </button>
              </li>
            </ul>
          </div>

          {items.length === 0 && items1.length === 0 ? (
            emptyCartMsg
          ) : (
            <div className="flex flex-col md:flex-row">
              <div className="md:w-2/3">
                {activeTab === "normal"
                  ? renderCartItems(items)
                  : renderCartItems(items1)}
                <Link to="/">
                  <button className="bg-orange-400 text-white font-semibold py-2 px-4 mt-4 rounded-full">
                    Continue Shopping
                  </button>
                </Link>
              </div>

              {/* Cart Summary */}
              <div className="md:w-1/3">
                <div className="card p-6 mt-6 md:mt-0">
                  <h2 className="h5 mb-4">Summary</h2>
                  {activeTab === "normal" ? (
                    <div className="card mb-2">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-between">
                          <span>Item Subtotal</span>
                          <span>Rs.{subtotal?.toFixed(2)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-between">
                          <span>Shipping Fee</span>
                          <span>Rs.{total >= 1000 ? 0 : 40}</span>
                        </li>
                        <li className="list-group-item d-flex justify-between font-bold">
                          <span>Total</span>
                          <span>
                            Rs. {(total + (total >= 1000 ? 0 : 40))?.toFixed(2)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="card mb-2">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-between">
                          <span>Item Subtotal</span>
                          <span>Rs.{subtotal1?.toFixed(2)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-between">
                          <span>Shipping Fee</span>
                          <span>Rs.{total1 >= 1000 ? 0 : 40}</span>
                        </li>
                        <li className="list-group-item d-flex justify-between font-bold">
                          <span>Total</span>
                          <span>
                            Rs.{" "}
                            {(total1 + (total1 >= 1000 ? 0 : 40))?.toFixed(2)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                  <button
                    className="bg-orange-400 text-white font-semibold py-2 px-4 rounded-full mt-4 w-full"
                    onClick={handleCheckout}
                  >
                    Go to Checkout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
