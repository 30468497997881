import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Badge } from "antd";

const Fruits = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [itemQuantities, setItemQuantities] = useState({});
  const [title, setTitle] = useState([]);
  const [categorydata, setCategorydata] = useState([]);
  const [data, setData] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [selectedCategory, setSelectedCategory] = useState("All Products");
  const cart = useSelector((state) => state.cart);
  const [selectedWeight, setSelectedWeight] = useState({});

  const dispatch = useDispatch();

  const [showAllCategories, setShowAllCategories] = useState(false);

  const handleQuantityChange = (item, change) => {
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 1) + change, 1),
    }));
  };

  const handleAddToCart = (item) => {
    const weightToAdd =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    const quantity = itemQuantities[item._id] || 1;
    toast.info("Product added successfully")
    dispatch(
      addToCart({
        ...item,
        selectedWeight: weightToAdd,
        quantity,
      })
    );

    // Reset the quantity for this item
    // setItemQuantities(prev => ({ ...prev, [item._id]: 0 }));
  };

  const handleRemoveFromCart = (item) => {
    const weightToRemove =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    dispatch(
      removeFromCart({
        _id: item._id,
        selectedWeight: weightToRemove,
      })
    );

    // Update the itemQuantities state
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 0) - 1, 0),
    }));
  };
  const handleAddToCart1 = (item) => {
    handleQuantityChange(item, 1);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const gettitle = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getTitle",{ headers: {
      Authorization: `Bearer ${token}`,
    },});
    if (res.status === 200) {
      setTitle(res.data?.Title);
    }
  };

  const getcategory = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getCategory",{ headers: {
      Authorization: `Bearer ${token}`,
    },});
    if (res.status === 200) {
      setCategorydata(
        res.data?.category.filter(
          (e) => e.categoryName !== "Fruits & Vegetables"
        )
      );
    }
  };

  const getproducts = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/Getproductlist",{ headers: {
        Authorization: `Bearer ${token}`,
      },}
    );
    if (res.status === 200) {
      const activeProducts = res.data.ProductList.filter(
        (item) =>
          item.status === "Active" && item.category !== "Fruits & Vegetables"
      );
      setData(activeProducts);

      const initialSelectedWeight = {};
      activeProducts.forEach((product) => {
        initialSelectedWeight[product._id] = !user?.club
          ? product.weight?.[0]
          : product.Clubweight?.[0];
      });
      setSelectedWeight(initialSelectedWeight);
    }
  };

  useEffect(() => {
    getcategory();
    gettitle();
    getproducts();
  }, []);

  const handleSubscribe = (val) => {
    if (!user) {
      toast.info("Please login as a user to subscribe.");
    } else {
      navigate(`/subscribe/${val._id}`, {
        state: { selectedWeight: selectedWeight[val._id] },
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const scrollToTop = (val) => {
    navigate(`/shop-detail/${val?._id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToTop1 = () => {
    navigate(`/shop`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const visibleCategories = showAllCategories
    ? categorydata
    : categorydata.slice(0, 4);

  return (
    <div className="mt-5 container ml-auto mr-auto">
      <ToastContainer />
      <div className="flex flex-col mb-10 mx-4 md:mx-20 lg:flex lg:justify-between lg:flex-row lg:mx-10 lg:gap-10">
        {title.slice(0, 1).map((data) => (
          <h1
            key={data._id}
            className="text-3xl font-semibold mb-6 lg:text-2xl lg:flex lg:justify-center lg:items-center"
            style={{ color: "#45595b" }}
          >
            {data.title}
          </h1>
        ))}
        <div className="w-full">
          <ul className="flex flex-row flex-wrap w-full">
            <li
              className={`py-2 px-3 border rounded-full w-32 text-center mx-2 my-2 lg:mx-1 hover:cursor-pointer ${
                selectedCategory === "All Products" ? "" : ""
              }`}
              style={{
                backgroundColor:
                  selectedCategory === "All Products" ? "#ffb524" : "#f4f6f8",
                color:
                  selectedCategory === "All Products" ? "#ffffff" : "#000000",
              }}
              onClick={() => handleCategorySelect("All Products")}
            >
              All Products
            </li>
            {visibleCategories.map((data) => (
              <li
                key={data.categoryName}
                className={`py-2 px-3 border rounded-full w-35 text-center mx-2 my-2 lg:mx-1 hover:cursor-pointer ${
                  selectedCategory === data.categoryName ? "" : ""
                }`}
                style={{
                  backgroundColor:
                    selectedCategory === data.categoryName
                      ? "#ffb524"
                      : "#f4f6f8",
                  color:
                    selectedCategory === data.categoryName
                      ? "#ffffff"
                      : "#000000",
                }}
                onClick={() => handleCategorySelect(data.categoryName)}
              >
                {data.categoryName}
              </li>
            ))}
          </ul>
          {categorydata.length > 4 && (
            <button
              className="mt-2 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              onClick={() => setShowAllCategories(!showAllCategories)}
            >
              {showAllCategories ? "View Less" : "View More"}
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {data
          .slice(0, 17)
          .filter(
            (data) =>
              data.category === selectedCategory ||
              selectedCategory === "All Products"
          )
          .map((val) => {
            const currentSelectedWeight = selectedWeight[val._id];
            const itemQuantity = itemQuantities[val._id] || 1;
            return (
              <Badge.Ribbon text={val.tag} color="orange">
                <div
                  key={val._id}
                  className="box border rounded-lg border-orange-400 mx-auto w-full  max-w-xs shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white"
                  style={{ height: "300px", marginBottom: "5px" }}
                >
                  <div
                    className="relative overflow-clip"
                  >
                    <img
                      className="hover:scale-105 transition-transform duration-500 ease-in-out w-full rounded-lg shadow-lg"
                      src={`https://parnetslink.xyz/product/${val.Photos[0]?.file}`}
                      alt="Product"
                      style={{
                        height: "130px",
                        width: "350px",
                        marginTop: "10px",
                        objectFit: "contain",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", cursor: "pointer" 
                      }}
                    onClick={() => scrollToTop(val)}
                    />

                    {/* <span
                  className="absolute top-1 text-white py-1 px-1 rounded-xl"
                  style={{ backgroundColor: "#ffb524", fontSize: "13px" }}
                >
                  {val.tag}
                </span> */}
                  </div>
                  <div
                    className="text-center"
                  >
                    <h3
                      className="text-l mt-4 font-semibold"
                      style={{ color: "#45595b",cursor: "pointer" }}
                    onClick={() => scrollToTop(val)}
                    >
                      {val.productname.slice(0, 20)}
                    </h3>

                    <select
                      name="option[504]"
                      id="input-option504"
                      className="form-control1 weight resp-select-box"
                      style={{
                        maxWidth: "200px",
                        fontSize: "12px",
                        border: "1px solid #88ac46",
                      }}
                      onChange={(e) => {
                        const selectedWeightData = JSON.parse(e.target.value);

                        const selectedDiscount =
                          selectedWeightData.discountType === "discount"
                            ? val.discount
                            : val.clubdiscount;
                        setSelectedWeight((prev) => ({
                          ...prev,
                          [val._id]: {
                            ...selectedWeightData,
                            discount: selectedDiscount,
                            discountType: selectedWeightData.discountType,
                          },
                        }));
                      }}
                      value={JSON.stringify(currentSelectedWeight)}
                    >
                      {!user?.club &&
                        val.weight?.map((e) => (
                          <option
                            key={`weight-${e.id}`}
                            value={JSON.stringify({
                              ...e,
                              discount: val.discount,
                              discountType: "discount",
                            })}
                            style={{ fontSize: "12px" }}
                          >
                            {e.value} {e.units} - Rs{" "}
                            {(e.price - e.price * (val.discount / 100)).toFixed(
                              2
                            )}
                          </option>
                        ))}

                      {val.Clubweight?.map((e) => (
                        <option
                          key={`clubweight-${e.id}`}
                          value={JSON.stringify({
                            ...e,
                            discount: val.clubdiscount,
                            discountType: "clubdiscount",
                          })}
                          disabled={!user || user.club === false}
                          style={{
                            color:
                              !user || user.club === false ? "#aaa" : "inherit",
                            fontSize: "12px",
                          }}
                        >
                          {e.value} {e.units} - Rs{" "}
                          {(
                            e.price -
                            e.price * (val.clubdiscount / 100)
                          ).toFixed(2)}
                          {!user?.club && " (Club Members Only)"}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <div className="flex-grow flex justify-center">
                      {val.subscribe === "Subscription" ? (
                        <button
                          onClick={() => handleAddToCart(val)}
                          className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                          style={{ backgroundColor: "#5C870C", color: "#fff" }}
                        >
                          Buy Once
                        </button>
                      ) : (
                        <>
                          <div className="flex items-center mr-2">
                            <button
                              className="px-2 py-1 text-sm font-semibold"
                              style={{
                                backgroundColor: "#ffb524",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleRemoveFromCart(val)}
                            >
                              -
                            </button>
                            <span className="px-3">{itemQuantity}</span>
                            <button
                              className="px-2 py-1 text-sm font-semibold"
                              style={{
                                backgroundColor: "#ffb524",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleQuantityChange(val, 1)}
                            >
                              +
                            </button>
                          </div>
                          <button
                            onClick={() => handleAddToCart(val)}
                            className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                            style={{
                              backgroundColor: "#5C870C",
                              color: "#fff",
                            }}
                          >
                            Add to cart
                          </button>
                        </>
                      )}
                    </div>
                    {val.subscribe === "Subscription" && (
                      <button
                        onClick={() => handleSubscribe(val)}
                        className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                        style={{
                          backgroundColor: "#0c363b",
                          marginRight: "15px",
                        }}
                      >
                        Subscribe
                      </button>
                    )}
                  </div>
                </div>
              </Badge.Ribbon>
            );
          })}
        <div className="px-4">
          <button
            onClick={() => scrollToTop1()}
            className="uppercase px-3 py-3 border border-orange-400 rounded-xl my-6 mx-auto text-white text-center block w-full text-md font-semibold duration-500"
            style={{ backgroundColor: "#ffb524" }}
          >
            View More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Fruits;
