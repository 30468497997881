import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Avatar,
  Card,
  Table,
  Modal,
  DatePicker,
  Input,
  Form,
  message,
  Descriptions,
  Space,
} from "antd";
import {
  UserOutlined,
  ShoppingOutlined,
  CalendarOutlined,
  CreditCardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Menu as MenuIcon } from "lucide-react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { clearCart } from "../redux/CartSlice";
import { Typography, Row, Col } from "antd";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Eye, EyeOff, Edit, X } from "lucide-react";
import { SearchOutlined } from "@material-ui/icons";

const { Title, Text } = Typography;
const { Header, Sider, Content } = Layout;

function Account() {
  const [activeTab, setActiveTab] = useState("profile");
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();

  const logOut = () => {
    window.location.assign("/");
    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("clubuser");
    dispatch(clearCart());
    toast.info("Successfully logged out");
  };

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return <Profile />;
      case "orders":
        return <Orders />;
      case "scheduledOrders":
        return <ScheduledOrders />;
      case "subscription":
        return <Subscriptions />;
      default:
        return <Profile />;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#5c870c" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth="0"
        style={{ backgroundColor: "#5c870c" }}
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
      >
        <div
          className="demo-logo-vertical lg-logo"
          style={{ height: 64, padding: 16 }}
        >
          <Title level={4} style={{ color: "white" }}>
            Dashboard
          </Title>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[activeTab]}
          style={{ backgroundColor: "#5c870c" }}
        >
          <Menu.Item
            key="profile"
            icon={<UserOutlined />}
            onClick={() => setActiveTab("profile")}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            key="orders"
            icon={<ShoppingOutlined />}
            onClick={() => setActiveTab("orders")}
          >
            Orders
          </Menu.Item>
          <Menu.Item
            key="scheduledOrders"
            icon={<CalendarOutlined />}
            onClick={() => setActiveTab("scheduledOrders")}
          >
            Scheduled Orders
          </Menu.Item>
          <Menu.Item
            key="subscription"
            icon={<CreditCardOutlined />}
            onClick={() => setActiveTab("subscription")}
          >
            Subscriptions
          </Menu.Item>
          <Menu.Item key="primary" icon={<LogoutOutlined />} onClick={logOut}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{ padding: 0, background: "#fff" }}
        >
          <Button
            type="text"
            icon={<MenuIcon />}
            onClick={() => setCollapsed(!collapsed)}
            style={{ fontSize: "16px", width: 64, height: 64 }}
          />
        </Header>
        <Content
          style={{
            // margin: "24px 16px", padding: 24,
            minHeight: 280,
            background: "#fff",
          }}
        >
          <ToastContainer />
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
}

function Profile() {
  const [user, setUser] = useState(() => {
    return (
      JSON.parse(sessionStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("clubuser"))
    );
  });
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(user);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(true);

  useEffect(() => {
    setFormData(user);
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Restrict name field to alphabets only
    if (name === "name" && !/^[a-zA-Z\s]*$/.test(value)) {
      return;
    }
    if (name === "mobile" && (!/^\d*$/.test(value) || value.length > 10)) {
      return;
    }
    if (name === "Amobile" && (!/^\d*$/.test(value) || value.length > 10)) {
      return;
    }
    if (name === "pincode" && (!/^\d*$/.test(value) || value.length > 6)) {
      return;
    }
    if (name === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const token = sessionStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: user ? "/user/editUser" : "/user/editclubUser",
        baseURL: "https://parnetslink.xyz/api",
        method: "post",
        data: { adminId: user._id, ...formData },
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };

      const res = await axios(config);
      if (res.status === 200) {
        setUser({ ...user, ...formData });
        sessionStorage.setItem(
          user ? "user" : "clubuser",
          JSON.stringify({ ...user, ...formData })
        );
        toast.info("Profile Updated Successfully");
        setIsEditing(false);
        setError("");
      }
    } catch (err) {
      setError("Failed to update profile");
    }
  };

  return (
    <div className="mx-auto p-2 min-h-screen flex items-center justify-center">
      <Card className="shadow-lg w-full h-full max-w-4xl">
        <div className="space-y-1 flex flex-col sm:flex-row justify-between items-center mb-4">
          <div className="flex items-center space-x-4">
            <div>
              <h2 className="text-2xl font-bold">
                {user.name} {user.club ? "(Club Member)" : ""}
              </h2>
              <p className="text-muted-foreground">{user.email}</p>
            </div>
          </div>
          <Button
            style={{
              backgroundColor: isEditing ? "#ff4d4f" : "#5c870c",
              color: "white",
            }}
            onClick={() => setIsEditing(!isEditing)}
            className="flex items-center gap-2 mt-2 sm:mt-0"
          >
            {isEditing ? (
              <X className="h-4 w-4" />
            ) : (
              <Edit className="h-4 w-4" />
            )}
            {isEditing ? "Cancel" : "Edit Profile"}
          </Button>
        </div>

        <form className="space-y-6 flex-grow">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <label htmlFor="name">Name</label>
              <Input
                id="name"
                name="name"
                value={formData.name || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="mobile">Mobile</label>
              <Input
                id="mobile"
                name="mobile"
                value={formData.mobile || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="Amobile">Alternative Mobile</label>
              <Input
                id="Amobile"
                name="Amobile"
                value={formData.Amobile || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="pincode">Pincode</label>
              <Input
                id="pincode"
                name="pincode"
                value={formData.pincode || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="address1">Address Line 1</label>
              <Input
                id="address1"
                name="address1"
                value={formData.address1 || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="address2">Address Line 2</label>
              <Input
                id="address2"
                name="address2"
                value={formData.address2 || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="landmark">Landmark</label>
              <Input
                id="landmark"
                name="landmark"
                value={formData.landmark || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="w-full"
              />
            </div>
            {isEditing && (
              <>
                <div className="space-y-2">
                  <label htmlFor="password">Password</label>
                  <div className="relative">
                    <Input
                      id="password"
                      name="password"
                      type={!showPassword ? "text" : "password"}
                      onChange={handleInputChange}
                      className="w-full pr-10"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2"
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4 text-gray-500" />
                      ) : (
                        <Eye className="h-4 w-4 text-gray-500" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="space-y-2">
                  <label htmlFor="cpassword">Confirm Password</label>
                  <div className="relative">
                    <Input
                      id="cpassword"
                      name="cpassword"
                      type={!showPassword1 ? "text" : "password"}
                      onChange={handleInputChange}
                      className="w-full pr-10"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword1(!showPassword1)}
                      className="absolute right-3 top-1/2 -translate-y-1/2"
                    >
                      {showPassword1 ? (
                        <EyeOff className="h-4 w-4 text-gray-500" />
                      ) : (
                        <Eye className="h-4 w-4 text-gray-500" />
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          {isEditing && (
            <div className="flex justify-end">
              <Button
                type="submit"
                className="w-full md:w-auto"
                onClick={handleSubmit}
                style={{ backgroundColor: "#5c870c", color: "white" }}
              >
                Save Changes
              </Button>
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}

function Orders() {
  const token = sessionStorage.getItem("token");
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [salesOrderData, setSalesOrderData] = useState(null);
  const { RangePicker } = DatePicker;

  const user =
    JSON.parse(sessionStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("clubuser"));

  useEffect(() => {
    const getOrders = async () => {
      try {
        const res = await axios.get(
          "https://parnetslink.xyz/api/user/getallOrder",{headers: {
            Authorization: `Bearer ${token}`,
          }}
        );
        if (res.status === 200) {
          const userOrders = res.data.success.filter(
            (e) => e.userId === user._id && e.orderType !== "scheduled"
          );
          setOrders(userOrders);
          setFilteredOrders(userOrders);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    getOrders();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [searchText, dateRange, orders]);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);

  const filterOrders = () => {
    let filtered = orders;

    if (searchText) {
      filtered = filtered.filter(
        (order) =>
          order._id.toLowerCase().includes(searchText.toLowerCase()) ||
          order.status.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.orderDate);
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        return orderDate >= startDate && orderDate <= endDate;
      });
    }

    setFilteredOrders(filtered);
  };

  const PrintInvoice = (Item) => {
    sessionStorage.setItem("InvoiceItem", JSON.stringify(Item));
    window.location.assign("/orderinvoice");
  };

  const handleViewSalesOrder = async (orderId) => {
    try {
      const response = await axios.get(
        `https://parnetslink.xyz/api/user/getSalesOrder/${orderId}`,{headers: {
          Authorization: `Bearer ${token}`,
        }}
      );
      if (response.data.success) {
        setSalesOrderData(response.data.data);
      } else {
        console.error("Sales order not found");
      }
    } catch (error) {
      console.error("Error fetching sales order:", error);
    }
  };
  const [selectedOrder, setSelectedOrder] = useState(null);
  const openmodal = (record) => {
    setSelectedOrder(record);
    setIsDetailsModalVisible(true);
    handleViewSalesOrder(record._id);
  };

  const columns = [
    { title: "Order ID", dataIndex: "_id", key: "_id" },
    {
      title: "Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (amount) => `₹${amount?.toFixed(2)}`,
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => openmodal(record)}
            style={{ backgroundColor: "#5c870c", color: "white" }}
          >
            View Details
          </Button>
          <Button
            onClick={() => PrintInvoice(record)}
            style={{ backgroundColor: "#5c870c", color: "white" }}
          >
            View Invoice
          </Button>
        </Space>
      ),
    },
  ];

  const columns1 = [
    { title: "Item Name", dataIndex: "name", key: "name" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Weight", dataIndex: "weight", key: "weight" },
    { title: "Price", dataIndex: "price", key: "price" },
  ];
  const renderDetailsModal = () => (
  <Modal
    title="Order Details"
    visible={isDetailsModalVisible}
    onCancel={() => setIsDetailsModalVisible(false)}
    footer={null}
    width={800}
  >
    <div>
      <Descriptions bordered column={2} layout="horizontal">
        <Descriptions.Item label="Date">
          {new Date(selectedOrder?.orderDate).toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label="Total Amount">
          ₹{selectedOrder?.totalAmount?.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Discount">
          ₹{selectedOrder?.discount?.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Payment Method">
          {selectedOrder?.paymentMethod}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {selectedOrder?.status}
        </Descriptions.Item>
      </Descriptions>

      <h4>Items:</h4>
      <Descriptions bordered column={1}>
        {selectedOrder?.items.map((item, i) => (
          <Descriptions.Item label={`Item ${i + 1}`}>
            {item.name} - Quantity: {item.quantity}, Price: ₹
            {item.price?.toFixed(2)}
          </Descriptions.Item>
        ))}
      </Descriptions>
      {salesOrderData && salesOrderData?.orderId === selectedOrder?._id ? (
        <>
          <h4 style={{ marginTop: "20px" }}>Sales Order:</h4>
          <Table
            dataSource={salesOrderData?.items}
            columns={columns1}
            rowKey="itemId"
            pagination={false}
          />
          <div style={{ marginBottom: "20px" }}>
            <strong>Total Sales Amount:</strong> {salesOrderData?.totalAmount}
          </div>
        </>
      ) : (
        // <p>No sales order found</p>
        ""
      )}
      <h4>Shipping Address:</h4>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Full Name">
          {selectedOrder?.shippingAddress?.fullName
            ? selectedOrder?.shippingAddress?.fullName
            : selectedOrder?.shippingAddress?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {selectedOrder?.shippingAddress?.street
            ? selectedOrder?.shippingAddress?.street
            : selectedOrder?.shippingAddress?.address1}
          ,{" "}
          {selectedOrder?.shippingAddress?.city
            ? selectedOrder?.shippingAddress?.city
            : selectedOrder?.shippingAddress?.address2}{" "}
          {selectedOrder?.shippingAddress?.state ? "," : ""}{" "}
          {selectedOrder?.shippingAddress?.state},{" "}
          {selectedOrder?.shippingAddress?.pincode}
        </Descriptions.Item>
      </Descriptions>
    </div>
  </Modal>);

  return (
    <Card title="Orders">
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search Order ID or Status"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <DatePicker.RangePicker onChange={(dates) => setDateRange(dates)} />
      </Space>
      <Table
        columns={columns}
        dataSource={filteredOrders}
        rowKey="_id"
        scroll={{ x: "max-content" }}
        pagination={{ pageSize: 5 }}
      />
      {renderDetailsModal()}
    </Card>
  );
}

function ScheduledOrders() {
  const token = sessionStorage.getItem("token");
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [salesOrderData, setSalesOrderData] = useState(null);
  const { RangePicker } = DatePicker;

  const user =
    JSON.parse(sessionStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("clubuser"));

  useEffect(() => {
    const getOrders = async () => {
      try {
        const res = await axios.get(
          "https://parnetslink.xyz/api/user/getallOrder",{headers: {
            Authorization: `Bearer ${token}`,
          }}
        );
        if (res.status === 200) {
          const userOrders = res.data.success.filter(
            (e) => e.userId === user._id && e.orderType === "scheduled"
          );
          setOrders(userOrders);
          setFilteredOrders(userOrders);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    getOrders();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [searchText, dateRange, orders]);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);

  const filterOrders = () => {
    let filtered = orders;

    if (searchText) {
      filtered = filtered.filter(
        (order) =>
          order._id.toLowerCase().includes(searchText.toLowerCase()) ||
          order.status.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.orderDate);
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        return orderDate >= startDate && orderDate <= endDate;
      });
    }

    setFilteredOrders(filtered);
  };

  const PrintInvoice = (Item) => {
    sessionStorage.setItem("InvoiceItem", JSON.stringify(Item));
    window.location.assign("/orderinvoice");
  };

  const handleViewSalesOrder = async (orderId) => {
    try {
      const response = await axios.get(
        `https://parnetslink.xyz/api/user/getSalesOrder/${orderId}`,{headers: {
          Authorization: `Bearer ${token}`,
        }}
      );
      if (response.data.success) {
        setSalesOrderData(response.data.data);
      } else {
        console.error("Sales order not found");
      }
    } catch (error) {
      console.error("Error fetching sales order:", error);
    }
  };
  const [selectedOrder, setSelectedOrder] = useState(null);
  const openmodal = (record) => {
    setSelectedOrder(record);
    setIsDetailsModalVisible(true);
    handleViewSalesOrder(record._id);
  };

  const columns = [
    { title: "Order ID", dataIndex: "_id", key: "_id" },
    {
      title: "Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (amount) => `₹${amount?.toFixed(2)}`,
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => openmodal(record)}
            style={{ backgroundColor: "#5c870c", color: "white" }}
          >
            View Details
          </Button>
          <Button
            onClick={() => PrintInvoice(record)}
            style={{ backgroundColor: "#5c870c", color: "white" }}
          >
            View Invoice
          </Button>
        </Space>
      ),
    },
  ];

  const columns1 = [
    { title: "Item Name", dataIndex: "name", key: "name" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Weight", dataIndex: "weight", key: "weight" },
    { title: "Price", dataIndex: "price", key: "price" },
  ];
  const renderDetailsModal = () => (
  <Modal
    title="Order Details"
    visible={isDetailsModalVisible}
    onCancel={() => setIsDetailsModalVisible(false)}
    footer={null}
    width={800}
  >
    <div>
      <Descriptions bordered column={2} layout="horizontal">
        <Descriptions.Item label="Date">
          {new Date(selectedOrder?.orderDate).toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label="Total Amount">
          ₹{selectedOrder?.totalAmount?.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Discount">
          ₹{selectedOrder?.discount?.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Payment Method">
          {selectedOrder?.paymentMethod}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {selectedOrder?.status}
        </Descriptions.Item>
      </Descriptions>

      <h4>Items:</h4>
      <Descriptions bordered column={1}>
        {selectedOrder?.items.map((item, i) => (
          <Descriptions.Item label={`Item ${i + 1}`}>
            {item.name} - Quantity: {item.quantity}, Price: ₹
            {item.price?.toFixed(2)}
          </Descriptions.Item>
        ))}
      </Descriptions>
      {salesOrderData && salesOrderData?.orderId === selectedOrder?._id ? (
        <>
          <h4 style={{ marginTop: "20px" }}>Sales Order:</h4>
          <Table
            dataSource={salesOrderData?.items}
            columns={columns1}
            rowKey="itemId"
            pagination={false}
          />
          <div style={{ marginBottom: "20px" }}>
            <strong>Total Sales Amount:</strong> {salesOrderData?.totalAmount}
          </div>
        </>
      ) : (
        // <p>No sales order found</p>
        ""
      )}
      <h4>Shipping Address:</h4>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Full Name">
          {selectedOrder?.shippingAddress?.fullName
            ? selectedOrder?.shippingAddress?.fullName
            : selectedOrder?.shippingAddress?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {selectedOrder?.shippingAddress?.street
            ? selectedOrder?.shippingAddress?.street
            : selectedOrder?.shippingAddress?.address1}
          ,{" "}
          {selectedOrder?.shippingAddress?.city
            ? selectedOrder?.shippingAddress?.city
            : selectedOrder?.shippingAddress?.address2}{" "}
          {selectedOrder?.shippingAddress?.state ? "," : ""}{" "}
          {selectedOrder?.shippingAddress?.state},{" "}
          {selectedOrder?.shippingAddress?.pincode}
        </Descriptions.Item>
      </Descriptions>
    </div>
  </Modal>);

  return (
    <Card title="Orders">
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search Order ID or Status"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <DatePicker.RangePicker onChange={(dates) => setDateRange(dates)} />
      </Space>
      <Table
        columns={columns}
        dataSource={filteredOrders}
        rowKey="_id"
        scroll={{ x: "max-content" }}
        pagination={{ pageSize: 5 }}
      />
      {renderDetailsModal()}
    </Card>
  );
}

function Subscriptions() {
  const token = sessionStorage.getItem("token");
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [pauseDate, setPauseDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const user =
    JSON.parse(sessionStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("clubuser"));

  useEffect(() => {
    const getSubscriptions = async () => {
      try {
        const res = await axios.get(
          "https://parnetslink.xyz/api/user/getallSubscriptions",{headers: {
            Authorization: `Bearer ${token}`,
          }}
        );
        if (res.status === 200) {
          const userSubscriptions = res.data.success.filter(
            (e) => e.userId === user._id
          );
          setSubscriptions(userSubscriptions);
          setFilteredSubscriptions(userSubscriptions);
        }
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };
    getSubscriptions();
  }, []);

  useEffect(() => {
    filterSubscriptions();
  }, [searchText, dateRange, subscriptions]);

  const filterSubscriptions = () => {
    let filtered = subscriptions;

    if (searchText) {
      filtered = filtered.filter(
        (subscription) =>
          subscription._id.toLowerCase().includes(searchText.toLowerCase()) ||
          subscription.status
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          subscription.productId.productname
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.startDate);
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        return orderDate >= startDate && orderDate <= endDate;
      });
    }

    setFilteredSubscriptions(filtered);
  };

  const handlePause = async () => {
    try {
      const response = await axios.post(
        "https://parnetslink.xyz/api/user/pauseSubscription",
        {
          subscriptionId: selectedSubscription._id,
          pauseDate: pauseDate ? pauseDate.format("YYYY-MM-DD") : null,
        },
        {headers: {
          Authorization: `Bearer ${token}`,
        }}
      );
      if (response.status === 200) {
        message.success("Subscription paused successfully");
        setSubscriptions(
          subscriptions.map((sub) =>
            sub._id === selectedSubscription._id ? { ...sub, pauseDate } : sub
          )
        );
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error pausing subscription:", error);
      message.error("Failed to pause subscription");
    }
  };

  const showModal = (subscription) => {
    setSelectedSubscription(subscription);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedSubscription(null);
    setPauseDate(null);
  };

  const columns = [
    { title: "Subscription ID", dataIndex: "_id", key: "_id" },
    {
      title: "Product",
      dataIndex: ["productId", "productname"],
      key: "product",
    },
    { title: "Frequency", dataIndex: "frequency", key: "frequency" },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) =>
        record.status !== "Completed" ? (
          <Button
            onClick={() => showModal(record)}
            style={{ backgroundColor: "#5c870c", color: "white" }}
          >
            Pause Subscription
          </Button>
        ) : null,
    },
  ];

  const showSubscriptionDetails = (subscription) => {
    Modal.info({
      title: `Subscription Details - ${subscription._id}`,
      content: (
        <div>
          <Descriptions bordered column={2} layout="horizontal">
            <Descriptions.Item label="Product">
              {subscription.productId?.productname}
            </Descriptions.Item>
            <Descriptions.Item label="Frequency">
              {subscription.frequency}
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">
              {subscription.quantity}
            </Descriptions.Item>
            <Descriptions.Item label="Start Date">
              {new Date(subscription.startDate).toLocaleDateString()}
            </Descriptions.Item>
            <Descriptions.Item label="End Date">
              {subscription.endDate
                ? new Date(subscription.endDate).toLocaleDateString()
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Total Orders">
              {subscription.totalOrders}
            </Descriptions.Item>
            <Descriptions.Item label="Total Quantity">
              {subscription.totalQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Product Value">
              ₹{subscription.selectedWeight.price?.toFixed(2)}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Discount">
              ₹{subscription.discount?.toFixed(2)}
            </Descriptions.Item> */}
            {/* <Descriptions.Item label="Subtotal">
              ₹{subscription.subtotal?.toFixed(2)}
            </Descriptions.Item> */}
            <Descriptions.Item label="Delivery Charges">
              ₹{subscription.deliveryCharges?.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Total">
              ₹{subscription.productValue?.toFixed(2)}
            </Descriptions.Item>
          </Descriptions>

          <h4>Shipping Address:</h4>
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Full Name">
              {subscription.shippingAddress?.fullName
                ? subscription.shippingAddress?.fullName
                : subscription.shippingAddress?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {subscription.shippingAddress?.street
                ? subscription.shippingAddress?.street
                : subscription.shippingAddress?.address1}
              ,{" "}
              {subscription.shippingAddress?.city
                ? subscription.shippingAddress?.city
                : subscription.shippingAddress?.address2}{" "}
              {subscription.shippingAddress?.state ? "," : ""}{" "}
              {subscription.shippingAddress?.state},{" "}
              {subscription.shippingAddress?.pincode}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {subscription.shippingAddress?.number
                ? subscription.shippingAddress?.number
                : subscription.shippingAddress?.mobile}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {subscription.shippingAddress?.email}
            </Descriptions.Item>
          </Descriptions>

          <h4>Selected Weight:</h4>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={`Weight`}>
              {subscription.selectedWeight?.value}{" "}
              {subscription.selectedWeight?.units}
            </Descriptions.Item>
          </Descriptions>

          {subscription.dayQuantities && (
            <>
              <h4>Day Quantities:</h4>
              <Descriptions bordered column={2}>
                {Object.entries(subscription.dayQuantities).map(
                  ([day, quantity]) => (
                    <Descriptions.Item key={day} label={day}>
                      {quantity}
                    </Descriptions.Item>
                  )
                )}
              </Descriptions>
            </>
          )}
          {subscription.pauseDates && subscription.pauseDates.length > 0 && (
            <>
              <h4>Pause Dates:</h4>
              <Descriptions bordered column={1}>
                {subscription.pauseDates.map((date, index) => (
                  <Descriptions.Item
                    key={index}
                    label={`Pause Date ${index + 1}`}
                  >
                    {new Date(date).toLocaleDateString()}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </>
          )}
        </div>
      ),
      width: "90%",
      style: { top: 20 },
    });
  };
  return (
    <Card title="Subscriptions">
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search Subscription ID, Status, or Product"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <DatePicker.RangePicker onChange={(dates) => setDateRange(dates)} />
      </Space>
      <Table
        columns={columns}
        dataSource={filteredSubscriptions}
        rowKey="_id"
        scroll={{ x: "max-content" }}
        pagination={{ pageSize: 5 }}
        expandable={{
          expandedRowRender: (record) => (
            <Button
              onClick={() => showSubscriptionDetails(record)}
              style={{ backgroundColor: "#5c870c", color: "white" }}
            >
              View Full Details
            </Button>
          ),
        }}
      />
      <Modal
        title={`Pause Subscription ${selectedSubscription?._id}`}
        visible={isModalVisible}
        onOk={handlePause}
        onCancel={handleCancel}
        okText="Confirm Pause"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: "#5c870c", color: "#fff" } }}
      >
        <div>
          <h3>Select Pause Date</h3>
          <DatePicker
            value={pauseDate}
            onChange={(date) => setPauseDate(date)}
            format="YYYY-MM-DD"
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
          />
        </div>
      </Modal>
    </Card>
  );
}

export default Account;
