import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import CustomInput from "./admin/components/CustomInput";

export default function LoginForm() {
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);
const token = sessionStorage.getItem("token");
  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/user/loginUser",
        method: "post",
        baseURL: "https://parnetslink.xyz/api",
        hearder: { "content-type": "application/json" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          email: email,
          password: password,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          if (res.data.success.status === "pending") {
            toast.info("You're account is not approved yet");
          } else {
            if (res.data) {
              toast.info("Login success");
              sessionStorage.setItem("user", JSON.stringify(res.data.success));
              sessionStorage.setItem("token",res.data.token);
              setTimeout(() => {
                window.location.assign("/");
              }, 2000);
             
            } else {
              handleerror();
            }
          }
        }
      });
    } catch (error) {
      toast.info("Please enter registered email id or mobile and password!");
      ////console.log(error.response.data.error);
    }
  };
  const forgetPassword = async () => {
    if (!email) {
      toast.info("Please enter email address");
    } else {
      try {
        const config = {
          url: "/userForgetPwd",
          method: "post",
          baseURL: "https://parnetslink.xyz/api/user",
          headers: { "content-type": "application/json" },
          headers: {
            Authorization: `Bearer ${token}`,
          },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          
          data: { email: email },
        };
        let res = await axios(config);
        if (res.status === 200) {
          toast.info("Successfully sent new password to your mail");
          window.location.reload(true);
        } else {
          toast.info("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          toast.info(error.response.data.error);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <div class="logo text-center"></div>
      <div class="wrapper">
        <div class="inner-warpper text-center">
          <h4 class="title">Login to Your Account</h4>

          <div style={{ position: "relative", width: "100%" }}>
            <CustomInput
              type="text"
              label="Email / Mobile Number"
              id="userName"
              name="userName"
              onChng={(e) => setEmail(e.target.value)}
              val={email}
            />
            <span class="lighting"></span>
          </div>
          <div style={{ position: "relative", width: "100%" }}>
            <CustomInput
              type={!showPassword ? "text" : "password"}
              label="Password"
              id="pass"
              name="password"
              onChng={(e) => setPassword(e.target.value)}
              val={password}
              style={{ width: "100%" }}
            />
            <i
              className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            ></i>
          </div>

          <div class="clearfix supporter">
            {/* <div class="pull-left remember-me">
          <input id="rememberMe" type="checkbox"/>
          <label for="rememberMe">Remember Me</label>
        </div> */}
            <a class="forgot pull-right" onClick={() => forgetPassword()}>
              Forgot Password?
            </a>
          </div>
          <br />
          <button
            class="button1"
            type="submit"
            id="login"
            onClick={handleClick}
          >
            Login
          </button>
        </div>
        <div class="signup-wrapper text-center" style={{ fontSize: "20px",marginTop:"-40px" }}>
          <a href="/register">
          New to
          Abhinandhan Organics? <span class="text-primary">{" "}Sign up</span>
          </a>
        </div>
      </div>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src="../img/success.png"
              style={{ margin: "auto" }}
              width="100px"
            />

            <p>Login Successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src="../img/close.png"
              style={{ margin: "auto" }}
              width="100px"
            />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
