import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Modal, message, Space, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Trash2, Edit, FileSpreadsheet } from 'lucide-react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const API_BASE_URL = 'https://parnetslink.xyz/api/admin';
const { Title } = Typography;

const AddTitle = () => {
  const token = sessionStorage.getItem("token");

  const [titles, setTitles] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingTitle, setEditingTitle] = useState(null);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [filteredTitles, setFilteredTitles] = useState([]);

  useEffect(() => {
    fetchTitles();
  }, []);

  useEffect(() => {
    setFilteredTitles(
      titles.filter((title) =>
        Object.values(title).some(
          (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [titles, searchText]);

  const fetchTitles = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getTitle`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      setTitles(response.data?.Title || []);
    } catch (error) {
      message.error('Failed to fetch titles');
    }
  };

  const handleAddTitle = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/addTitle`, {
        title: values.title
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Title added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchTitles();
    } catch (error) {
      message.error('Failed to add title');
    }
  };

  const handleEditTitle = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/editTitle/${editingTitle._id}`, {
        title: values.title
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Title updated successfully');
      setIsEditModalVisible(false);
      form.resetFields();
      fetchTitles();
    } catch (error) {
      message.error('Failed to update title');
    }
  };

  const handleDeleteTitle = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/deletetitle/${id}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Title deleted successfully');
      fetchTitles();
    } catch (error) {
      message.error('Failed to delete title');
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredTitles.map(({ _id, title }) => ({
      'Title': title,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Titles');
    XLSX.writeFile(workbook, 'titles.xlsx');
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serialNo',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<Edit color="blue" />}
            onClick={() => {
              setEditingTitle(record);
              setIsEditModalVisible(true);
              form.setFieldsValue({ title: record.title });
            }}
          />
          <Button
            type="text"
            icon={<Trash2 color="red" />}
            onClick={() => handleDeleteTitle(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Title level={3} className="title">Titles</Title>
        <Space>
          <Input
            placeholder="Search titles"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
            Add Title
          </Button>
          <Button  type="primary" icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>Export to Excel</Button>
        </Space>
      </div>

      <Table 
        columns={columns} 
        dataSource={filteredTitles} 
        rowKey="_id" 
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
      />

      <Modal
        title="Add Title"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddTitle} layout="vertical">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Title
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Title"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditTitle} layout="vertical">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Title
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddTitle;