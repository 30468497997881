import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Table,
  Space,
  message,
  Typography,
  Select,
  Form,
  Descriptions,
} from "antd";
import {
  ExclamationCircleOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { BiEdit } from "react-icons/bi";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileSpreadsheet } from "lucide-react";

const API_BASE_URL = "https://parnetslink.xyz/api";
const { Title } = Typography;
const { confirm } = Modal;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const token = sessionStorage.getItem("token");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchOrders();
    fetchDrivers();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchText, dateRange, orders]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/user/getallOrder`,{headers: {
          Authorization: `Bearer ${token}`,
        }});
      if (response.status === 200) {
        const normalOrders = response.data.success.filter(
          (e) => e.orderType === "normal"
        );
        const transformedOrders = transformData(normalOrders);
        setOrders(transformedOrders);
        setFilteredOrders(transformedOrders);
      }
    } catch (error) {
      message.error("Failed to fetch orders");
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/getAlldriver`,{headers: {
        Authorization: `Bearer ${token}`,
      }});
      if (response.status === 200) {
        setDriver(response.data.success);
      }
    } catch (error) {
      message.error("Failed to fetch drivers");
      console.error("Error fetching drivers:", error);
    }
  };

  const handleAssignDeliveryBoy = async (orderId, deliveryBoyId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/assignDeliveryBoy`,
        {
          orderId,
          deliveryBoyId,
        },{  headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (response.status === 200) {
        message.success("Delivery boy assigned successfully");
        fetchOrders();
      }
    } catch (error) {
      message.error("Failed to assign delivery boy");
      console.error("Error assigning delivery boy:", error);
    }
  };

  const handleEditProduct = (orderId, product, discount) => {
    setEditingProduct({ orderId, product, discount });
    form.setFieldsValue({
      quantity: product.quantity,
      weight: product.weight,
    });
    setIsModalVisible(true);
  };

  const handleSaveEdit = async () => {
    try {
      const values = await form.validateFields();
      const { orderId, product, discount } = editingProduct;
  
      // Calculate the total amount for the edited item
      const editedTotal = values.quantity * (values.price ? values.price : product.price);
  
      // Fetch the original order data
      const responseOrder = await axios.get(`${API_BASE_URL}/user/getOriginalOrder/${orderId}`,{  headers: {
        Authorization: `Bearer ${token}`,
      },});
      const originalOrder = responseOrder.data.data;
  
      console.log(originalOrder, "originalOrder");
  
      // Filter out any item with the same name and weight as the edited product
      const uneditedItems = originalOrder.items.filter(
        (item) => !(item.name.trim() === product.name.trim() && item.quantity === product.quantity)
      );
  
      // Calculate the total for unedited items (excluding the duplicate item)
      const uneditedTotal = uneditedItems.reduce((acc, item) => acc + item.quantity * item.price, 0);
  
      console.log(uneditedTotal, "uneditedTotal");
  
      // Calculate the final total amount (edited + unedited)
      const totalAmount = editedTotal + uneditedTotal;
  
      // Prepare the sales order data with the edited product and unedited items
      const response = await axios.post(`${API_BASE_URL}/user/saveSalesOrder`, {
        orderId,
        discount,
        totalAmount,
        items: [
          // Add the updated item details (edited version)
          {
            itemId: product.itemId,
            name: product.name.trim(),  // Trim to remove extra spaces
            quantity: values.quantity,
            weight: values.weight,
            price: values.price ? values.price : product.price,
          },
          // Include the rest of the unedited items
          ...uneditedItems,
        ],
      },{  headers: {
        Authorization: `Bearer ${token}`,
      },});
  
      // Handle successful sales order creation
      if (response.status === 200) {
        message.success("Sales order updated successfully");
        setIsModalVisible(false);
        setIsDetailsModalVisible(false);
        form.resetFields();
        fetchOrders(); // Refresh the orders
      }
    } catch (error) {
      message.error("Failed to update sales order");
      console.error("Error saving sales order:", error);
    }
  };
  
  
  
  const showDeleteConfirm = (orderId) => {
    confirm({
      title: "Are you sure you want to delete this order?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteOrder(orderId);
      },
    });
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/user/deleteOrder/${orderId}`,{headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (response.status === 200) {
        message.success("Order deleted successfully");
        fetchOrders();
      }
    } catch (error) {
      message.error("Failed to delete order");
      console.error("Error deleting order:", error);
    }
  };
  const [salesOrderData, setSalesOrderData] = useState(null);
  const handleViewSalesOrder = async (orderId) => {
    try {
      // Fetch sales order data from the backend
      const response = await axios.get(
        `${API_BASE_URL}/user/getSalesOrder/${orderId}`,{  headers: {
          Authorization: `Bearer ${token}`,
        },}
      );

      if (response.data.success) {
        setSalesOrderData(response.data.data);
      } else {
        console.error("Sales order not found");
      }
    } catch (error) {
      console.error("Error fetching sales order:", error);
    }
  };

  const transformData = (data) => {
    return data.map((order, index) => ({
      key: index + 1,
      Sno: index + 1,
      orderId: order._id,
      customerName: order.shippingAddress?.fullName
        ? order.shippingAddress?.fullName
        : order.shippingAddress?.name,
      searchableContent: `
        ${order._id}
        ${
          order.shippingAddress?.name
            ? order.shippingAddress?.name
            : order.shippingAddress?.fullName
        }
        ${
          order.shippingAddress?.street
            ? order.shippingAddress?.street
            : order.shippingAddress?.address1
        }
        ${
          order.shippingAddress?.city
            ? order.shippingAddress?.city
            : order.shippingAddress?.address2
        }
        ${order.shippingAddress?.state}
        ${order.shippingAddress?.pincode}
        ${order.shippingAddress?.country}
        ${
          order.shippingAddress?.number
            ? order.shippingAddress?.number
            : order.shippingAddress?.mobile
        }
        ${order.shippingAddress?.email}
        ${order.items
          ?.map((item) => `${item.name} ${item.quantity} ${item.price}`)
          .join(" ")}
        ${order.totalAmount}
        ${order.paymentmethod}
        ${order.status}
        ${order.remarks}
      `.toLowerCase(),
      address: (
        <div>
          <p>
            {order.shippingAddress?.fullName
              ? order.shippingAddress?.fullName
              : order.shippingAddress?.name}
          </p>
          <p>
            {order.shippingAddress?.street
              ? order.shippingAddress?.street
              : order.shippingAddress?.address1}
          </p>
          <p>
            {order.shippingAddress?.city
              ? order.shippingAddress?.city
              : order.shippingAddress?.address2}
            , {order.shippingAddress?.state} {order.shippingAddress?.pincode}
          </p>
          <p>{order.shippingAddress?.country}</p>
          <p>
            Phone:{" "}
            {order.shippingAddress?.number
              ? order.shippingAddress?.number
              : order.shippingAddress?.mobile}
          </p>
          <p>Email: {order.shippingAddress?.email}</p>
        </div>
      ),
      products: (
        <ul>
          {order.items?.map((item, idx) => (
            <li key={idx}>
              {item.name} - {item.quantity} x {item.price} INR - {item.weight}
              <BiEdit
                style={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() =>
                  handleEditProduct(order._id, item, order.discount)
                }
              />
            </li>
          ))}
        </ul>
      ),
      amount: order.totalAmount,
      discount: order.discount,
      date: moment(order.orderDate).format("DD/MM/YYYY"),
      dateForFiltering: order.orderDate,
      additionalInstructions: order.additionalInstructions,
      status: order.status,
      paymentmethod: order.paymentMethod,
      remarks: order.remarks,
      actions: (
        <Space>
          {order.deliveryBoyId === null ? (
            <Select
              style={{ width: 150 }}
              placeholder="Select Delivery Boy"
              onChange={(value) => handleAssignDeliveryBoy(order._id, value)}
            >
              {driver?.map((boy) => {
                return (
                  <Select.Option key={boy._id} value={boy._id}>
                    {boy.name}
                  </Select.Option>
                );
              })}
            </Select>
          ) : (
            <span>{order.deliveryBoyId?.name}</span>
          )}
          {/* <Button type="danger" onClick={() => showDeleteConfirm(order._id)}>
            Delete
          </Button> */}
        </Space>
      ),
    }));
  };

  const applyFilters = () => {
    let filtered = [...orders];

    if (searchText) {
      const searchLower = searchText.toLowerCase();
      filtered = filtered.filter((order) =>
        order.searchableContent.includes(searchLower)
      );
    }

    if (dateRange?.[0] && dateRange?.[1]) {
      const startDate = dateRange[0].startOf("day").valueOf();
      const endDate = dateRange[1].endOf("day").valueOf();

      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.dateForFiltering).valueOf();
        return orderDate >= startDate && orderDate <= endDate;
      });
    }

    if (
      filtered.length === 0 &&
      (searchText || (dateRange?.[0] && dateRange?.[1]))
    ) {
      message.warning("No orders found with the applied filters.");
    }

    setFilteredOrders(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const exportToExcel = () => {
    const dataToExport = filteredOrders.map(
      ({
        searchableContent,
        products,
        address,
        actions,
        dateForFiltering,
        ...rest
      }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, "orders.xlsx");
  };

  const showOrderDetails = (order) => {
    setSelectedOrder(order);
    setIsDetailsModalVisible(true);
    handleViewSalesOrder(order?.orderId)
  };

  const columns = [
    { title: "SNo", dataIndex: "Sno", width: 70 },
    { title: "Order ID", dataIndex: "orderId", width: 150 },
    { title: "Customer Name", dataIndex: "customerName", width: 150 },
    { title: "Total Amount", dataIndex: "amount", width: 150 },
    { title: "Date", dataIndex: "date", width: 150 },
    { title: "Status", dataIndex: "status", width: 150 },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 250,
      render: (_, record) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => showOrderDetails(record)}
          >
            View Details
          </Button>
          {record.actions}
        </Space>
      ),
    },
  ];

  const renderDetailsModal = () => (
    <Modal
      title="Order Details"
      visible={isDetailsModalVisible}
      onCancel={() => setIsDetailsModalVisible(false)}
      footer={null}
      width={800}
    >
      <div>
        <Descriptions bordered column={2} layout="horizontal">
          <Descriptions.Item label="Date">
            {new Date(selectedOrder?.date).toLocaleDateString()}
          </Descriptions.Item>
          <Descriptions.Item label="Total Amount">
            ₹{selectedOrder?.amount?.toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Discount">
            ₹{selectedOrder?.discount?.toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Method">
            {selectedOrder?.paymentmethod}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {selectedOrder?.status}
          </Descriptions.Item>
        </Descriptions>

        <h4>Items:</h4>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Products">
            {selectedOrder?.products}
          </Descriptions.Item>
        </Descriptions>

        {salesOrderData?.orderId === selectedOrder?.orderId ? (
          <>
            <h4 style={{ marginTop: "20px" }}>Sales Order:</h4>
            <Table
              dataSource={salesOrderData?.items}
              columns={columns1}
              rowKey="itemId"
              pagination={false}
            />
             <div style={{ marginBottom: "20px" }}>
          <strong>Total Sales Amount:</strong> {salesOrderData?.totalAmount}
        </div>
          </>
        ) : (
          // <p>No sales order found</p> 
          ""
        )}
       
        <h4>Shipping Address:</h4>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Customer Name">
            {selectedOrder?.customerName}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {selectedOrder?.address}
          </Descriptions.Item>
        </Descriptions>

        {selectedOrder?.additionalInstructions && (
          <div>
            <h4>Additional Instructions:</h4>
            <p>{selectedOrder?.additionalInstructions}</p>
          </div>
        )}

        {selectedOrder?.remarks && (
          <div>
            <h4>Remarks:</h4>
            <p>{selectedOrder?.remarks}</p>
          </div>
        )}
      </div>
    </Modal>
  );
  const columns1 = [
    { title: "Item Name", dataIndex: "name", key: "name" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Weight", dataIndex: "weight", key: "weight" },
    { title: "Price", dataIndex: "price", key: "price" },
  ];
  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={3}>Orders</Title>
        <Space>
          <Input
            placeholder="Search orders"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
          />
          <DatePicker.RangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
          />
          <Button
            type="primary"
            onClick={exportToExcel}
            icon={<FileSpreadsheet size={16} />}
          >
            Export to Excel
          </Button>
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={filteredOrders}
        scroll={{ x: "max-content" }}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
        loading={loading}
      />

      {renderDetailsModal()}

      <Modal
        title="Edit Product"
        visible={isModalVisible}
        onOk={handleSaveEdit}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
      >
        <Form form={form}>
          <Form.Item
            label="Quantity"
            name="quantity"
          >
            <Input type="number" min={1} />
          </Form.Item>
          <Form.Item
            label="Weight"
            name="weight"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
          >
            <Input type="number" min={1} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Orders;
