import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, Table, Modal, message, Space, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Trash2, Edit, FileSpreadsheet } from 'lucide-react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const API_BASE_URL = 'https://parnetslink.xyz/api/admin';
const { Title } = Typography;
const AddCategory = () => {
  const [categories, setCategories] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
const token = sessionStorage.getItem("token");

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    setFilteredCategories(
      categories.filter((category) =>
        Object.values(category).some(
          (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [categories, searchText]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getCategory`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories(response.data.category);
    } catch (error) {
      message.error('Failed to fetch categories');
    }
  };

  const handleAddCategory = async (values) => {
    try {
      const formData = new FormData();
      formData.append('categoryName', values.categoryName);
      formData.append('categoryimage', values.categoryimage.file);

      await axios.post(`${API_BASE_URL}/addCategory`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, headers: {
          Authorization: `Bearer ${token}`,
        },
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Category added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchCategories();
    } catch (error) {
      message.error('Failed to add category');
    }
  };

  const handleEditCategory = async (values) => {
    try {
      const formData = new FormData();
      formData.append('categoryName', values.categoryName);
      if (values.categoryimage && values.categoryimage.file) {
        formData.append('categoryimage', values.categoryimage.file);
      }

      await axios.post(`${API_BASE_URL}/editCategory/${editingCategory._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, headers: {
          Authorization: `Bearer ${token}`,
        },
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Category updated successfully');
      setIsEditModalVisible(false);
      form.resetFields();
      fetchCategories();
    } catch (error) {
      message.error('Failed to update category');
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/deleteCategory/${id}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Category deleted successfully');
      fetchCategories();
    } catch (error) {
      message.error('Failed to delete category');
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredCategories.map(({ _id, categoryName, categoryimage }) => ({
      'Category Name': categoryName,
      'Image URL': `https://parnetslink.xyz/image/${categoryimage}`,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Categories');
    XLSX.writeFile(workbook, 'categories.xlsx');
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serialNo',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Category Image',
      dataIndex: 'categoryimage',
      key: 'categoryimage',
      render: (image) => (
        <img src={`https://parnetslink.xyz/image/${image}`} alt="Category" style={{ width: 80, height: 80 }} />
      ),
    },
    {
      title: 'Category Name',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<Edit color="blue" />}
            onClick={() => {
              setEditingCategory(record);
              setIsEditModalVisible(true);
              form.setFieldsValue(record);
            }}
          />
          <Button
            type="text"
            icon={<Trash2 color="red" />}
            onClick={() => handleDeleteCategory(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
      <Title level={3} className="title">Categories</Title>

        <Space>
          <Input
            placeholder="Search categories"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
            Add Category
          </Button>
          <Button type="primary"  icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>Export to Excel</Button>
        </Space>
      </div>

      <Table columns={columns} dataSource={filteredCategories} rowKey="_id" pagination={{
              pageSize: 5,
              showSizeChanger: true, 
            }}
            scroll={{ x: "max-content" }}
            components={{
              header: {
                cell: (props) => (
                  <th
                    {...props}
                    style={{
                      ...props.style,
                      background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                      color: "white",
                    }}
                  />
                ),
              }}}/>

      <Modal
        title="Add Category"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddCategory} layout="vertical">
          <Form.Item
            name="categoryName"
            label="Category Name"
            rules={[{ required: true, message: 'Please enter a category name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="categoryimage"
            label="Category Image"
            rules={[{ required: true, message: 'Please upload a category image' }]}
          >
            <Upload accept="image/*" listType="picture-card" maxCount={1} beforeUpload={() => false}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Category
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Category"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditCategory} layout="vertical">
          <Form.Item
            name="categoryName"
            label="Category Name"
            rules={[{ required: true, message: 'Please enter a category name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="categoryimage" label="Category Image">
            <Upload accept="image/*" listType="picture-card" maxCount={1} beforeUpload={() => false}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Category
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddCategory;