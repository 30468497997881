import React, { useState } from "react";
import "./login.css";
import axios from "axios";

import { Container, Form, Row, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import CustomInput from "./admin/components/CustomInput";
import Modal from "./common/header/Modal";
export default function LoginRegister() {
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [amobile, setamobile] = useState();
  const [houseno, sethouseno] = useState("");
  const [landmark, setlandmark] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [postcode, setpostcode] = useState("");
  // const [deliveryZone, setdeliveryZone] = useState("");
  const [Username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(true);
  const [successshow, setsuccessshow] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const handleOtpClose = () => setOtpShow(false);
  const handleOtpShow = () => setOtpShow(true);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const isPasswordValid = (password) => {
    if (password.length < 8) return false;
    if (!/[A-Z]/.test(password)) return false;
    if (!/[a-z]/.test(password)) return false;
    if (!/\d/.test(password)) return false;
    return true;
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@gmail\.com$/;
    return emailRegex.test(email);
  };
  const isMobileValid = (mobile) => {
    const mobileRegex = /^[6-9][0-9]{9}$/;
    return mobileRegex.test(mobile);
  };

  const isPincodeValid = (postcode) => {
    const pincodeRegex = /^[0-9]{6}$/;
    return pincodeRegex.test(postcode);
  };
  const handleClick = async () => {
    if (!Username?.trim()) {
      toast.info("Please enter your name.");
    } else if (!mobile?.trim()) {
      toast.info("Please enter your mobile number.");
    } else if (!isMobileValid(mobile)) {
      toast.info("Please enter a valid mobile number.");
    } else if (!isMobileValid(amobile)) {
      toast.info("Please enter a valid alternate mobile number.");
    } else if (!isEmailValid(email)) {
      toast.info("Please enter a valid email address (e.g., user@gmail.com).");
    } else if (!houseno?.trim()) {
      toast.info("Please enter your house number.");
    } else if (!address1?.trim()) {
      toast.info("Please enter your address line 1.");
    } else if (!landmark?.trim()) {
      toast.info("Please enter a landmark.");
    } else if (!postcode?.trim()) {
      toast.info("Please enter your postal code.");
    } else if (!isPincodeValid(postcode)) {
      toast.info("Please enter a valid postal code.");
    } else if (!isPasswordValid(password)) {
      toast.info(
        "Use a minimum of 8 characters, including uppercase letters, lowercase letters, and numbers."
      );
    } else if (password !== cpassword) {
      toast.info("Passwords don't match");
    } else if (!termsAccepted) {
      toast.info("Please accept the terms and conditions to proceed.");
    } else {
      try {
        const config = {
          url: "/registerUser",
          method: "post",
          baseURL: "https://parnetslink.xyz/api/user",
          headers: { "content-type": "application/json" },
          data: {
            mobile: mobile,
            Amobile: amobile,
            Houseno: houseno,
            address1: address1,
            address2: address2,
            landmark: landmark,
            pincode: postcode,
            // deliveryzone: deliveryZone,
            email: email,
            password: password,
            name: Username,
            cpassword: cpassword,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            handleOtpShow();
          }
        });
      } catch (error) {
        console.error(error);
        toast.info(error.response.data.error);
      }
    }
  };
  const handleOtpVerification = async () => {
    try {
      const config = {
        url: "/userotpverify",
        method: "post",
        baseURL: "https://parnetslink.xyz/api/user",
        headers: { "Content-Type": "application/json" },
        data: {
          email: email,
          otp: enteredOtp,
        },
      };

      const response = await axios(config);

      if (response.status === 200) {
        toast.info(response.data.success);
        handleOtpClose();
        window.location.assign("/login");
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.info(error.response.data.error);
      } else {
        toast.info(
          "An error occurred during OTP verification. Please try again."
        );
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="logo text-center"></div>
      <div className="wrapper1">
        <div className="inner-wrapper1 text-center">
          <h4 className="title">Register Your Account</h4>

          <div className="input-row">
            <div
              class="registerinput"
              style={{ position: "relative", width: "30%" }}
            >
              <CustomInput
                type="text"
                label="User Name"
                id="userName"
                name="userName"
                onChng={(e) =>{const value = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(value)) {
                    setUsername(value); // Only set the value if it's valid
                  }}}
                val={Username}
              />
              <span class="lighting"></span>
            </div>
            <div
              class="registerinput"
              style={{ position: "relative", width: "33%" }}
            >
              <CustomInput
                type="text"
                label="Email"
                id="Email"
                name="Email"
                onChng={(e) => setemail(e.target.value)}
                val={email}
              />
              <span class="lighting"></span>
            </div>
            <div
              class="registerinput"
              style={{ position: "relative", width: "34%" }}
            >
              <CustomInput
                type="tel"
                label="Phone Number"
                id="Phone Number"
                name="Phone Number"
                onChng={(e) =>{const value = e.target.value;
                  if (/^[0-9\s]*$/.test(value)) {
                    setmobile(value); // Only set the value if it's valid
                  }}}
                val={mobile}
              />
              <span class="lighting"></span>
            </div>
          </div>
          <div className="input-row">
            <div
              class="registerinput"
              style={{ position: "relative", width: "30%" }}
            >
              <CustomInput
                type="tel"
                label="Alternative Phone Number"
                id="APhone Number"
                name="APhone Number"
                onChng={(e) =>{const value = e.target.value;
                  if (/^[0-9\s]*$/.test(value)) {
                    setamobile(value); // Only set the value if it's valid
                  }}}
                val={amobile}
              />
              <span class="lighting"></span>
            </div>
            <div
              class="registerinput"
              style={{ position: "relative", width: "33%" }}
            >
              <CustomInput
                type="text"
                label="House Number"
                id="HouseNumber"
                name="HouseNumber"
                onChng={(e) => sethouseno(e.target.value)}
                val={houseno}
              />
              <span class="lighting"></span>
            </div>
            <div
              class="registerinput"
              style={{ position: "relative", width: "34%" }}
            >
              <CustomInput
                type="text"
                label="Address Line 1"
                id="address1"
                name="address1"
                onChng={(e) => setaddress1(e.target.value)}
                val={address1}
              />
              <span class="lighting"></span>
            </div>
          </div>
          <div className="input-row">
            <div
              class="registerinput"
              style={{ position: "relative", width: "30%" }}
            >
              <CustomInput
                type="text"
                label="Address Line 2"
                id="address2"
                name="address2"
                onChng={(e) => setaddress2(e.target.value)}
                val={address2}
              />
              <span class="lighting"></span>
            </div>
            <div
              class="registerinput"
              style={{ position: "relative", width: "33%" }}
            >
              <CustomInput
                type="text"
                label="Landmark"
                id="landmark"
                name="landmark"
                onChng={(e) => setlandmark(e.target.value)}
                val={landmark}
              />
              <span class="lighting"></span>
            </div>
            <div
              class="registerinput"
              style={{ position: "relative", width: "34%" }}
            >
              <CustomInput
                type="number"
                label="Post Code"
                id="postcode"
                name="postcode"
                onChng={(e) =>{const value = e.target.value;
                  if (/^[0-9\s]*$/.test(value)) {
                    setpostcode(value); // Only set the value if it's valid
                  }}}
                val={postcode}
              />
              <span class="lighting"></span>
            </div>
          </div>
          <div className="input-row">
            <div
              class="registerinput"
              style={{ position: "relative", width: "49%" }}
            >
              <CustomInput
                type={!showPassword ? "text" : "password"}
                label="Password"
                id="pass"
                name="password"
                onChng={(e) => setpassword(e.target.value)}
                val={password}
              />
              <i
                className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              ></i>
            </div>

            <div
              class="registerinput"
              style={{ position: "relative", width: "49%" }}
            >
              <CustomInput
                type={!showPassword1 ? "text" : "password"}
                label="Confirm Password"
                id="cpass"
                name="cpassword"
                onChng={(e) => setcpassword(e.target.value)}
                val={cpassword}
              />
              <i
                className={showPassword1 ? "fas fa-eye-slash" : "fas fa-eye"}
                onClick={togglePasswordVisibility1}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              ></i>
            </div>
          </div>
          <div className="input-row" style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                style={{ cursor: "pointer" }}
              />
              <label htmlFor="terms" style={{ cursor: "pointer", margin: 0 }}>
                I have read and agree to the{" "}
                <a href="">
                  Terms and Conditions
                </a>
              </label>
            </div>
          </div>
          <br />
          <button
            className="button1"
            type="submit"
            id="register"
            style={{ width: "300px", marginLeft: "auto", marginRight: "auto" }}
            onClick={handleClick}
          >
            Register
          </button>
        </div>
        <div className="signup-wrapper1 text-center">
          <a href="/login">
            Already have an account?{" "}
            <span className="text-primary">Sign In</span>
          </a>
        </div>
      </div>

      <Modal isOpen={otpShow} onClose={() => setOtpShow(false)}>
        <h2>Enter OTP</h2>
        <div className="form-group">
          <input
            type="text"
            value={enteredOtp}
            onChange={(e) => setEnteredOtp(e.target.value)}
            placeholder="Enter OTP"
          />
        </div>
        <button className="btn btn-secondary" onClick={() => setOtpShow(false)}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleOtpVerification}>
          Verify
        </button>
      </Modal>

      <ToastContainer />
    </div>
  );
}
