import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Modal, message, Space, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Trash2, Edit, FileSpreadsheet } from 'lucide-react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const API_BASE_URL = 'https://parnetslink.xyz/api/admin';
const { Title } = Typography;

const AddTag = () => {
  const token = sessionStorage.getItem("token");

  const [tags, setTags] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingTag, setEditingTag] = useState(null);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    setFilteredTags(
      tags.filter((tag) =>
        Object.values(tag).some(
          (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [tags, searchText]);

  const fetchTags = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getTag`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      setTags(response.data?.Tag || []);
    } catch (error) {
      message.error('Failed to fetch tags');
    }
  };

  const handleAddTag = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/addTag`, {
        tag: values.tag
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Tag added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchTags();
    } catch (error) {
      message.error('Failed to add tag');
    }
  };

  const handleEditTag = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/editTag/${editingTag._id}`, {
        tag: values.tag
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Tag updated successfully');
      setIsEditModalVisible(false);
      form.resetFields();
      fetchTags();
    } catch (error) {
      message.error('Failed to update tag');
    }
  };

  const handleDeleteTag = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/deletetag/${id}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Tag deleted successfully');
      fetchTags();
    } catch (error) {
      message.error('Failed to delete tag');
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredTags.map(({ _id, tag }) => ({
      'Tag': tag,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tags');
    XLSX.writeFile(workbook, 'tags.xlsx');
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serialNo',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<Edit color="blue" />}
            onClick={() => {
              setEditingTag(record);
              setIsEditModalVisible(true);
              form.setFieldsValue({ tag: record.tag });
            }}
          />
          <Button
            type="text"
            icon={<Trash2 color="red" />}
            onClick={() => handleDeleteTag(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Title level={3} className="title">Tags</Title>
        <Space>
          <Input
            placeholder="Search tags"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
            Add Tag
          </Button>
          <Button  type="primary" icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>Export to Excel</Button>
        </Space>
      </div>

      <Table 
        columns={columns} 
        dataSource={filteredTags} 
        rowKey="_id" 
        scroll={{ x: "max-content" }}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
      />

      <Modal
        tag="Add Tag"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddTag} layout="vertical">
          <Form.Item
            name="tag"
            label="Tag"
            rules={[{ required: true, message: 'Please enter a tag' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Tag
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        tag="Edit Tag"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditTag} layout="vertical">
          <Form.Item
            name="tag"
            label="Tag"
            rules={[{ required: true, message: 'Please enter a tag' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Tag
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddTag;