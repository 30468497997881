import React, { useEffect, useState } from "react";
import { Download, Printer } from "lucide-react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import axios from "axios";

const InvoicePage = () => {
  const [order, setOrder] = useState(null);
  const [salesOrderData, setSalesOrderData] = useState(null);
const token = sessionStorage.getItem("token");
  useEffect(() => {
    const storedOrder = JSON.parse(sessionStorage.getItem("InvoiceItem"));
    if (storedOrder) {
      setOrder(storedOrder);
      fetchSalesOrder(storedOrder._id); // Fetch sales order when order is available
    }
  }, []);

  const fetchSalesOrder = async (orderId) => {
    try {
      const response = await axios.get(
        `https://parnetslink.xyz/api/user/getSalesOrder/${orderId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setSalesOrderData(response.data.data);
      } else {
        console.error("Sales order not found");
      }
    } catch (error) {
      console.error("Error fetching sales order:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const doc = new jsPDF();

    // Add header
    doc.setFontSize(16);
    doc.text("Abhinandhan Organics", 105, 15, { align: "center" });
    doc.setFontSize(10);
    doc.text("123 Store Street, City, State - 123456", 105, 22, {
      align: "center",
    });
    doc.text("Phone: (123) 456-7890", 105, 27, { align: "center" });

    // Add invoice details
    doc.setFontSize(10);
    doc.text(`Invoice No: ${order._id}`, 14, 40);
    doc.text(`Date: ${new Date(order.orderDate).toLocaleDateString()}`, 14, 45);
    doc.text(`Customer: ${order.shippingAddress.fullName}`, 14, 50);
    doc.text(`Email: ${order.shippingAddress.email}`, 14, 55);

    // Add items table
    const tableData = order.items.map((item) => [
      item.name,
      item.quantity,
      `₹${item.price.toFixed(2)}`,
      `₹${(item.quantity * item.price).toFixed(2)}`,
    ]);

    autoTable(doc, {
      startY: 60,
      head: [["Item", "Qty", "Price", "Total"]],
      body: tableData,
      theme: "plain",
      styles: { fontSize: 9, cellPadding: 1.5 },
      headStyles: { fontStyle: "bold", fillColor: false },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: 20, halign: "right" },
        2: { cellWidth: 20, halign: "right" },
        3: { cellWidth: 20, halign: "right" },
      },
    });

    // Add sales order details if available
    if (salesOrderData) {
      doc.text("Sales Order Details:", 14, doc.lastAutoTable.finalY + 10);

      const salesTableData = salesOrderData.items.map((item) => [
        item.name,
        item.quantity,
        item.weight,
        `₹${item.price.toFixed(2)}`,
      ]);

      autoTable(doc, {
        startY: doc.lastAutoTable.finalY + 15,
        head: [["Item", "Qty", "Weight", "Price"]],
        body: salesTableData,
        theme: "plain",
        styles: { fontSize: 9, cellPadding: 1.5 },
        headStyles: { fontStyle: "bold", fillColor: false },
        columnStyles: {
          0: { cellWidth: "auto" },
          1: { cellWidth: 20, halign: "right" },
          2: { cellWidth: 30, halign: "right" },
          3: { cellWidth: 20, halign: "right" },
        },
      });

      const finalY = doc.lastAutoTable.finalY + 5;
      doc.setFontSize(10);
      doc.text(
        `Total Sales Amount: ₹${salesOrderData.totalAmount.toFixed(2)}`,
        14,
        finalY
      );
    }

    // Add totals
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(10);
    doc.text(`Subtotal: ₹${order.totalAmount.toFixed(2)}`, 14, finalY);
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text(`Total: ₹${order.totalAmount.toFixed(2)}`, 14, finalY + 6);

    // Add footer
    doc.setFontSize(9);
    doc.setFont(undefined, "normal");
    doc.text("Thank you for shopping with us!", 105, finalY + 15, {
      align: "center",
    });
    doc.text(
      "Please keep this invoice for any returns or exchanges.",
      105,
      finalY + 20,
      { align: "center" }
    );

    // Save the PDF
    doc.save(`invoice_${order._id}.pdf`);
  };

  if (!order) {
    return <p>Loading invoice...</p>;
  }

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white shadow-lg print:shadow-none">
      {/* Header */}
      <div className="text-center mb-4">
        <h1 className="text-2xl font-bold">Abhinandhan Organics</h1>
        <p className="text-sm">123 Store Street, City, State - 123456</p>
        <p className="text-sm">Phone: (123) 456-7890</p>
      </div>

      {/* Invoice Details */}
      <div className="flex justify-between text-sm mb-4">
        <div>
          <p>
            <strong>Invoice No:</strong> {order._id}
          </p>
          <p>
            <strong>Date:</strong>{" "}
            {new Date(order.orderDate).toLocaleDateString()}
          </p>
        </div>
        <div className="text-right">
          <p>
            <strong>Customer:</strong>{" "}
            {order.shippingAddress.fullName
              ? order.shippingAddress.fullName
              : order.shippingAddress.name}
          </p>
          <p>
            <strong>Address:</strong>
            {order.shippingAddress?.street
              ? order.shippingAddress?.street
              : order.shippingAddress?.address1}
            , <br />
            {order.shippingAddress?.city
              ? order.shippingAddress?.city
              : order.shippingAddress?.address2}
            {order.shippingAddress?.state ? "," : ""}{" "}
            {order.shippingAddress?.state}, {order.shippingAddress?.pincode}
          </p>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between text-sm font-bold bg-gray-200 p-2">
          <span className="w-1/2">Item</span>
          <span className="w-1/6 text-right">Qty</span>
          <span className="w-1/6 text-right">Price</span>
          <span className="w-1/6 text-right">Total</span>
        </div>
        {order.items.map((item, index) => (
          <div
            key={index}
            className="flex justify-between text-sm border-b py-2"
          >
            <span className="w-1/2">{item.name}</span>
            <span className="w-1/6 text-right">{item.quantity}</span>
            <span className="w-1/6 text-right">₹{item.price.toFixed(2)}</span>
            <span className="w-1/6 text-right">
              ₹{(item.quantity * item.price).toFixed(2)}
            </span>
          </div>
        ))}
      </div>

      {/* Sales Order Table */}
      {salesOrderData && (
        <div className="mb-4">
          <h4 className="font-bold">Sales Order Items</h4>

          <div className="mb-4">
            <div className="flex justify-between text-sm font-bold bg-gray-200 p-2">
              <span className="w-1/2">Item</span>
              <span className="w-1/6 text-right">Qty</span>
              <span className="w-1/6 text-right">Price</span>
              <span className="w-1/6 text-right">Total</span>
            </div>
            {salesOrderData.items.map((item, index) => (
              <div
                key={index}
                className="flex justify-between text-sm border-b py-2"
              >
                <span className="w-1/2">{item.name}</span>
                <span className="w-1/6 text-right">{item.quantity}</span>
                <span className="w-1/6 text-right">
                  ₹{item.price.toFixed(2)}
                </span>
                <span className="w-1/6 text-right">
                  ₹{(item.quantity * item.price).toFixed(2)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Total Section */}
      <div className="text-right mb-4">
        {!salesOrderData && (
          <p>
            <strong>Subtotal:</strong> ₹{order.totalAmount.toFixed(2)}
          </p>
        )}
        {salesOrderData && (
          <p>
            <strong>Total Sales Amount:</strong> ₹
            {salesOrderData.totalAmount.toFixed(2)}
          </p>
        )}
        {!salesOrderData && (
          <p className="text-lg font-bold">
            Total: ₹{order.totalAmount.toFixed(2)}
          </p>
        )}
      </div>
      <div className="text-center text-sm mb-4">
        <p>Thank you for shopping with us!</p>
        <p>Please keep this invoice for any returns or exchanges.</p>
      </div>
      {/* Actions */}
      <div className="flex justify-center">
        <button
          onClick={handlePrint}
          className="px-4 py-2 bg-green-500 text-white flex items-center"
        >
          <Download className="mr-2" /> Download
        </button>
        {/* <button
          onClick={handleDownload}
          className="px-4 py-2 bg-green-500 text-white flex items-center"
        >
          <Download className="mr-2" /> Print
        </button> */}
      </div>
    </div>
  );
};

export default InvoicePage;
