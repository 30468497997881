import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "../common/header/Header";
import Home from "../Home";
import Footer from "../common/footer/Footer";
import Shop from "../Shop";
import ShopDetail from "../ShopDetail";
import TestimonialMain from "../testimonial/TestimonialMain";
import Error from "../Error";
import Contact from "../Contact";
import Cart from "../carts";
import Checkout from "../Checkout";
import Wallet from "../wallet";
import LoginForm from "../login";
import LoginRegister from "../register";
import Account from "../Account";
import Wishlist1 from "../wishlist";

import Login from "../admin/pages/Login";
import Resetpassword from "../admin/pages/Resetpassword";
import Forgotpassword from "../admin/pages/Forgotpassword";
import MainLayout from "../admin/components/MainLayout";
import Dashboard from "../admin/pages/Dashboard";
import Enquiries from "../admin/pages/Enquiries";
import ViewEnq from "../admin/pages/ViewEnq";
import Bloglist from "../admin/pages/Bloglist";
import Addblog from "../admin/pages/Addblog";
import Couponlist from "../admin/pages/Couponlist";
import AddCoupon from "../admin/pages/AddCoupon";
import Blogcatlist from "../admin/pages/Blogcatlist";
import Addblogcat from "../admin/pages/Addblogcat";
import Orders from "../admin/pages/Orders";
import ViewOrder from "../admin/pages/ViewOrder";
import Customers from "../admin/pages/Customers";
import Colorlist from "../admin/pages/Colotlist";
import Addcolor from "../admin/pages/Addcolor";
import Categorylist from "../admin/pages/Categorylist";
import Addcat from "../admin/pages/Addcat";
import Brandlist from "../admin/pages/Brandlist";
import Addbrand from "../admin/pages/Addbrand";
import Productlist from "../admin/pages/Productlist";
import Addproduct from "../admin/pages/Addproduct";
import SubscribeCart from "../subscribes";
import AddBanner from "../admin/pages/addbanner";
import AddCategory from "../admin/pages/addcategory";
import AddSubCategory from "../admin/pages/addsubcategory";
import AddTitle from "../admin/pages/AddTitle";
import AddTag from "../admin/pages/AddTag";
import AddDiscountBanner from "../admin/pages/adddisbanner";
import ClubLoginForm from "../clublogin";
import ClubLoginRegister from "../clubregister";
import ClubCustomers from "../admin/pages/ClubCustomers";
import AddUnit from "../admin/pages/AddUnit";
import Subscriptions from "../admin/pages/subscriptionorder";
import AddTestimonial from "../admin/pages/addTestimonial";
import Scheduled from "../Scheduled";
import AddFruits from "../admin/pages/AddvegetableFruits";
import FruitsList from "../admin/pages/Fruitsegetableslist";
import AbandonedCartList from "../admin/pages/abandoned";
import AddclubBanner from "../admin/pages/joinclubbanner";
import ShopDetail1 from "../ShopDetail1";
import Orders1 from "../admin/pages/ScheduledOrders";
import Leaves from "../admin/pages/Driverleaves";
import Checkins from "../admin/pages/DriverCheckins";
import Drivers from "../admin/pages/Driver";
import InvoicePage from "../Invoice";
import AddText from "../admin/pages/AddText";
import DeliveryChargesList from "../admin/pages/DeliveryCharges";

const AdminRoute = ({ children }) => {
  const [isAdminRoute, setIsAdminRoute] = useState(true);
  return <>{children}</>;
};

const ClientRoute = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

const Pages = () => {
  return (
    <Router>
      <Routes>
        {/* Client Routes */}
        <Route
          path="/"
          element={
            <ClientRoute>
              <Home />
            </ClientRoute>
          }
        />
        <Route
          path="/shop"
          element={
            <ClientRoute>
              <Shop />
            </ClientRoute>
          }
        />
        <Route
          path="/Scheduled"
          element={
            <ClientRoute>
              <Scheduled />
            </ClientRoute>
          }
        />

        {/* <Route path="/shop-detail" element={<ClientRoute><ShopDetail /></ClientRoute>} /> */}
        <Route
          path="/shop-detail/:id"
          element={
            <ClientRoute>
              <ShopDetail />
            </ClientRoute>
          }
        />
        <Route
          path="/shop-detail1/:id"
          element={
            <ClientRoute>
              <ShopDetail1 />
            </ClientRoute>
          }
        />
        <Route
          path="/testimonial"
          element={
            <ClientRoute>
              <TestimonialMain />
            </ClientRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ClientRoute>
              <Cart />
            </ClientRoute>
          }
        />
        <Route
          path="/clublogin"
          element={
            <ClientRoute>
              <ClubLoginForm />
            </ClientRoute>
          }
        />

        <Route
          path="/login"
          element={
            <ClientRoute>
              <LoginForm />
            </ClientRoute>
          }
        />
        <Route
          path="/clubregister"
          element={
            <ClientRoute>
              <ClubLoginRegister />
            </ClientRoute>
          }
        />

        <Route
          path="/register"
          element={
            <ClientRoute>
              <LoginRegister />
            </ClientRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ClientRoute>
              <Wallet />
            </ClientRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ClientRoute>
              <Account />
            </ClientRoute>
          }
        />
        <Route
          path="/wishlist"
          element={
            <ClientRoute>
              <Wishlist1 />
            </ClientRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <ClientRoute>
              <Checkout />
            </ClientRoute>
          }
        />
        <Route
          path="/error"
          element={
            <ClientRoute>
              <Error />
            </ClientRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <ClientRoute>
              <Contact />
            </ClientRoute>
          }
        />
        <Route
          path="/subscribe/:id"
          element={
            <ClientRoute>
              <SubscribeCart />
            </ClientRoute>
          }
        />
        {/* Admin Routes */}
        <Route
          path="/admin"
          element={
            <AdminRoute>
              <Login />
            </AdminRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <AdminRoute>
              <Resetpassword />
            </AdminRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AdminRoute>
              <Forgotpassword />
            </AdminRoute>
          }
        />
        <Route
          path="/admindashboard"
          element={
            <AdminRoute>
              <MainLayout />
            </AdminRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="banner" element={<AddBanner />} />
          <Route path="clubbanner" element={<AddclubBanner />} />
          <Route path="disbanner" element={<AddDiscountBanner />} />
          <Route path="leaves" element={<Leaves />} />
          <Route path="Checkins" element={<Checkins />} />
          <Route path="Drivers" element={<Drivers />} />

          <Route path="category" element={<AddCategory />} />
          <Route path="subcategory" element={<AddSubCategory />} />

          <Route path="enquiries" element={<Enquiries />} />
          <Route path="enquiries/:id" element={<ViewEnq />} />
          <Route path="blog-list" element={<Bloglist />} />
          <Route path="blog" element={<Addblog />} />
          <Route path="blog/:id" element={<Addblog />} />
          <Route path="coupon-list" element={<Couponlist />} />
          <Route path="AbandonedCartList" element={<AbandonedCartList />} />

          <Route path="coupon" element={<AddCoupon />} />
          <Route path="coupon/:id" element={<AddCoupon />} />
          <Route path="blog-category-list" element={<Blogcatlist />} />
          <Route path="blog-category" element={<Addblogcat />} />
          <Route path="blog-category/:id" element={<Addblogcat />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders1" element={<Orders1 />} />
          <Route path="subscription" element={<Subscriptions />} />
          <Route path="testimonial" element={<AddTestimonial />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="customers" element={<Customers />} />
          <Route path="clubcustomers" element={<ClubCustomers />} />

          <Route path="list-color" element={<Colorlist />} />
          <Route path="color" element={<Addcolor />} />
          <Route path="color/:id" element={<Addcolor />} />
          <Route path="list-category" element={<Categorylist />} />
          <Route path="categorys" element={<Addcat />} />
          <Route path="categorys/:id" element={<Addcat />} />
          <Route path="list-type" element={<Brandlist />} />
          <Route path="type" element={<Addbrand />} />
          <Route path="title" element={<AddTitle />} />
          <Route path="tag" element={<AddTag />} />
          <Route path="text" element={<AddText />} />

          <Route path="unit" element={<AddUnit />} />
          <Route path="type/:id" element={<Addbrand />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="list-Fruits" element={<FruitsList />} />
          <Route path="product" element={<Addproduct />} />
          <Route path="DeliveryChargesList" element={<DeliveryChargesList />} />
          <Route path="fruits" element={<AddFruits />} />
        </Route>
        <Route path="/orderinvoice" element={<InvoicePage />} />
      </Routes>
    </Router>
  );
};

export default Pages;
