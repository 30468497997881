import "./App.css";
import React, { useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import Pages from "./components/pages/Pages";
import "antd/dist/reset.css";

import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import axios from "axios";
function App() {
  const token = sessionStorage.getItem("token");
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    console.log("Setting up message listener");
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received:", payload);
      console.log("Attempting to show notification");
      if (Notification.permission === "granted") {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: "../img/success.png", 
        };
        console.log("Attempting to show notification111");
        new Notification(notificationTitle, notificationOptions);
      }
      try {
        toast.info(
          `${payload.notification.title}: ${payload.notification.body}`
        );
        console.log("Notification displayed successfully");
      } catch (error) {
        console.error("Error displaying notification:", error);
      }
    });

    return () => {
      console.log("Cleaning up message listener");
      unsubscribe();
    };
  }, []);
  const items = useSelector((state) => state.cart.items);
  const timeoutRef = useRef(null);

  const timeoutDuration = 30 * 60 * 1000;
  const notifyAdmin = (cartData) => {
    axios
      .post('https://parnetslink.xyz/api/admin/abandoned-cart', {
        cart: cartData,
        user: user,
        timestamp: new Date(),
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },})
      .then((response) => {
        console.log('Admin notified of abandoned cart:', response.data);
      })
      .catch((error) => {
        console.error('Error notifying admin:', error);
      });
  };
  useEffect(() => {
    const handleUserActivity = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          if (items.length > 0 && user) {
            console.log("Cart is abandoned, notifying admin...");
            notifyAdmin(items);
          }
        }, timeoutDuration);
      }
    };
  
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keypress', handleUserActivity);
  
    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keypress', handleUserActivity);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [items, user]);
  return (
    <div className="App">
      <ToastContainer />
      <Pages />
    </div>
  );
}

export default App;
