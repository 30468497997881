import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Modal, message, Space, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Trash2, Edit, FileSpreadsheet } from 'lucide-react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const API_BASE_URL = 'https://parnetslink.xyz/api/admin';
const { Title } = Typography;

const AddUnit = () => {
  const token = sessionStorage.getItem("token");

  const [units, setUnits] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingUnit, setEditingUnit] = useState(null);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [filteredUnits, setFilteredUnits] = useState([]);

  useEffect(() => {
    fetchUnits();
  }, []);

  useEffect(() => {
    setFilteredUnits(
      units.filter((unit) =>
        Object.values(unit).some(
          (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [units, searchText]);

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getUnit`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      setUnits(response.data?.Unit || []);
    } catch (error) {
      message.error('Failed to fetch units');
    }
  };

  const handleAddUnit = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/addUnit`, {
        unit: values.unit
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Unit added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchUnits();
    } catch (error) {
      message.error('Failed to add unit');
    }
  };

  const handleEditUnit = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/editUnit/${editingUnit._id}`, {
        unit: values.unit
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Unit updated successfully');
      setIsEditModalVisible(false);
      form.resetFields();
      fetchUnits();
    } catch (error) {
      message.error('Failed to update unit');
    }
  };

  const handleDeleteUnit = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/deleteunit/${id}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Unit deleted successfully');
      fetchUnits();
    } catch (error) {
      message.error('Failed to delete unit');
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredUnits.map(({ _id, unit }) => ({
      'Unit': unit,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Units');
    XLSX.writeFile(workbook, 'units.xlsx');
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serialNo',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<Edit color="blue" />}
            onClick={() => {
              setEditingUnit(record);
              setIsEditModalVisible(true);
              form.setFieldsValue({ unit: record.unit });
            }}
          />
          <Button
            type="text"
            icon={<Trash2 color="red" />}
            onClick={() => handleDeleteUnit(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Title level={3} className="title">Units</Title>
        <Space>
          <Input
            placeholder="Search units"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
            Add Unit
          </Button>
          <Button type="primary"  icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>Export to Excel</Button>
        </Space>
      </div>

      <Table 
        columns={columns} 
        dataSource={filteredUnits} 
        rowKey="_id" 
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
      />

      <Modal
        unit="Add Unit"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddUnit} layout="vertical">
          <Form.Item
            name="unit"
            label="Unit"
            rules={[{ required: true, message: 'Please enter a unit' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Unit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        unit="Edit Unit"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditUnit} layout="vertical">
          <Form.Item
            name="unit"
            label="Unit"
            rules={[{ required: true, message: 'Please enter a unit' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Unit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddUnit;