import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Table,
  Space,
  message,
  Typography,
  Select,
  Form,
  Descriptions,
} from "antd";
import {
  ExclamationCircleOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { BiEdit } from "react-icons/bi";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileSpreadsheet } from "lucide-react";

const API_BASE_URL = "https://parnetslink.xyz/api";
const { Title } = Typography;
const { confirm } = Modal;

const Subscriptions = () => {
  const token = sessionStorage.getItem("token");
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingSubscription, setEditingSubscription] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchSubscriptions();
    fetchDrivers();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchText, dateRange, subscriptions]);

  const fetchSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/user/getallSubscriptions`,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (response.status === 200) {
        const transformedSubscriptions = transformData(response.data.success);
        setSubscriptions(transformedSubscriptions);
        setFilteredSubscriptions(transformedSubscriptions);
      }
    } catch (error) {
      message.error("Failed to fetch subscriptions");
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/getAlldriver`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      if (response.status === 200) {
        setDriver(response.data.success);
      }
    } catch (error) {
      message.error("Failed to fetch drivers");
      console.error("Error fetching drivers:", error);
    }
  };

  const handleAssignDeliveryBoy = async (subscriptionId, deliveryBoyId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/assignDeliveryBoy1`,
        {
          orderId: subscriptionId,
          deliveryBoyId,
        },{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (response.status === 200) {
        toast.success("Delivery boy assigned successfully");
        fetchSubscriptions();
      }
    } catch (error) {
      toast.error("Failed to assign delivery boy");
      console.error("Error assigning delivery boy:", error);
    }
  };

  const handleEditSubscription = (subscription) => {
    setEditingSubscription(subscription);
    form.setFieldsValue({
      frequency: subscription.frequency,
      startDate: moment(subscription.startDate),
      endDate:
        subscription.endDate !== "N/A"
          ? moment(subscription.endDate)
          : undefined,
      quantity: subscription.productDetails.quantity,
    });
    setIsModalVisible(true);
  };

  const handleSaveEdit = async () => {
    try {
      const values = await form.validateFields();
      const { subscriptionId } = editingSubscription;

      const response = await axios.post(
        `${API_BASE_URL}/user/updateSubscription/${subscriptionId}`,
        values,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );

      if (response.status === 200) {
        toast.success("Subscription updated successfully");
        setIsModalVisible(false);
        form.resetFields();
        fetchSubscriptions();
      }
    } catch (error) {
      toast.error("Failed to update subscription");
      console.error("Error updating subscription:", error);
    }
  };

  const showDeleteConfirm = (subscriptionId) => {
    confirm({
      title: "Are you sure you want to delete this subscription?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteSubscription(subscriptionId);
      },
    });
  };

  const handleDeleteSubscription = async (subscriptionId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/user/deleteSubscription/${subscriptionId}`,{headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (response.status === 200) {
        toast.success("Subscription deleted successfully");
        fetchSubscriptions();
      }
    } catch (error) {
      toast.error("Failed to delete subscription");
      console.error("Error deleting subscription:", error);
    }
  };

  const transformData = (data) => {
    return data.map((subscription, index) => ({
      key: index + 1,
      Sno: index + 1,
      subscriptionId: subscription._id,
      customerName:
        subscription.shippingAddress?.fullName ||
        subscription.shippingAddress?.name,
      searchableContent: `
        ${subscription._id}
        ${
          subscription.shippingAddress?.fullName ||
          subscription.shippingAddress?.name
        }
        ${
          subscription.shippingAddress?.street ||
          subscription.shippingAddress?.address1
        }
        ${
          subscription.shippingAddress?.city ||
          subscription.shippingAddress?.address2
        }
        ${subscription.shippingAddress?.state}
        ${subscription.shippingAddress?.pincode}
        ${subscription.shippingAddress?.country}
        ${
          subscription.shippingAddress?.number ||
          subscription.shippingAddress?.mobile
        }
        ${subscription.shippingAddress?.email}
        ${subscription.productId?.productname}
        ${subscription.quantity}
        ${subscription.selectedWeight?.price}
        ${subscription.productValue}
        ${subscription.frequency}
        ${subscription.status}
      `.toLowerCase(),
      address: (
        <div>
          <p>
            {subscription.shippingAddress?.fullName ||
              subscription.shippingAddress?.name}
          </p>
          <p>
            {subscription.shippingAddress?.street ||
              subscription.shippingAddress?.address1}
          </p>
          <p>
            {subscription.shippingAddress?.city ||
              subscription.shippingAddress?.address2}
            , {subscription.shippingAddress?.state}{" "}
            {subscription.shippingAddress?.pincode}
          </p>
          <p>{subscription.shippingAddress?.country}</p>
          <p>
            Phone:{" "}
            {subscription.shippingAddress?.number ||
              subscription.shippingAddress?.mobile}
          </p>
          <p>Email: {subscription.shippingAddress?.email}</p>
        </div>
      ),
      productDetails: {
        name: subscription.productId?.productname,
        quantity: subscription.quantity,
        price: subscription.selectedWeight?.price,
      },
      productname: subscription.productId?.productname,
      amount: subscription.productValue,
      discount: subscription.discount,
      startDate: new Date(subscription.startDate).toLocaleString(),
      endDate: subscription.endDate
        ? new Date(subscription.endDate).toLocaleString()
        : "N/A",
      frequency: subscription.frequency,
      totalOrders: subscription.totalOrders * (subscription.quantity || 1),
      status: subscription.status,
      remarks: subscription.remarks,
      actions: (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => showSubscriptionDetails(subscription)}
          >
            View Details
          </Button>
          <BiEdit
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => handleEditSubscription(subscription)}
          />
          {subscription.deliveryBoyId === null ? (
            <Select
              style={{ width: 150 }}
              placeholder="Select Delivery Boy"
              onChange={(value) =>
                handleAssignDeliveryBoy(subscription._id, value)
              }
            >
              {driver.map((boy) => (
                <Select.Option key={boy._id} value={boy._id}>
                  {boy?.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <span>{subscription.deliveryBoyId?.name}</span>
          )}
        </Space>
      ),
    }));
  };

  const applyFilters = () => {
    let filtered = [...subscriptions];

    if (searchText) {
      const searchLower = searchText.toLowerCase();
      filtered = filtered.filter((subscription) =>
        subscription.searchableContent.includes(searchLower)
      );
    }

    if (dateRange?.[0] && dateRange?.[1]) {
      const startDate = dateRange[0].startOf("day").valueOf();
      const endDate = dateRange[1].endOf("day").valueOf();

      filtered = filtered.filter((subscription) => {
        const subscriptionDate = new Date(subscription.startDate).valueOf();
        return subscriptionDate >= startDate && subscriptionDate <= endDate;
      });
    }

    if (
      filtered.length === 0 &&
      (searchText || (dateRange?.[0] && dateRange?.[1]))
    ) {
      message.warning("No subscriptions found with the applied filters.");
    }

    setFilteredSubscriptions(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const exportToExcel = () => {
    const dataToExport = filteredSubscriptions.map(
      ({ searchableContent, productDetails, address, actions, ...rest }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    XLSX.writeFile(workbook, "subscriptions.xlsx");
  };

  const showSubscriptionDetails = (subscription) => {
    setSelectedSubscription(subscription);
    setIsDetailsModalVisible(true);
  };

  const columns = [
    { title: "SNo", dataIndex: "Sno", width: 70 },
    { title: "Subscription ID", dataIndex: "subscriptionId", width: 150 },
    { title: "Customer Name", dataIndex: "customerName", width: 150 },
    { title: "Total Amount", dataIndex: "amount", width: 150 },
    { title: "Start Date", dataIndex: "startDate", width: 150 },
    { title: "Status", dataIndex: "status", width: 150 },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 300,
    },
  ];

  const renderDetailsModal = () => (
    <Modal
      title="Subscription Details"
      visible={isDetailsModalVisible}
      onCancel={() => setIsDetailsModalVisible(false)}
      footer={null}
      width={800}
    >
      <div>
        <Descriptions bordered column={2} layout="horizontal">
          <Descriptions.Item label="Product Name">
            {selectedSubscription?.productId?.productname}
          </Descriptions.Item>
          <Descriptions.Item label="Frequency">
            {selectedSubscription?.frequency}
          </Descriptions.Item>

          <Descriptions.Item label="Start Date">
            {new Date(selectedSubscription?.startDate).toLocaleDateString()}
          </Descriptions.Item>
          <Descriptions.Item label="End Date">
            {selectedSubscription?.endDate
              ? new Date(selectedSubscription?.endDate).toLocaleDateString()
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Total Orders">
            {selectedSubscription?.totalOrders}
          </Descriptions.Item>
          <Descriptions.Item label="Total Quantity">
            {selectedSubscription?.totalQuantity}
          </Descriptions.Item>
          <Descriptions.Item label="Product Value">
            ₹{selectedSubscription?.selectedWeight.price?.toFixed(2)}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Discount">
              ₹{subscription.discount?.toFixed(2)}
            </Descriptions.Item> */}
          {/* <Descriptions.Item label="Subtotal">
              ₹{subscription.subtotal?.toFixed(2)}
            </Descriptions.Item> */}
          <Descriptions.Item label="Delivery Charges">
            ₹{selectedSubscription?.deliveryCharges?.toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            ₹{selectedSubscription?.productValue?.toFixed(2)}
          </Descriptions.Item>
          {selectedSubscription?.frequency !== "Customize" ? (
            <Descriptions.Item label="Quantity">
              {selectedSubscription?.quantity}
            </Descriptions.Item>
          ) : (
            ""
          )}
        </Descriptions>

        <h4>Shipping Address:</h4>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Full Name">
            {selectedSubscription?.shippingAddress?.fullName
              ? selectedSubscription?.shippingAddress?.fullName
              : selectedSubscription?.shippingAddress?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {selectedSubscription?.shippingAddress?.street
              ? selectedSubscription?.shippingAddress?.street
              : selectedSubscription?.shippingAddress?.address1}
            ,{" "}
            {selectedSubscription?.shippingAddress?.city
              ? selectedSubscription?.shippingAddress?.city
              : selectedSubscription?.shippingAddress?.address2}{" "}
            {selectedSubscription?.shippingAddress?.state ? "," : ""}{" "}
            {selectedSubscription?.shippingAddress?.state},{" "}
            {selectedSubscription?.shippingAddress?.pincode}
          </Descriptions.Item>
          <Descriptions.Item label="Phone">
            {selectedSubscription?.shippingAddress?.number
              ? selectedSubscription?.shippingAddress?.number
              : selectedSubscription?.shippingAddress?.mobile}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {selectedSubscription?.shippingAddress?.email}
          </Descriptions.Item>
        </Descriptions>

        <h4>Selected Weight:</h4>
        <Descriptions bordered column={1}>
          <Descriptions.Item label={`Weight`}>
            {selectedSubscription?.selectedWeight?.value}{" "}
            {selectedSubscription?.selectedWeight?.units}
          </Descriptions.Item>
        </Descriptions>

        {selectedSubscription?.dayQuantities && (
          <>
            <h4>Day Quantities:</h4>
            <Descriptions bordered column={2}>
              {Object.entries(selectedSubscription?.dayQuantities).map(
                ([day, quantity]) => (
                  <Descriptions.Item key={day} label={day}>
                    {quantity}
                  </Descriptions.Item>
                )
              )}
            </Descriptions>
          </>
        )}
        {selectedSubscription?.pauseDates &&
          selectedSubscription?.pauseDates.length > 0 && (
            <>
              <h4>Pause Dates:</h4>
              <Descriptions bordered column={1}>
                {selectedSubscription?.pauseDates.map((date, index) => (
                  <Descriptions.Item
                    key={index}
                    label={`Pause Date ${index + 1}`}
                  >
                    {new Date(date).toLocaleDateString()}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </>
          )}
        {selectedSubscription?.remarks && (
          <div>
            <h4>Remarks:</h4>
            <p>{selectedSubscription?.remarks}</p>
          </div>
        )}
      </div>
      {/* <Descriptions bordered column={2}>
        <Descriptions.Item label="Start Date">
          {selectedSubscription?.startDate}
        </Descriptions.Item>
        <Descriptions.Item label="End Date">
          {selectedSubscription?.endDate}
        </Descriptions.Item>
        <Descriptions.Item label="Total Amount">
          ₹{selectedSubscription?.amount?.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Discount">
          ₹{selectedSubscription?.discount?.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Frequency">
          {selectedSubscription?.frequency}
        </Descriptions.Item>
        <Descriptions.Item label="Total Orders">
          {selectedSubscription?.totalOrders}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {selectedSubscription?.status}
        </Descriptions.Item>
      </Descriptions>

      <h4>Product Details:</h4>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Product">
          {selectedSubscription?.productDetails?.name} - 
          {selectedSubscription?.productDetails?.quantity} x 
          ₹{selectedSubscription?.productDetails?.price}
        </Descriptions.Item>
      </Descriptions>

      <h4>Shipping Address:</h4>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Address">
          {selectedSubscription?.address}
        </Descriptions.Item>
      </Descriptions> */}
    </Modal>
  );

  return (
    <div style={{ padding: "20px" }}>
      <ToastContainer position="top-right" autoClose={3000} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={3}>Subscriptions</Title>
        <Space>
          <Input
            placeholder="Search subscriptions"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
          />
          <DatePicker.RangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
          />
          <Button
            type="primary"
            onClick={exportToExcel}
            icon={<FileSpreadsheet size={16} />}
          >
            Export to Excel
          </Button>
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={filteredSubscriptions}
        scroll={{ x: "max-content" }}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
        loading={loading}
      />

      {renderDetailsModal()}

      <Modal
        title="Edit Subscription"
        visible={isModalVisible}
        onOk={handleSaveEdit}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
      >
        <Form form={form}>
          <Form.Item
            label="Frequency"
            name="frequency"
            rules={[{ required: true, message: "Please select frequency!" }]}
          >
            <Select>
              <Select.Option value="Everyday">Everyday</Select.Option>
              <Select.Option value="On Interval">On Interval</Select.Option>
              <Select.Option value="Customize">Customize</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: true, message: "Please select start date!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item label="End Date" name="endDate">
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Quantity"
            name="quantity"
            rules={[{ required: true, message: "Please input quantity!" }]}
          >
            <Input type="number" min={1} />
          </Form.Item>
          {form.getFieldValue("frequency") === "On Interval" && (
            <Form.Item
              label="Interval (days)"
              name="nthInterval"
              rules={[{ required: true, message: "Please input interval!" }]}
            >
              <Input type="number" min={1} />
            </Form.Item>
          )}
          {form.getFieldValue("frequency") === "Customize" && (
            <Form.Item label="Day Quantities">
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                <Form.Item key={day} name={["dayQuantities", day]} noStyle>
                  <Input
                    type="number"
                    min={0}
                    addonBefore={day}
                    style={{
                      width: "150px",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </Form.Item>
              ))}
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default Subscriptions;
