
import React, { useEffect, useState } from "react";
import SubscribeSummary from "./Subscribe/SubscribeSummary";
import SubscribeItem from "./Subscribe/SubscribeItem";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaShippingFast } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { Minus, Plus, Truck } from "lucide-react";
const DaySquare = ({ day, dayQuantities, selectedDay, setSelectedDay }) => {
  return (
    <div 
      className={`flex flex-col items-center p-2 rounded-lg cursor-pointer transition-colors
        ${selectedDay === day 
          ? 'bg-[#5c870c]/20 border-2 border-[#5c870c]' 
          : 'bg-gray-50 border border-gray-200 hover:bg-[#5c870c]/10'
        }`}
      onClick={() => setSelectedDay(day)}
    >
      <div className="text-sm font-medium">{day}</div>
      <div className="text-lg font-bold text-[#5c870c]">{dayQuantities[day]}</div>
    </div>
  );
};

const SubscribeCart = () => {
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
const token = sessionStorage.getItem("token");
  const [newAddress, setNewAddress] = useState({
    fullName: '',
    street: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    number: '',
    email: '',
  });
  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
    if (name === "fullName") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid name: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid name: Minimum 3 characters");
        return;
      }
    }
    if (name === "city") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid city: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid city: Minimum 3 characters");
        return;
      }
    }
    if (name === "street" && value.length < 3) {
      console.log("Invalid street: Minimum 3 characters");
      return;
    }
    if (name === "state") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid state: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid state: Minimum 3 characters");
        return;
      }
    }
    if (name === "country") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid country: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid country: Minimum 3 characters");
        return;
      }
    }
    if (name === "pincode" && (!/^\d*$/.test(value) || value.length > 6)) {
      console.log("Invalid pincode: Must be a number with max 6 digits");
      return;
    }
    if (name === "mobile" && (!/^\d*$/.test(value) || value.length > 10)) {
      console.log("Invalid mobile: Must be a number with max 10 digits");
      return;
    }
  };
  const [paymentMethod, setPaymentMethod] = useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [name, setname] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [mobile, setmobile] = useState("");
  const [pincode, setpincode] = useState("");
  const [Email, setEmail] = useState("");
  const [Area, setArea] = useState("");

  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const addAddress = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://parnetslink.xyz/api/user/addaddress",
        {
          userId: user._id,
          ...newAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        toast.info("Address added successfully");
        getAddress(); // Fetch the updated addresses list
        setNewAddress({
          fullName: "",
          street: "",
          city: "",
          state: "",
          country: "",
          pincode: "",
          number: "",
          email: "",
        });
      }
    } catch (error) {
      toast.info(error.response?.data?.error || "Error adding address");
    }
  };

  let deleteAddress = async (id) => {
    try {
      let res = await axios.delete(
        "https://parnetslink.xyz/api/user/deleteaddress/" + id,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (res.status === 200) {
        toast.info("Successfully deleted"
        );
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [address, setaddress] = useState([]);
  console.log(address);
  let getAddress = async () => {
    try {
      const userId = user?._id;
      if (!userId) {
        console.error("User ID is undefined");
        return;
      }

      const res = await axios.get(
        `https://parnetslink.xyz/api/user/address/${userId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        setaddress(res.data.success);
        console.log(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };
  useEffect(() => {
    if (user) {
      getAddress();
    }
  }, []);
  const [product, setProduct] = useState([]);

  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  let location = useLocation();
  const { selectedWeight } = location.state;
  console.log(selectedWeight);
  const params = useParams();

  const getProducts = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/Getproductlist",{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setProduct(
          res.data.ProductList.filter((item) => item.status === "Active")
        );
      } else {
        console.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const item = product?.find((items) => items?._id === params.id);
  const [frequency, setFrequency] = useState("Everyday");
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); 
  const minDate = tomorrow.toISOString().split("T")[0]; 
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [nthInterval, setNthInterval] = useState("");
  const [nthIntervalActive, setNthIntervalActive] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState(null);
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleShipToDifferentAddressChange = () => {
    setShipToDifferentAddress(!shipToDifferentAddress);
  };

  const handleAddressChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedAddress(JSON.parse(selectedValue)); 
  };

  const pricePerItem = selectedWeight?.price;

  const [productValue, setProductValue] = useState(pricePerItem * quantity);

  const [discount, setDiscount] = useState(
    user.club ? item?.clubdiscount : item?.discount
  );
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  const [subtotal, setSubtotal] = useState(productValue - discount);
  const [total, setTotal] = useState(subtotal + deliveryCharges);

  
  const [dayQuantities, setDayQuantities] = useState({
  Sunday: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
  });


  const [selectedDay, setSelectedDay] = useState("");

 
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const daysDiff = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;

      let orders = 0;
      let qty = 0;

      if (frequency === "Everyday") {
        orders = daysDiff;
        qty = daysDiff * quantity;
      } else if (frequency === "On Interval" && nthInterval) {
        orders = Math.floor(daysDiff / nthInterval);
        qty = orders * quantity;
      } else if (frequency === "Customize") {
        orders = 0;
        qty = 0;
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
          const day = d.toLocaleString("en-us", { weekday: "long" });
          if (dayQuantities[day] > 0) {
            orders++;
            qty += dayQuantities[day];
          }
        }
      }

      setTotalOrders(orders);
      setTotalQuantity(qty);
      setIsDateFilterApplied(true);
      setSelectedOrders(null); 
    } else if (selectedOrders) {
      setTotalOrders(selectedOrders);
      setTotalQuantity(
        frequency === "Customize"
          ? Object.values(dayQuantities).reduce((a, b) => a + b, 0) *
              selectedOrders
          : selectedOrders * quantity
      );
      setIsDateFilterApplied(false); 
    }
  }, [
    startDate,
    endDate,
    frequency,
    nthInterval,
    dayQuantities,
    selectedOrders,
    quantity, 
  ]);
  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response = await axios.get(
          `https://parnetslink.xyz/api/user/getwallet/${user._id}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBalance(response.data.success.balance);
        console.log(response.data.success.balance);
        setTransactions(response.data.success.transactions);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };

    fetchWalletData();
  }, []);
  const roundToTwoDecimals = (num) =>
    Math.round((num + Number.EPSILON) * 100) / 100;
  useEffect(() => {
    const itemPrice = pricePerItem;
    const newProductValue = roundToTwoDecimals(itemPrice * totalQuantity);
    setProductValue(newProductValue);

    const discountPercentage = user.club
      ? item?.clubdiscount / 100
      : item?.discount / 100;
    const newDiscount = roundToTwoDecimals(
      newProductValue * discountPercentage
    );
    setDiscount(newDiscount);

    const newSubtotal = roundToTwoDecimals(newProductValue - newDiscount);
    setSubtotal(newSubtotal);

    const newTotal = roundToTwoDecimals(newSubtotal + deliveryCharges
    );
    setTotal(newTotal);
  }, [totalQuantity, pricePerItem, item, deliveryCharges, balance]);

  const handleFrequencyChange = (newFrequency) => {
    setFrequency(newFrequency);
    setNthIntervalActive(false);
    setSelectedOrders(null);

    if (newFrequency === "Everyday" || newFrequency === "On Interval") {
      setQuantity(1);
    } else if (newFrequency === "Customize") {
      setQuantity(0);
    }
  };

  const handleNthIntervalChange = (e) => {
    setNthInterval(Number(e.target.value));
  };

  const handleNthDayClick = () => {
    setNthIntervalActive(true);
  };

  const handleOrdersSelection = (orders) => {
    setSelectedOrders(orders);
  };

  const handleDayQuantityChange = (day, amount) => {
    setDayQuantities((prevQuantities) => ({
      ...prevQuantities,
      [day]: Math.max(0, prevQuantities[day] + amount),
    }));
  };

  const handleQuantityChange = (amount) => {
    if (frequency === "Customize" && selectedDay) {
      setDayQuantities((prevQuantities) => ({
        ...prevQuantities,
        [selectedDay]: Math.max(0, prevQuantities[selectedDay] + amount),
      }));
    } else {
      setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
    }
  };
  const calculateWalletCredit = (orderValue) => {
    let credit = 0;
    if (orderValue < 100) {
      credit += 1;
    } 

    if (orderValue >= 100) {
      credit += 2;
    }

    if (orderValue > 1000) {
      credit += 2;
    }

    if (orderValue > 2000) {
      credit += 5;
    }

    if (orderValue > 5000) {
      credit += 20;
    }

    if (orderValue > 10000) {
      credit += 100;
    }

    return credit;
  };
  const saveSubscription = async () => {
    if (!selectedAddress) {
      toast.info("Please select a delivery address."
      );
      return;
    }
    if (!startDate) {
      toast.info("Please select start date."
      );
      return;
    }
    if ((frequency === "Customize" || frequency === "On Interval") && (!endDate && selectedOrders === null)) {
      toast.info("Please select an end date.");
      return;
    }
    try {
      const response = await axios.post(
        "https://parnetslink.xyz/api/user/create",
        {
          userId: user?._id,
          productId: item?._id,
          frequency,
          quantity,
          startDate,
          endDate,
          totalOrders,
          totalQuantity,
          productValue,
          discount,
          subtotal,
          deliveryCharges,
          total,
          shippingAddress: selectedAddress,
          dayQuantities,
          nthInterval: frequency === "On Interval" ? nthInterval : undefined,
          selectedWeight: {
            value: selectedWeight?.value,
            units: selectedWeight?.units,
            price:selectedWeight?.price,
          },
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
      toast.info("Subscription added successfully."
      );
       window.location.assign("/");
      }
      // if (response.status === 201) {
      //   
      //   const debitResponse = await axios.post(
      //     "https://parnetslink.xyz/api/user/debit",
      //     {
      //       userId: user?._id,
      //       amount: subtotal, 
      //       detail: `Subscription for product ${item?.productname}`,
      //     }
      //   );

      //   if (debitResponse.status === 200) {
      //     
      //     const creditAmount = calculateWalletCredit(subtotal);

      //    
      //     const creditResponse = await axios.post(
      //       "https://parnetslink.xyz/api/user/credit",
      //       {
      //         userId: user?._id,
      //         amount: creditAmount,
      //         detail: `Credit for order value ${subtotal}`,
      //       }
      //     );

      //     if (creditResponse.status === 200) {
      //       
      //toast.info("Subscription created and wallet updated successfully."
     // );
      //       window.location.assign("/");
      //     } else {
     
     // toast.info("Subscription created and amount debited, but failed to credit wallet."
        // );
      //     }
      //   } else {
    
    //  toast.info("Subscription created, but failed to deduct amount from wallet."
        // );
      //   }
      // }
    } catch (error) {
      console.error("Error in subscription process:", error);
      toast.info("Failed to complete the subscription process. Please try again."
        );
    }
  };
  const getOrdinal = (n) => {
    if (n === 1) return 'st';
    if (n === 2) return 'nd';
    if (n === 3) return 'rd';
    return 'th';
  };
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <ToastContainer />
      <h2 className="text-2xl font-bold text-gray-900 mb-8">Your Subscribe Items</h2>
      
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main Content */}
        <div className="flex-grow">
          {/* Product Card */}
          <div className="bg-white rounded-lg shadow-md p-4 mb-8">
            <div className="flex flex-col md:flex-row gap-6">
              {/* Product Image */}
              <div className="w-full md:w-48 h-48 relative">
                <img
                  src={`https://parnetslink.xyz/product/${item?.Photos[0].file}`}
                  alt={item?.productname}
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>

              {/* Product Details */}
              <div className="flex-grow">
                <h3 className="text-xl font-bold text-[#5c870c] mb-2">{item?.productname}</h3>
                <h3 className="text-xl font-bold text-[#5c870c] mb-4">{selectedWeight?.value+" "+ selectedWeight.units} - ₹{selectedWeight.price}</h3>
                {/* Frequency Selection */}
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Frequency:</label>
                  <div className="flex flex-wrap gap-2">
                    {['Everyday', 'On Interval', 'Customize'].map(freq => (
                      <button
                        key={freq}
                        onClick={() => handleFrequencyChange(freq)}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                          ${frequency === freq 
                            ? 'bg-[#5c870c] text-white' 
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                      >
                        {freq}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Interval Options */}
                {frequency === 'On Interval' && (
                  <div className="mb-6">
                    <div className="flex flex-wrap gap-2">
                      {[2, 3, 4].map(n => (
                        <button
                          key={n}
                          onClick={() => setNthInterval(n)}
                          className={`px-4 py-2 rounded-lg text-sm font-medium
                            ${nthInterval === n 
                              ? 'bg-[#5c870c]/20 text-[#5c870c] border-2 border-[#5c870c]' 
                              : 'bg-gray-50 text-gray-700 border border-gray-200 hover:bg-gray-100'
                            }`}
                        >
                          Every {n}{getOrdinal(n)} Day
                        </button>
                      ))}
                      <button
                        onClick={handleNthDayClick}
                        className="px-4 py-2 rounded-lg text-sm font-medium bg-gray-50 text-gray-700 border border-gray-200 hover:bg-gray-100"
                      >
                        Custom Interval
                      </button>
                    </div>
                    {nthIntervalActive && (
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Enter interval (days):
                        </label>
                        <input
                          type="number"
                          value={nthInterval}
                          onChange={handleNthIntervalChange}
                          className="w-32 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#5c870c]"
                          min="1"
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* Customize Days Grid */}
                {frequency === 'Customize' && (
                  <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-7 gap-2 mb-6">
                    {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => (
                      <DaySquare
                        key={day}
                        day={day}
                        dayQuantities={dayQuantities}
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                      />
                    ))}
                  </div>
                )}

                {/* Quantity Control */}
                <div className="flex items-center gap-4 mb-6">
                  <button
                    onClick={() => handleQuantityChange(-1)}
                    className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                  >
                    <Minus size={20} />
                  </button>
                  <span className="text-xl font-bold">
                    {frequency === 'Customize' 
                      ? selectedDay ? dayQuantities[selectedDay] : 0 
                      : quantity
                    }
                  </span>
                  <button
                    onClick={() => handleQuantityChange(1)}
                    className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                  >
                    <Plus size={20} />
                  </button>
                </div>

                {/* Date Selection */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      min={minDate}
                      className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#5c870c]"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">End Date (Optional)</label>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      min={minDate}
                      className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#5c870c]"
                    />
                  </div>
                </div>

                {/* Quick Order Selection */}
                {frequency !== 'Customize' && !isDateFilterApplied && (
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Quick Order Selection
                    </label>
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {[7, 30, 60, 90].map(orderCount => (
                        <button
                          key={orderCount}
                          onClick={() => handleOrdersSelection(orderCount)}
                          className={`py-3 px-4 rounded-lg text-sm font-medium transition-colors
                            ${selectedOrders === orderCount
                              ? 'bg-[#5c870c] text-white'
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                        >
                          {orderCount} Orders
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Address Selection */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-bold flex items-center gap-2 mb-6">
              <Truck className="text-[#5c870c]" />
              Delivery Address
            </h3>
            <select
            className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
            value={JSON.stringify(selectedAddress)}
            onChange={handleAddressChange}
          >
             <option value="">Select an address</option>
           
              <option key={user._id} value={JSON.stringify(user)}>
                {user.name}, {user.address1}, {user.address2}
              </option>
            {address.map((address) => (
              <option key={address._id} value={JSON.stringify(address)}>
                {address.fullName}, {address.street}, {address.city}
              </option>
            ))}
          </select>

          {selectedAddress && (
            <div className="bg-gray-100 p-4 rounded-md mb-4">
              <h3 className="font-semibold mb-2 text-gray-700">Selected Address</h3>
              <p>{selectedAddress.name ? selectedAddress.name : selectedAddress.fullName}</p>
              <p>{selectedAddress.address1 ? selectedAddress.address1 : selectedAddress.street}</p>
              <p>{selectedAddress.address2 ? selectedAddress.address2 :selectedAddress.city}, {selectedAddress.state} {selectedAddress.pincode}</p>
              <p>{selectedAddress.country}</p>
              <p>Phone: {selectedAddress.mobile ? selectedAddress.mobile : selectedAddress.number}</p>
              <p>Email: {selectedAddress.email}</p>
            </div>
          )}

            <div className="mb-4">
            <label className="flex items-center text-gray-700">
              <input
                type="checkbox"
                checked={shipToDifferentAddress}
                onChange={() => setShipToDifferentAddress(!shipToDifferentAddress)}
                className="mr-2 form-checkbox h-5 w-5 text-[#5c870c]"
              />
              Ship to a different address?
            </label>
          </div>

          {shipToDifferentAddress && (
            <form onSubmit={addAddress} className="space-y-4">
              <input
                type="text"
                name="fullName"
                value={newAddress.fullName}
                onChange={handleNewAddressChange}
                placeholder="Full Name"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <input
                type="text"
                name="street"
                value={newAddress.street}
                onChange={handleNewAddressChange}
                placeholder="Street Address"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="city"
                  value={newAddress.city}
                  onChange={handleNewAddressChange}
                  placeholder="City"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
                <input
                  type="text"
                  name="state"
                  value={newAddress.state}
                  onChange={handleNewAddressChange}
                  placeholder="State"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="country"
                  value={newAddress.country}
                  onChange={handleNewAddressChange}
                  placeholder="Country"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
                <input
                  type="text"
                  name="pincode"
                  value={newAddress.pincode}
                  onChange={handleNewAddressChange}
                  placeholder="Pincode"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
              </div>
              <input
                type="tel"
                name="number"
                value={newAddress.number}
                onChange={handleNewAddressChange}
                placeholder="Phone Number"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <input
                type="email"
                name="email"
                value={newAddress.email}
                onChange={handleNewAddressChange}
                placeholder="Email"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <button
                type="submit"
                className="w-full bg-[#5c870c] text-white p-2 rounded-md hover:bg-[#5c870c] transition duration-300"
              >
                Add New Address
              </button>
            </form>
          )}
          </div>
        </div>

        {/* Order Summary Sidebar */}
        <div className="lg:w-96">
          <div className="bg-white rounded-lg shadow-md p-6 sticky top-4">
            <h3 className="text-xl font-bold mb-6">Subscribe Summary</h3>
            
            <div className="space-y-4 mb-6">
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Total Orders</span>
                <span className="font-bold">{totalOrders}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Total Quantity</span>
                <span className="font-bold">{totalQuantity}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Product Value</span>
                <span className="font-bold">₹{productValue?.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center text-[#5c870c]">
                <span>Wallet Balance</span>
                <span className="font-bold">₹{balance?.toFixed(2)}</span>
              </div>
            </div>

            <button
              onClick={saveSubscription}
              className="w-full bg-[#5c870c] text-white py-3 px-4 rounded-lg font-medium hover:bg-[#5c870c] transition-colors"
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeCart;
