import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  AiOutlineDashboard,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBgColors,
} from "react-icons/ai";
import { RiCouponLine } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { CiDeliveryTruck } from "react-icons/ci";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { IoIosNotifications, IoMdLogIn } from "react-icons/io";
import { FaClipboardList, FaBloggerB, FaPowerOff, FaImage, FaTags, FaUnity, FaTextHeight } from "react-icons/fa";
import { SiBrandfolder } from "react-icons/si";
import { BiCategoryAlt, BiSolidCategoryAlt } from "react-icons/bi";
import { GrDocumentUser } from "react-icons/gr";

import { FcFeedback, FcLeave } from "react-icons/fc";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { MdAddShoppingCart, MdOutlineTitle } from "react-icons/md";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const logOut = () => {
    window.location.assign("/admin");
    window.sessionStorage.removeItem("admin");
    toast.info("Successfully logout");
  };
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  return (
    <Layout hasSider /* onContextMenu={(e) => e.preventDefault()} */>
       <ToastContainer />
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo">AO</span>
            
            <img
              src="../logo.svg"
              style={{ marginTop: "-12px", width: "150px", height: "70px" }}
              className="lg-logo"
            />
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key == "signout") {
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "",
              icon: <AiOutlineDashboard className="fs-4" />,
              label: "Dashboard",
            },
            {
              key: "text",
              icon: <FaTextHeight   className="fs-4" />,
              label: "Text",
            },
            {
              key: "banner",
              icon: <FaImage  className="fs-4" />,
              label: "Banner",
            },
            {
              key: "category",
              icon: <BiSolidCategoryAlt  className="fs-4" />,
              label: "Category",
            },
            {
              key: "subcategory",
              icon: <BiSolidCategoryAlt  className="fs-4" />,
              label: "Sub-Category",
            },
            {
              key: "clubcustomers",
              icon: <AiOutlineUser className="fs-4" />,
              label: "Club Members",
            },
            {
              key: "customers",
              icon: <AiOutlineUser className="fs-4" />,
              label: "Customers",
            },
            {
              key: "Product",
              icon: <AiOutlineShoppingCart className="fs-4" />,
              label: "Product",
              children: [
                {
                  key: "title",
                  icon: <MdOutlineTitle  className="fs-4" />,
                  label: "Title",
                },
                {
                  key: "unit",
                  icon: <FaUnity className="fs-4" />,
                  label: "Units",
                },
                {
                  key: "tag",
                  icon: <FaTags  className="fs-4" />,
                  label: "Tags",
                },
                {
                  key: "disbanner",
                  icon: <FaImage  className="fs-4" />,
                  label: "Discount Banner",
                },
                {
                  key: "product",
                  icon: <AiOutlineShoppingCart className="fs-4" />,
                  label: "Add Groceries",
                },
                {
                  key: "fruits",
                  icon: <AiOutlineShoppingCart className="fs-4" />,
                  label: "Add Fruits and Vegetables",
                },
                {
                  key: "list-product",
                  icon: <AiOutlineShoppingCart className="fs-4" />,
                  label: "Groceries List",
                },
                {
                  key: "list-Fruits",
                  icon: <AiOutlineShoppingCart className="fs-4" />,
                  label: "Fruits and Vegetables List",
                },
                {
                  key: "DeliveryChargesList",
                  icon: <TbTruckDelivery className="fs-4" />,
                  label: "Add Delivery Charges",
                },
              ],
            },
            {
              key: "Orders",
              icon: <FaClipboardList className="fs-4" />,
              label: "Orders",
              children: [
            {
              key: "orders",
              icon: <FaClipboardList className="fs-4" />,
              label: "Orders",
            },
            {
              key: "orders1",
              icon: <FaClipboardList className="fs-4" />,
              label: "Scheduled Orders",
            },
            {
              key: "subscription",
              icon: <FaClipboardList className="fs-4" />,
              label: "Subscription Order",
            },
          
          ]
        },
        {
          key: "Driver",
          icon: <CiDeliveryTruck className="fs-4" />,
          label: "Driver",
          children: [
            {
              key: "Drivers",
              icon: <CiDeliveryTruck   className="fs-4" />,
              label: "Drivers",
            },
            {
              key: "Checkins",
              icon: <IoMdLogIn  className="fs-4" />,
              label: "Checkins",
            },
        {
          key: "leaves",
          icon: <FcLeave className="fs-4" />,
          label: "Leaves",
        },
        
      
      ]
    },
            {
              key: "marketing",
              icon: <RiCouponLine className="fs-4" />,
              label: "Marketing",
              children: [
                // {
                //   key: "coupon",
                //   icon: <ImBlog className="fs-4" />,
                //   label: "Add Coupon",
                // },
                {
                  key: "coupon-list",
                  icon: <RiCouponLine className="fs-4" />,
                  label: "Coupon",
                },
              ],
            },
            // {
            //   key: "blogs",
            //   icon: <FaBloggerB className="fs-4" />,
            //   label: "Blogs",
            //   children: [
            //     {
            //       key: "blog",
            //       icon: <ImBlog className="fs-4" />,
            //       label: "Add Blog",
            //     },
            //     {
            //       key: "blog-list",
            //       icon: <FaBloggerB className="fs-4" />,
            //       label: "Blog List",
            //     },
            //     {
            //       key: "blog-category",
            //       icon: <ImBlog className="fs-4" />,
            //       label: "Add Blog Category",
            //     },
            //     {
            //       key: "blog-category-list",
            //       icon: <FaBloggerB className="fs-4" />,
            //       label: "Blog Category List",
            //     },
            //   ],
            // },
            {
              key: "enquiries",
              icon: <FcFeedback  className="fs-4" />,
              label: "Feedback",
            },
            {
              key: "testimonial",
              icon: <GrDocumentUser  className="fs-4" />,
              label: "Testimonial",
            },
            {
              key: "clubbanner",
              icon: <FaImage  className="fs-4" />,
              label: "Club Banner",
            },

            {
              key: "AbandonedCartList",
              icon: <MdAddShoppingCart className="fs-4" />,
              label: "Abandoned Cart List",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <div className="d-flex gap-4 align-items-center">
            <div className="d-flex gap-3 align-items-center dropdown">
              <div>
                <img width={32} height={32} src="../img/admin.png" alt="" />
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <h5 className="mb-0">{admin.name}</h5>
                <p className="mb-0">{admin.email}</p>
              </div>

                <FaPowerOff
                 onClick={logOut}
                  style={{
                    height: "auto",
                    lineHeight: "20px",
                    fontSize:"20px",
                    color: "red",
                    cursor: "pointer",
                  }}
                />
            
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
