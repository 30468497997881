import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Modal, message, Typography, Space } from 'antd';
import { SearchOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons';
import { FileSpreadsheet, Trash2 } from 'lucide-react';
import axios from 'axios';
import moment from 'moment';
import * as XLSX from 'xlsx';

const { Title } = Typography;
const API_BASE_URL = 'https://parnetslink.xyz/api/admin';

const AbandonedCartList = () => {
  const [abandonedCarts, setAbandonedCarts] = useState([]);
  const [filteredCarts, setFilteredCarts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedCartId, setSelectedCartId] = useState(null);
const token = sessionStorage.getItem("token");

  useEffect(() => {
    fetchAbandonedCarts();
  }, []);

  const fetchAbandonedCarts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getAbandoned`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const transformedData = transformData(response.data?.Abandoned);
      setAbandonedCarts(transformedData);
      setFilteredCarts(transformedData);
    } catch (error) {
      message.error('Failed to fetch abandoned carts');
    }
  };

  const transformData = (data) => {
    return data?.map((cart, index) => ({
      key: index + 1,
      user: cart.user?.name || 'Anonymous',
      cartItems: cart.cart.map(item => `${item.productname} (x${item.quantity})`).join(', '),
      timestamp: moment(cart.timestamp).format('DD/MM/YYYY, HH:mm'),
      _id: cart._id,
    }));
  };

  const handleDeleteCart = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/deleteAbandoned/${selectedCartId}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Abandoned cart deleted successfully');
      setIsDeleteModalVisible(false);
      fetchAbandonedCarts();
    } catch (error) {
      message.error('Failed to delete abandoned cart');
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = abandonedCarts.filter((record) =>
      Object.values(record).some(
        (val) => val && val.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredCarts(filtered);
  };

  const exportToExcel = () => {
    const dataToExport = filteredCarts.map(({ key, _id, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Abandoned Carts');
    XLSX.writeFile(workbook, 'Abandoned_Carts.xlsx');
  };

  const columns = [
    { title: 'SNo', dataIndex: 'key', key: 'key' },
    { title: 'User', dataIndex: 'user', key: 'user' },
    { title: 'Cart Items', dataIndex: 'cartItems', key: 'cartItems' },
    { title: 'Timestamp', dataIndex: 'timestamp', key: 'timestamp' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type="text"
          icon={<Trash2 color="red" />}
          onClick={() => {
            setSelectedCartId(record._id);
            setIsDeleteModalVisible(true);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex',alignItems:'center', justifyContent: 'space-between', marginBottom: 16 }}>
      <Title level={3}>Abandoned Carts</Title>
      <Space>
       
        <Input
        placeholder="Search"
        prefix={<SearchOutlined />}
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
      />
        <Button type="primary"   icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>
          Export to Excel
        </Button>
        </Space>
      </div>

      

      <Table
        columns={columns}
        dataSource={filteredCarts}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        scroll={{ x: 'max-content' }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: 'linear-gradient(#5C870C,#5C870C,#5C870C)',
                  color: 'white',
                }}
              />
            ),
          },
        }}
      />

      <Modal
        title="Delete Abandoned Cart"
        visible={isDeleteModalVisible}
        onOk={handleDeleteCart}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete this abandoned cart?</p>
      </Modal>
    </div>
  );
};

export default AbandonedCartList;