import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const Back = ({title}) => {
    const location = useLocation();
    return (
        <>
            <section className="back">
                <div className="overlay-back"></div>
                <h1>{title}</h1>
                <h2><Link to='/'>Home </Link>/ {location.pathname.split('/')[1]}</h2>
            </section>
            <div style={{margin:"3%"}}></div>
        </>
    )
}

export default Back
