import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  Typography,
  Space,
} from "antd";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";
import { FileDown, FileSpreadsheet, Search } from "lucide-react";

const { Title } = Typography;

const Leaves = () => {
  const token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getLeaves();
  }, []);

  const getLeaves = async () => {
    try {
      const res = await axios.get("https://parnetslink.xyz/api/user/getAllleaves",{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      if (res.status === 200) {
        const transformedData = transformData(res.data.success);
        setData(transformedData);
        setFilteredData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
  };

  const transformData = (data) => {
    return data.map((item, i) => ({
      key: i + 1,
      _id: item._id,
      userName: item.userName,
      fromDate: moment(item.FromDate).format("YYYY-MM-DD"),
      toDate: moment(item.ToDate).format("YYYY-MM-DD"),
      leaveType: item.LeaveType,
      status: item.status,
    }));
  };

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filtered = data.filter((item) =>
      Object.values(item).some(
        (val) => typeof val === "string" && val.toLowerCase().includes(searchText)
      )
    );
    setFilteredData(filtered);
  };

  const handleApproveLeave = async (leaveId) => {
    try {
      await axios.post("https://parnetslink.xyz/api/user/changeleavesStatus", {
        leavesId: leaveId,
        status: "Approved",
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      getLeaves();
    } catch (error) {
      console.error("Error approving leave:", error);
    }
  };

  const handleRejectLeave = async (leaveId) => {
    try {
      await axios.post("https://parnetslink.xyz/api/user/changeleavesStatus", {
        leavesId: leaveId,
        status: "Rejected",
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      getLeaves();
    } catch (error) {
      console.error("Error rejecting leave:", error);
    }
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leaves");
    XLSX.writeFile(workbook, "LeavesData.xlsx");
  };

  const columns = [
    {
      title: "Driver Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "From Date",
      dataIndex: "fromDate",
      key: "fromDate",
    },
    {
      title: "To Date",
      dataIndex: "toDate",
      key: "toDate",
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      render: (record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleApproveLeave(record._id)}
            style={{ marginRight: "10px", backgroundColor: "green" }}
          >
            Approve
          </Button>
          <Button
            type="danger"
            onClick={() => handleRejectLeave(record._id)}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Reject
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={3}>Leaves</Title>
        <Space>
        <Input
          placeholder="Search"
          onChange={handleSearch}
          prefix={<Search size={16} />}
        />
        <Button
          type="primary"
          onClick={handleExportToExcel}
          icon={<FileSpreadsheet size={16} />}
        >
          Export to Excel
        </Button></Space>
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
      />
    </div>
  );
};

export default Leaves;