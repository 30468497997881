import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Modal,
  Form,
  
  Upload,
  message,
  Space,
  Card,
  Typography,
  Descriptions,
  Tag,
  Tabs,
  Divider,
} from "antd";
import {
  Edit,
  Trash2,
  Search,
  Upload as UploadIcon,
  FileSpreadsheet,
  Image as ImageIcon,
  RefreshCw,
  Star,
  XCircle,
  Info,
  Eye,
  Plus,
} from "lucide-react";
import { Modal as BootstrapModal, TabPane } from "react-bootstrap";
import axios from "axios";
import { styled } from '@mui/material/styles';
import * as XLSX from "xlsx";
import parse from "html-react-parser";
import {
  FormControl,
  Box,
  CardContent,
  Grid,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";
import CustomInput from "../components/CustomInput";
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const StyledDropzone = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const FruitsList = () => {
  const token = sessionStorage.getItem("token");
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [categoryData, setcategorydata] = useState([]);
  const [subCategoryData, setsubcategorydata] = useState([]);
  const [tag, settag] = useState([]);
  const [units, setunits] = useState([]);

  // Form state (keeping all original state variables)
  const [title, setTitle] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [category, setcategory] = useState("");
  const [subCategory, setsubcategory] = useState("");
  const [productTag, setProductTag] = useState("");
  const [discount, setDiscount] = useState("");
  const [clubDiscount, setClubDiscount] = useState("");
  const [stock, setStock] = useState("");
  const [miniStock, setMiniStock] = useState("");
  const [unit, setUnit] = useState("");
  const [miniUnit, setMiniUnit] = useState("");
  const [subscription, setSubscription] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [newOption, setNewOption] = useState({
    value: "",
    price: "",
    units: "",
  });
  const [previewList, setPreviewList] = useState([]);
  const [previewClubList, setPreviewClubList] = useState([]);
  const PreviewItem = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(1),
  }));
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) =>
      setUploadedImages([...uploadedImages, ...acceptedFiles]),
    accept: "image/*,video/*",
  });

  const getcategory = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getCategory",{ headers: {
      Authorization: `Bearer ${token}`,
    },});
    if ((res.status = 200)) {
      ////console.log(res);
      setcategorydata(res.data?.category);
    }
  };
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedImages((prevImages) => [...prevImages, ...files]);
  };

  const removeItem2 = (index) => {
    setUploadedImages(uploadedImages.filter((_, i) => i !== index));
  };
  const getsubcategory = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/getSubcategory",{ headers: {
        Authorization: `Bearer ${token}`,
      },}
    );
    if ((res.status = 200)) {
      ////console.log(res);
      setsubcategorydata(res.data?.Subcategory);
    }
  };
  const gettag = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getTag",{ headers: {
      Authorization: `Bearer ${token}`,
    },});
    if ((res.status = 200)) {
      ////console.log(res);
      settag(res.data?.Tag);
    }
  };
  const getunit = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getUnit",{ headers: {
      Authorization: `Bearer ${token}`,
    },});
    if ((res.status = 200)) {
      ////console.log(res);
      setunits(res.data?.Unit);
    }
  };
  useEffect(() => {
    getunit();
    gettag();
    getcategory();
    getsubcategory();
  }, []);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const removeWeight = async (id1, ids) => {
    try {
      const config = {
        url: `/removefruitsWeight/${id1}/${ids}`,
        method: "delete",
        baseURL: "https://parnetslink.xyz/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status !== 200) {
        throw new Error("Failed to delete image");
      }
      message.success("Successfully removed");
      window.location.reload();
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.error);
      } else {
        message.error("Something Wrong");
      }
    }
  };

  // Form States
  const [form] = Form.useForm();
  const [uploadedExcel, setUploadedExcel] = useState(null);
  const handleRemovePhoto = async (productId, photoId) => {
    try {
      await axios.delete(
        `https://parnetslink.xyz/api/admin/removefruitsPhotos/${productId}/${photoId}`,{headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      message.success("Photo removed successfully");
      
      fetchProducts();
      window.location.reload()
    } catch (error) {
      message.error("Failed to remove photo");
    }
  };
  const calculateDiscountedPrice = (price, discountPercentage) => {
    const discount = (price * discountPercentage) / 100;
    return (price - discount).toFixed(2);
  };
  const addToPreview = (data) => {
    if (
      newOption.value &&
      newOption.price &&
      newOption.units &&
      discount ? discount : data.discount &&
      clubDiscount ? clubDiscount : data.clubdiscount
    ) {
      const normalDiscountedPrice = calculateDiscountedPrice(
        Number(newOption.price),
        Number(discount ? discount : data.discount)
      );

      const clubDiscountedPrice = calculateDiscountedPrice(
        Number(newOption.price),
        Number(clubDiscount ? clubDiscount : data.clubdiscount)
      );

      const newPreviewItem = {
        ...newOption,
        discountedPrice: normalDiscountedPrice,
        id: Date.now(),
      };

      const newClubPreviewItem = {
        ...newOption,
        discountedPrice: clubDiscountedPrice,
        id: Date.now(),
      };

      setPreviewList([...previewList, newPreviewItem]);
      setPreviewClubList([...previewClubList, newClubPreviewItem]);
      setNewOption({ value: "", price: "", units: "" });
    } else {
      message.error("Please fill in all option details and discounts");
    }
  };

  const removeFromPreview = (id) => {
    setPreviewList(previewList.filter((item) => item.id !== id));
  };

  const removeFromClubPreview = (id) => {
    setPreviewClubList(previewClubList.filter((item) => item.id !== id));
  };
  // Fetch Products
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://parnetslink.xyz/api/admin/Getfruitslist",{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      const filteredData = response.data.fruitsList.filter(
        (p) => p.category === "Fruits & Vegetables"
      );
      setProducts(filteredData);
      setFilteredProducts(filteredData);
    } catch (error) {
      message.error("Failed to fetch products");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Search Handler
  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = products.filter((product) =>
      Object.values(product).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredProducts(filtered);
  };
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setProductDesc(data);
  };
  // Excel Export
  const exportToExcel = () => {
    const exportData = filteredProducts.map(
      ({ description, Photos,weight,Clubweight, stock,ministock,unit,miniunit, ...rest }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    XLSX.writeFile(workbook, "fruits-export.xlsx");
  };

  const removeClubWeight = async (id1, ids) => {
    try {
      const config = {
        url: `/removefruitsClubWeight/${id1}/${ids}`,
        method: "delete",
        baseURL: "https://parnetslink.xyz/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status !== 200) {
        throw new Error("Failed to delete image");
      }
      message.success("Successfully removed");
      window.location.reload();
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.error);
      } else {
        message.error("Something Wrong");
      }
    }
  };
  const handleExcelUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        // Process the data
        const response = await axios.post(
          "https://parnetslink.xyz/api/admin/addmultifruits",
          {
            fruits: data.map((datas) => ({
              productname: datas.Product_Name,
              category: datas.Category,
              subcategory: datas.Subcategory,
              description: datas.Description,
              price: datas.MRP_Price,
              discount: datas.Discount || 0,
              unit: datas.Current_Unit,
              miniunit: datas.Minimum_Unit,
              stock: datas.Current_Stock,
              clubdiscount: datas.Club_Discount || 0,
              weight:
                datas.Normal_Weight_Value &&
                datas.Normal_Weight_Price &&
                datas.Normal_Weight_Units
                  ? [
                      {
                        value: datas.Normal_Weight_Value,
                        price: datas.Normal_Weight_Price,
                        units: datas.Normal_Weight_Units,
                      },
                      {
                        value: datas.Normal_Weight_Value1,
                        price: datas.Normal_Weight_Price1,
                        units: datas.Normal_Weight_Units1,
                      },
                      {
                        value: datas.Normal_Weight_Value2,
                        price: datas.Normal_Weight_Price2,
                        units: datas.Normal_Weight_Units2,
                      },
                    ]
                  : undefined,
              Clubweight:
                datas.Normal_Weight_Value &&
                datas.Normal_Weight_Price &&
                datas.Normal_Weight_Units
                  ? [
                      {
                        value: datas.Normal_Weight_Value,
                        price: datas.Normal_Weight_Price,
                        units: datas.Normal_Weight_Units,
                      },
                      {
                        value: datas.Normal_Weight_Value1,
                        price: datas.Normal_Weight_Price1,
                        units: datas.Normal_Weight_Units1,
                      },
                      {
                        value: datas.Normal_Weight_Value2,
                        price: datas.Normal_Weight_Price2,
                        units: datas.Normal_Weight_Units2,
                      },
                    ]
                  : undefined,
              Photos: [
                { file: datas.Product_Image_1_Name },
                { file: datas.Product_Image_2_Name },
                { file: datas.Product_Image_3_Name },
                { file: datas.Product_Image_4_Name },
              ].filter((photo) => photo.file),
              tag: datas.Tag,
              subscribe: datas.Normal_Subscribe,
              ministock: datas.Minimum_Stock || 0,
              status: "Active",
            })),
          },{ headers: {
            Authorization: `Bearer ${token}`,
          },}
        );

        if (response.status === 200) {
          message.success("Products imported successfully");
          fetchProducts();
        }
      } catch (error) {
        message.error("Failed to import products");
      }
    };
    reader.readAsBinaryString(file);
  };

  // Image Upload
  const handleImageUpload = async (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("productimage", file);
    });

    try {
      const response = await axios.post(
        "https://parnetslink.xyz/api/admin/productimage",
        formData,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (response.status === 200) {
        message.success("Images uploaded successfully");
        fetchProducts();
      }
    } catch (error) {
      message.error("Failed to upload images");
    }
  };

  // Product Actions
  const handleEdit = (product) => {
    setCurrentProduct(product);
    form.setFieldsValue(product);
    setEditModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://parnetslink.xyz/api/admin/deletefruits/${id}`,{headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      message.success("Product deleted successfully");
      fetchProducts();
      setDeleteModalVisible(false);
    } catch (error) {
      message.error("Failed to delete product");
    }
  };

  const handleStatusToggle = async (product) => {
    try {
      const endpoint =
        product.status === "Active"
          ? "changeActiveStatus"
          : "changeInactiveStatus";
      await axios.get(
        `https://parnetslink.xyz/api/admin/${endpoint}/${product._id}`,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      message.success(
        `Product ${
          product.status === "Active" ? "blocked" : "unblocked"
        } successfully`
      );
      fetchProducts();
    } catch (error) {
      message.error("Failed to update product status");
    }
  };
  const weights = previewList.concat(currentProduct?.weight);
  const Register1 = async () => {
    try {
      const config = {
        url: `/updatefruits/${currentProduct?._id}`,
        method: "post",
        baseURL: "https://parnetslink.xyz/api/admin",
        headers: { "content-type": "application/json" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          productname: title,
          price: productPrice,
          description: productDesc,
          category: category,
          subcategory: subCategory,
          tag: productTag,
          discount: discount,
          clubdiscount: clubDiscount,

          stock: stock,
          unit: unit,
          miniunit: miniUnit,
          ministock: miniStock,
          weight: weights,
          subscribe: subscription,
          Clubweight: weights,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          uploadFiles(res.data.fruits?._id);
          //handlesuccess();
        }
      });
    } catch (error) {
      //////console.log(error);
      message.success(error.response.data.error);
    }
  };
  const uploadFiles = async (id) => {
    const formdata = new FormData();
    try {
      for (let i = 0; i < uploadedImages.length; i++) {
        formdata.append("id", id);
        formdata.append("Photos", uploadedImages[i]);
        await fetch("https://parnetslink.xyz/api/admin/addfruitsPhotos/", {
          method: "post",
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      message.success("Products updated successfully");
      window.location.reload();
    } catch (error) {
      ////console.log(error);
    }
  };
  const columns = [
    {
      title: "Product Name",
      dataIndex: "productname",
      key: "productname",
      sorter: (a, b) => a.productname.localeCompare(b.productname),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Button
          type={record.status === "Active" ? "primary" : "danger"}
          onClick={() => handleStatusToggle(record)}
        >
          {record.status === "Active" ? "Active" : "Inactive"}
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<Eye size={16} />}
            onClick={() => {
              setCurrentProduct(record);
              setDetailModalVisible(true);
            }}
          />
          <Button
            type="text"
            icon={<Edit size={16} />}
            onClick={() => handleEdit(record)}
          />
          <Button
            type="text"
            danger
            icon={<Trash2 size={16} />}
            onClick={() => {
              setCurrentProduct(record);
              setDeleteModalVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const DetailModal = ({ product, visible, onClose }) => {
    if (!product) return null;

    const normalWeights = product.weight;
    const clubWeights = product.Clubweight;

    return (
      <Modal
        title={
          <Space>
            <Info size={20} /> Product Details
          </Space>
        }
        visible={visible}
        onCancel={onClose}
        width={1000}
        footer={[
          <Button key="close" onClick={onClose}>
            Close
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Basic Information" key="1">
            <Descriptions bordered column={2}>
              <Descriptions.Item label="Product Name" span={2}>
                {product.productname}
              </Descriptions.Item>
              <Descriptions.Item label="Category">
                {product.category}
              </Descriptions.Item>
              <Descriptions.Item label="Subcategory">
                {product.subcategory}
              </Descriptions.Item>
              <Descriptions.Item label="Tag">{product.tag}</Descriptions.Item>
              <Descriptions.Item label="Subscription Type">
                {product.subscribe}
              </Descriptions.Item>
              <Descriptions.Item label="Base Price">
                {formatCurrency(product.price)}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tag color={product.status === "Active" ? "green" : "red"}>
                  {product.status}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </TabPane>

          <TabPane tab="Pricing & Discounts" key="2">
            <Card title="Regular Pricing">
              <Descriptions bordered column={2}>
                <Descriptions.Item label="Base Price">
                  {formatCurrency(product.price)}
                </Descriptions.Item>
                <Descriptions.Item label="Regular Discount">
                  {product.discount}%
                </Descriptions.Item>
                <Descriptions.Item label="Club Discount">
                  {product.clubdiscount}%
                </Descriptions.Item>
              </Descriptions>

              <Divider>Weight-based Pricing</Divider>

              <div style={{ display: "flex", gap: "20px" }}>
                <Card title="Regular Prices" style={{ flex: 1 }}>
                  {normalWeights.map((item, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <Tag color="blue">{item.value +" "+ item?.units}</Tag>
                      <Text style={{ marginLeft: "10px" }}>
                        {formatCurrency(item.price)}
                      </Text>
                    </div>
                  ))}
                </Card>

                <Card title="Club Member Prices" style={{ flex: 1 }}>
                  {clubWeights.map((item, index) => (
                     <div key={index} style={{ marginBottom: "10px" }}>
                     <Tag color="purple">{item.value +" "+ item?.units}</Tag>
                     <Text style={{ marginLeft: "10px" }}>
                       {formatCurrency(item.price)}
                     </Text>
                   </div>
                  ))}
                </Card>
              </div>
            </Card>
          </TabPane>

          {/* <TabPane tab="Stock Information" key="3">
            <Descriptions bordered column={2}>
              <Descriptions.Item label="Current Stock">
                {product.stock}
              </Descriptions.Item>
              <Descriptions.Item label="Minimum Stock">
                {product.ministock}
              </Descriptions.Item>
              <Descriptions.Item label="Unit">
                {product.unit}
              </Descriptions.Item>
              <Descriptions.Item label="Minimum Unit">
                {product.miniunit}
              </Descriptions.Item>
            </Descriptions>
          </TabPane> */}

          <TabPane tab="Description" key="4">
            <Card>
              {product.description && typeof product.description === "string"
                ? parse(product.description)
                : "No description available"}
            </Card>
          </TabPane>

          <TabPane tab="Media Gallery" key="5">
            <div className="grid grid-cols-3 gap-4">
              {product.Photos?.map((photo, index) => (
                <div key={index} className="relative">
                  {photo.file.match(/\.(mp4)$/i) ? (
                    <video
                      src={`https://parnetslink.xyz/product/${photo.file}`}
                      className="w-full h-40 object-contain rounded"
                      controls
                    />
                  ) : (
                    <img
                      src={`https://parnetslink.xyz/product/${photo.file}`}
                      alt={`Product ${index + 1}`}
                      className="w-full h-40 object-contain rounded"
                    />
                  )}
                  <Button
                    type="text"
                    danger
                    icon={<XCircle size={16} />}
                    className="absolute top-2 right-2"
                    onClick={() => handleRemovePhoto(product._id, photo._id)}
                  />
                </div>
              ))}
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    );
  };
  return (
    <div className="p-6">
      <Card>
        <div className="mb-6">
          <Title level={3}>Fruits and Vegetables Product Management</Title>

          {/* Action Buttons */}
          <Space className="mb-4">
            <Button type="primary" className="buttons">
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="https://parnetslink.xyz/excelfile/fruitexcel.xlsx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Excel Template
              </a>
            </Button>
            <Upload
              accept=".xlsx,.xls"
              showUploadList={false}
              beforeUpload={(file) => {
                handleExcelUpload(file);
                return false;
              }}
            >
              <Button icon={<FileSpreadsheet size={16} />}>Import Excel</Button>
            </Upload>

            <Button
              icon={<FileSpreadsheet size={16} />}
              onClick={exportToExcel}
            >
              Export Excel
            </Button>

            <Upload
              accept="image/*,video/*"
              multiple
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageUpload([file]);
                return false;
              }}
            >
              <Button icon={<ImageIcon size={16} />}>Upload Images</Button>
            </Upload>
            <DetailModal
              product={currentProduct}
              visible={detailModalVisible}
              onClose={() => setDetailModalVisible(false)}
            />
          </Space>

          {/* Search */}
          <Input
            placeholder="Search products..."
            prefix={<Search size={16} />}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="mb-4"
            allowClear
          />

          {/* Products Table */}
          <Table
            columns={columns}
            dataSource={filteredProducts}
            rowKey="_id"
            loading={loading}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
            }}
            scroll={{ x: "max-content" }}
            components={{
              header: {
                cell: (props) => (
                  <th
                    {...props}
                    style={{
                      ...props.style,
                      background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                      color: "white",
                    }}
                  />
                ),
              },
            }}
            
          />
        </div>
      </Card>

      {/* Edit Modal */}

      <BootstrapModal
        show={editModalVisible}
        dialogClassName="custom-modal1"
        onHide={() => setEditModalVisible(false)}
        backdrop="static"
        keyboard={false}
      >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Edit Product</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
       <Grid container spacing={3}>
        {/* Basic Information */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Basic Information</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    onChange={(e) => setcategory(e.target.value)}
                    value={category ? category : currentProduct?.category}
                    label="Category"
                  >
                    <MenuItem value="">Select Category</MenuItem>
                    {categoryData?.filter(e =>e.categoryName === "Fruits & Vegetables")?.map((cat, idx) => (
                      <MenuItem key={idx} value={cat.categoryName}>
                        {cat.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Sub-Category</InputLabel>
                  <Select
                     onChange={(e) => setsubcategory(e.target.value)}
                     value={subCategory ? subCategory : currentProduct?.subcategory}
                    label="Sub-Category"
                  >
                    <MenuItem value="">Select Sub-Category</MenuItem>
                    {subCategoryData
                      .filter((sub) => sub.categoryName === category)
                      .map((sub, idx) => (
                        <MenuItem key={idx} value={sub.SubcategoryName}>
                          {sub.SubcategoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Product Type</InputLabel>
                  <Select
                     onChange={(e) => setSubscription(e.target.value)}
                     value={subscription ? subscription : currentProduct?.subscribe}
                    label="Product Type"
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Subscription">Subscription</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Product Details */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Product Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Product Title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title ? title : currentProduct?.productname}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Product Price"
                  onChange={(e) => setProductPrice(e.target.value)}
                  value={productPrice ? productPrice : currentProduct?.price}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Product Tag</InputLabel>
                  <Select
                       onChange={(e) => setProductTag(e.target.value)}
                       value={productTag ? productTag : currentProduct?.tag}
                    label="Product Tag"
                  >
                    <MenuItem value="">Select Tag</MenuItem>
                    {tag.map((t, idx) => (
                      <MenuItem key={idx} value={t.tag}>
                        {t.tag}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Stock Management */}
        {/* <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Stock Management</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Stock"
                  value={stock}
                  onChange={(e) => setStock(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Stock Units</InputLabel>
                  <Select
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    label="Stock Units"
                  >
                    <MenuItem value="">Select Units</MenuItem>
                    {units.map((u, idx) => (
                      <MenuItem key={idx} value={u.unit}>
                        {u.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Minimum Stock"
                  value={miniStock}
                  onChange={(e) => setMiniStock(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Minimum Stock Units</InputLabel>
                  <Select
                    value={miniUnit}
                    onChange={(e) => setMiniUnit(e.target.value)}
                    label="Minimum Stock Units"
                  >
                    <MenuItem value="">Select Units</MenuItem>
                    {units.map((u, idx) => (
                      <MenuItem key={idx} value={u.unit}>
                        {u.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}

        {/* Pricing Options */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Pricing Options</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Normal Member Discount (%)"
                  onChange={(e) => setDiscount(e.target.value)}
                  value={discount ? discount : currentProduct?.discount}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Club Member Discount (%)"
                  onChange={(e) => setClubDiscount(e.target.value)}
                  value={
                    clubDiscount ? clubDiscount : currentProduct?.clubdiscount
                  }
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Weight/Volume"
                    value={newOption.value}
                    onChange={(e) =>
                      setNewOption({ ...newOption, value: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Units</InputLabel>
                    <Select
                     value={newOption.units}
                     onChange={(e) =>
                       setNewOption({ ...newOption, units: e.target.value })
                     }
                      label="Units"
                    >
                      <MenuItem value="">Select Units</MenuItem>
                      {units.map((u, idx) => (
                        <MenuItem key={idx} value={u.unit}>
                          {u.unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Price"
                    value={newOption.price}
                    onChange={(e) =>
                      setNewOption({ ...newOption, price: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    startIcon={<Plus />}
                    fullWidth
                    onClick={()=>addToPreview(currentProduct)}
                    sx={{ height: '56px' }}
                    
                    style={{backgroundColor:"#5C870C",color:"white"}}
                  >
                    Add Option
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* Preview Lists */}
            {previewList.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Normal Members Preview</Typography>
                {previewList.map((option) => (
                  <PreviewItem key={option.id}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>
                        {option.value} {option.units} - Rs {option.price} (Discounted: Rs {option.discountedPrice})
                      </Typography>
                      <IconButton onClick={() => removeFromPreview(option.id)} color="error">
                        <Trash2 />
                      </IconButton>
                    </CardContent>
                  </PreviewItem>
                ))}
              </Box>
            )}
              {currentProduct?.weight.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Club Members Preview</Typography>
                {currentProduct?.weight.map((option) => (
                  <PreviewItem key={option.id}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>
                        {option.value} {option.units} - Rs {option.price} (Discounted: Rs {option.discountedPrice})
                      </Typography>
                      <IconButton onClick={() => removeFromClubPreview(option.id)} color="error">
                        <Trash2 />
                      </IconButton>
                    </CardContent>
                  </PreviewItem>
                ))}
              </Box>
            )}
               {previewClubList.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Club Members Preview</Typography>
                {previewClubList.map((option) => (
                  <PreviewItem key={option.id}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>
                        {option.value} {option.units} - Rs {option.price} (Discounted: Rs {option.discountedPrice})
                      </Typography>
                      <IconButton onClick={() => removeFromClubPreview(option.id)} color="error">
                        <Trash2 />
                      </IconButton>
                    </CardContent>
                  </PreviewItem>
                ))}
              </Box>
            )}
               {currentProduct?.Clubweight.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Club Members Preview</Typography>
                {currentProduct?.Clubweight.map((option) => (
                  <PreviewItem key={option.id}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>
                        {option.value} {option.units} - Rs {option.price} (Discounted: Rs {option.discountedPrice})
                      </Typography>
                      <IconButton onClick={() => removeFromClubPreview(option.id)} color="error">
                        <Trash2 />
                      </IconButton>
                    </CardContent>
                  </PreviewItem>
                ))}
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Description */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Product Description</Typography>
            <CKEditor
             editor={ClassicEditor}
             data={productDesc ? productDesc : currentProduct?.description}
             onChange={(event, editor) => {
               const data = editor.getData();
               setProductDesc(data);
             }}
           />
         </Paper>
       </Grid>

       {/* Image Upload */}
       <Grid item xs={12}>
         <Paper sx={{ p: 3 }}>
           <Typography variant="h6" gutterBottom style={{marginBottom:'20px',marginRight:'auto',fontWeight:'900'}}>Product Images</Typography>
           
           <StyledDropzone {...getRootProps()}>
             <input {...getInputProps()} />
             <Upload size={40} className="mx-auto mb-2" />
             <Typography>
               Drag & drop files here, or click to select files
             </Typography>
             <Typography variant="body2" color="textSecondary">
               Supports images and videos
             </Typography>
           </StyledDropzone>

           {uploadedImages.length > 0 && (
             <Grid container spacing={2}>
               {uploadedImages.map((file, index) => (
                 <Grid item xs={6} sm={4} md={3} key={index}>
                   <Card>
                     <Box sx={{ position: 'relative' }}>
                       {file.type.startsWith('video/') ? (
                         <video
                           src={URL.createObjectURL(file)}
                           controls
                           style={{
                             width: '100%',
                             height: '200px',
                             objectFit: 'cover'
                           }}
                         />
                       ) : (
                         <Box
                           component="img"
                           src={URL.createObjectURL(file)}
                           alt={`Upload ${index + 1}`}
                           sx={{
                             width: '100%',
                             height: '200px',
                             objectFit: 'cover'
                           }}
                         />
                       )}
                       <IconButton
                         sx={{
                           position: 'absolute',
                           top: 8,
                           right: 8,
                           backgroundColor: 'rgba(255, 255, 255, 0.8)',
                           '&:hover': {
                             backgroundColor: 'rgba(255, 255, 255, 0.9)'
                           }
                         }}
                         onClick={() => {
                           const newImages = [...uploadedImages];
                           newImages.splice(index, 1);
                           setUploadedImages(newImages);
                         }}
                       >
                         <Trash2 size={20} />
                       </IconButton>
                     </Box>
                   </Card>
                 </Grid>
               ))}
             </Grid>
           )}
         </Paper>
       </Grid>

       {/* Submit Button */}
       <Grid item xs={12}>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
           <Button
             variant="contained"
             size="large"
             onClick={Register1}
             startIcon={<Plus />}
             
             style={{backgroundColor:"#5C870C",color:'white'}}
           >
             Edit Product
           </Button>
         </Box>
       </Grid>
     </Grid>
     </BootstrapModal.Body>
      </BootstrapModal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={() => handleDelete(currentProduct?._id)}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this product?</p>
      </Modal>

      {/* Image Preview Modal */}
      <Modal
        title="Product Images"
        visible={imageModalVisible}
        onCancel={() => setImageModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="grid grid-cols-3 gap-4">
          {currentProduct?.Photos?.map((photo, index) => (
            <div key={index} className="relative">
              {photo.file.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                <img
                  src={`https://parnetslink.xyz/product/${photo.file}`}
                  alt={`Product ${index + 1}`}
                  className="w-full h-40 object-cover rounded"
                />
              ) : (
                <video
                  src={`https://parnetslink.xyz/product/${photo.file}`}
                  className="w-full h-40 object-cover rounded"
                  controls
                />
              )}
              <Button
                type="text"
                danger
                icon={<XCircle size={16} />}
                className="absolute top-2 right-2"
                onClick={() => {
                  // Handle image deletion
                }}
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default FruitsList;
