import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Users, Star, ShoppingCart, Repeat } from "lucide-react";
import axios from "axios";
import { Link } from "react-router-dom";

const DashboardCard = ({ title, value, icon: Icon }) => (
  <Card sx={{ height: "100%" }}>
    <CardContent>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Icon size={24} />
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
          <Typography variant="h5" component="div">
            {value}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [clubUsers, setClubUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orders1, setOrders1] = useState([]);
  const token = sessionStorage.getItem("token");

  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersRes, ordersRes, subscriptionsRes] = await Promise.all(
          [
            axios.get("https://parnetslink.xyz/api/user/getAllUser",{headers: {
              Authorization: `Bearer ${token}`,
            },}),
            axios.get("https://parnetslink.xyz/api/user/getallOrder",{headers: {
              Authorization: `Bearer ${token}`,
            },}),
            axios.get("https://parnetslink.xyz/api/user/getallSubscriptions",{headers: {
              Authorization: `Bearer ${token}`,
            },}),
          ],
        
        );

        setUsers(usersRes.data.success);
        setOrders1(ordersRes.data.success);
        setClubUsers(
          usersRes.data.success.filter((user) => user.club === true)
        );
        setOrders(ordersRes.data.success.slice(0, 5));
        setSubscriptions(subscriptionsRes.data.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { id: "sno", label: "SNo", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 170 },
    { id: "product", label: "Product", minWidth: 200 },
    { id: "status", label: "Status", minWidth: 100 },
  ];

  const rows = orders.map((order, index) => ({
    sno: index + 1,
    name: order?.shippingAddress?.fullName
      ? order?.shippingAddress?.fullName
      : order?.shippingAddress?.name,
    product: order?.items
      ?.map((item) => `${item.name} - ${item.quantity} x ${item.price} INR`)
      .join(", "),
    status: order?.status,
  }));

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/admindashboard/customers">
            {" "}
            <DashboardCard
              title="Normal Users"
              value={users.length}
              icon={Users}
            />{" "}
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/admindashboard/clubcustomers">
            {" "}
            <DashboardCard
              title="Club Members"
              value={clubUsers.length}
              icon={Star}
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/admindashboard/orders">
            {" "}
            <DashboardCard
              title="Normal Orders"
              value={orders1.length}
              icon={ShoppingCart}
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/admindashboard/subscription">
            {" "}
            <DashboardCard
              title="Subscription Orders"
              value={subscriptions.length}
              icon={Repeat}
            />
          </Link>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Recent Orders
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth, color: "white" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.sno}>
                  <TableCell>{row.sno}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.product}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Dashboard;
