import React, { useEffect, useState } from "react";
import Hero from "./home/Hero";
import Featured from "./home/Featured";
import Fruits from "./home/Fruits";
import Service from "./home/Service";
import Vegetables from "./home/Vegetables";
import Banner from "./home/Banner";
import Bestseller from "./home/Bestseller";
import Fact from "./home/Fact";
import Testimonial from "./testimonial/Testimonial";
import Milk from "./home/Milk";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { toast, ToastContainer } from "react-toastify";
const CategoryCard = ({ title, imageUrl }) => {
  const navigation = useNavigate();
  const scrollToTop = (categoryName) => {
    navigation("/shop", { state: { title: categoryName } });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="category-card">
      <div
        className="image-wrapper"
        style={{
          backgroundImage: `url(https://parnetslink.xyz/image/${imageUrl})`,
        }}
        onClick={() => scrollToTop(title)}
      >
        <div className="overlay1">
          <h3 className="category-title">{title}</h3>
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [paymentShow, setPaymentShow] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
const token = sessionStorage.getItem("token");
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const handlePayment = () => {
    setPaymentComplete(true);
    setPaymentShow(false);
    handleClick();
  };
  const handleClick1 = async () => {
    setPaymentShow(true);
    setShowModal(false);
  };
  const [banner, setbanner] = useState([]);
  const getbanner = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/getallclubbanner",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if ((res.status = 200)) {
      ////console.log(res);
      setbanner(res.data?.clubbanner);
    }
  };
  useEffect(() => {
    getbanner();
  }, []);
  const handleClick = async () => {
    try {
      axios
        .post(`https://parnetslink.xyz/api/user/changeClubStatus/` + user._id,
          {}, // You can pass data here if required, otherwise keep it as an empty object
          {
            headers: {
              Authorization: `Bearer ${token}`, // Adding Bearer token
            },
          })
        .then(function (response) {
          if (response.status === 200) {
            let updatedUser = { ...user, club: true };
            sessionStorage.setItem("user", JSON.stringify(updatedUser));
            toast.info("Registered as club member");
            window.location.reload();
          }
        })
        .catch(function (error) {
          toast.info("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      toast.info("Not able to complete");
      return error;
    }
  };
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  const [categorydata, setcategorydata] = useState([]);
  const getcategory = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getCategory",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if ((res.status = 200)) {
      ////console.log(res);
      setcategorydata(
        res.data?.category.filter(
          (e) => e.categoryName !== "Fruits & Vegetables"
        )
      );
    }
  };
  useEffect(() => {
    getcategory();
  }, []);
  const closeModal = () => {
    setShowModal(false);
  };
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const chunkedCategories = chunkArray(categorydata, 6);
  const openWhatsApp = () => {
    window.open('https://wa.me/yourNumber', '_blank'); 
  };
  
  return (
    <>
      <ToastContainer />
      <Hero />
      <Bestseller />
      <div className="product-section">
        <h1
          className="text-2xl md:text-2xl lg:text-2xl font-bold text-center mb-6"
          style={{ color: "#45595b" }}
        >
          Our Categories
        </h1>
        <Carousel
          showArrows={true}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          showThumbs={false}
        >
          {chunkedCategories.map((chunk, index) => (
            <div key={index} className="category-row">
              {chunk.map((category, categoryIndex) => (
                <CategoryCard
                  key={categoryIndex}
                  title={category.categoryName}
                  imageUrl={category.categoryimage}
                />
              ))}
            </div>
          ))}
        </Carousel>
      </div>

      <Fruits />

      <Banner />

      {isVisible && (
        <>
          <div className="scroll-to-top" onClick={scrollToTop}>
            <div className="back-top-container">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                height={26}
                width={26}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={48}
                  d="M112 328l144-144 144 144"
                />
              </svg>
            </div>
          </div>

          <div className="scroll-to-bottom" onClick={scrollToBottom}>
            <div className="back-bottom-container">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                height={26}
                width={26}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={48}
                  d="M112 184l144 144 144-144"
                />
              </svg>
            </div>
          </div>
          <div className="whatsapp-icon" onClick={openWhatsApp}>
      <div className="whatsapp-container">
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth={0}
          viewBox="0 0 448 512"
          height={26}
          width={26}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M380.9 97.1c-40.8-40.8-94.8-63.3-152.3-63.3-118.6 0-214.8 96.2-214.8 214.8 0 38.1 10 75.4 29.1 108L0 512l141.6-43.8c31.8 18.7 68 28.6 105.4 28.6 118.6 0 214.8-96.2 214.8-214.8 0-57.6-22.5-111.6-63.3-152.3zm-152.3 319.7c-33.5 0-66.5-9.2-94.9-26.7l-6.8-4.1-84.2 26.1 25.8-82.4-4.4-7.2c-17.7-29-27-62.4-27-96.7 0-105.2 85.6-190.8 190.8-190.8 51 0 98.9 19.9 134.9 55.9 36 36 55.9 83.9 55.9 134.9 0 105.1-85.6 190.7-190.8 190.7zm105.3-138.1c-5.8-2.9-34.4-16.9-39.7-18.8-5.4-1.9-9.3-2.9-13.1 2.9-3.9 5.8-14.9 18.8-18.3 22.7-3.4 3.9-6.8 4.4-12.6 1.5-5.8-2.9-24.6-9-46.9-29.2-17.4-15.5-29.2-34.6-32.7-40.4-3.4-5.8-.4-8.9 2.5-11.8 2.9-2.9 5.8-6.8 8.7-10.2 2.9-3.4 3.9-5.8 5.8-9.7 1.9-3.9 1-7.3-.5-10.2-1.5-2.9-13.1-31.6-18-43.5-4.8-11.5-9.7-9.9-13.1-10.1h-11.3c-3.9 0-10.2 1.5-15.5 7.3-5.4 5.8-20.3 19.8-20.3 48.3 0 28.4 20.8 55.8 23.7 59.7 2.9 3.9 41.1 62.7 99.3 87.9 58.2 25.1 58.2 16.8 68.8 15.8 10.5-1 34.4-12.6 39.3-24.7 4.9-12.1 4.9-22.4 3.4-24.7-1.5-2.5-5.3-3.9-11.1-6.8z" />
        </svg>
      </div>
    </div>
        </>
      )}
      <Featured />

      <Testimonial />
      {!user?.club && user && (
        <Modal
          title={
            <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
              Join Our Club!
            </h2>
          }
          visible={showModal}
          onCancel={closeModal}
          footer={null}
          centered
          bodyStyle={{ padding: "20px", textAlign: "center" }}
        >
          {banner.slice(0, 1).map((e) => (
            <>
              <img
                src={`https://parnetslink.xyz/image/${e.clubbanner}`}
                alt="Join Our Club"
                style={{ width: "100%", height: "auto", marginBottom: "20px" }}
              />
              <p style={{ fontSize: "16px", marginBottom: "20px" }}>{e.type}</p>
            </>
          ))}
          <button className="buttons" onClick={handleClick1}>
            Join as a Club Member
          </button>
        </Modal>
      )}
      <Modal
        title="Complete Your Payment"
        visible={paymentShow}
        onCancel={() => setPaymentShow(false)}
        footer={null}
      >
        <p>Please make a payment of 200 INR to complete your registration.</p>
        <button
          className="buttons"
          style={{ width: "100px", padding: "10px" }}
          onClick={handlePayment}
        >
          Pay Now
        </button>
      </Modal>
    </>
  );
};

export default Home;
