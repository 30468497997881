import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const token = sessionStorage.getItem("token");
  const [categorydata, setcategorydata] = useState([]);
const navigation = useNavigate()
  const getcategory = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getCategory",{ headers: {
      Authorization: `Bearer ${token}`,
    },});
    if ((res.status = 200)) {
      ////console.log(res);
      setcategorydata(res.data?.category.filter((e) => e.categoryName !== "Fruits & Vegetables")
    );
    }
  };
  useEffect(() => {
    getcategory();
  }, []);
  const scrollToTop = (categoryName) => {
    navigation('/shop', { state: { title: categoryName } });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className="mt-10 px-6 py-10 sm:px-20 md:px-24 lg:px-16 xl:px-20"
      style={{ backgroundColor: "#45595b" }}
    >
      {/* <div className="py-2 lg:grid lg:grid-cols-3 lg:items-center xl:flex xl:justify-between ">
        <div>
          <img
            alt="logo"
            class="desktop-logo hide-mobile"
            src="../img/logo.png"
          style={{width:'200px',height:'100px'}}
          />
           <h1 className='text-3xl font-semibold lg:text-4xl lg:grid-cols-1' style={{color: '#5C870C'}}>Fruitables</h1> 
          <p className="text-md" style={{ color: "#ffb524",textAlign:'center' }}>
            Fresh products
          </p> 
        </div>
        <div className="relative box-border w-full xl:mx-28 my-10 lg:grid-cols-2">
          <input
            className="border-gray-100 outline-none relative w-full rounded-full pl-4 py-3 px-4"
            type="email"
            placeholder="Your Email"
          />
          <button
            className="subscribe duration-500 text-white absolute py-3 right-0 top-0 px-6 rounded-full font-semibold text-md"
            type="submit"
          >
            Subscribe Now
          </button>
        </div>*/}
        
     <ul className="flex justify-end mb-4">
     <li className="mx-1">
            <i className="fab fa-facebook-f  py-3 px-4 social rounded-full border border-orange-400"></i>
          </li>
          <li className="mx-1">
            <i className="fab fa-whatsapp py-3 px-3 social rounded-full border border-orange-400"></i>
          </li>
          <li className="mx-1">
            <i className="fab fa-instagram py-3 px-3 social rounded-full border border-orange-400"></i>
          </li>
          <li className="mx-1">
            <i className="fab fa-youtube py-3 px-3 social rounded-full border border-orange-400"></i>
          </li>
          
        </ul> 
      {/* </div>  */}
      {/* <hr className="w-10/12 lg:w-full m-auto h-0.5 mb-6" /> */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-y-8 lg:grid-cols-4">
        <div>
          <img
            alt="logo"
            class="desktop-logo hide-mobile"
            src="../logo2.png"
            style={{ width: "230px", height: "100px" }}
          />
          <h3 className="footer-head lg:w-40 xl:w-auto">Why People Like us!</h3>
          <p className="footer-contact mt-4 md:w-50 lg:w-64 xl:w-75">
            typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the like Aldus PageMaker including of Lorem Ipsum.
          </p>
        </div>
        <div>
          <h3 className="footer-head">Categories</h3>
          <ul className="mt-5 ">
            {categorydata.map((data) => (
             <div onClick={() => scrollToTop(data.categoryName)}> <li className="footer-body">{data.categoryName}</li></div>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="footer-head">Help & Support</h3>
          <ul className="mt-5 ">
            <li className="footer-body">About Us</li>
            <li className="footer-body">Contact Us</li>
            <li className="footer-body">Privacy Policy</li>
            <li className="footer-body">Terms & Condition</li>
            <li className="footer-body">Return Policy</li>
            <li className="footer-body">FAQs & Help</li>
          </ul>
        </div>

        <div>
          <h3 className="footer-head" style={{ textAlign: "center" }}>
            Contact
          </h3>
          <div className="mt-5">
            <p className="footer-contact">Address: 123 road, IN 560056</p>
            <p className="footer-contact">Email: Example@gmail.com</p>
            <p className="footer-contact">Phone: +91 92 4567 8910</p>
            <p className="footer-contact">Payment Accepted</p>
            <img className="mt-4" src="../img/payment.png" alt="" />
          </div>
        </div>
      </div>
      <hr className=" my-6" />
      <div className="m-auto text-center">
        <p className="font-medium" style={{ color: "#ffffff80" }}>
          <strong style={{ color: "#ffb524" }}>
            &copy;Abhinandhan Organics,{" "}
          </strong>
          All Right Reserved by ParNets Group
        </p>
      </div>
    </div>
  );
};

export default Footer;
