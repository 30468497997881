import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartReducer from './CartSlice'; 
import cartReducer1 from './CartSlice1'; 


const persistConfigCart = {
  key: 'cart',
  storage,
};

const persistConfigCart1 = {
  key: 'cart1',  
  storage,
};
const persistedCartReducer = persistReducer(persistConfigCart, cartReducer);
const persistedCartReducer1 = persistReducer(persistConfigCart1, cartReducer1);

const rootReducer = combineReducers({
  cart: persistedCartReducer,
  cart1: persistedCartReducer1,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
const persistor = persistStore(store);

export { store, persistor };
