import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
const Banner = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };
    const banners = [
        "https://egrocer.wrteam.me/storage/offers/1710242968_44298.jpg",
        "https://egrocer.wrteam.me/storage/offers/1710242968_44298.jpg",
    ];
    const [banner, setbanner] = useState([]);
    const token = sessionStorage.getItem("token");
    const getbanner = async () => {
        let res = await axios.get("https://parnetslink.xyz/api/admin/getalldiscountbanner",{ headers: {
            Authorization: `Bearer ${token}`,
          },});
        if ((res.status = 200)) {
          ////console.log(res);
          setbanner(res.data?.banner);
        }
      };
      useEffect(() => {
        getbanner();
      }, []);
    return (
       
        <Slider {...settings}>
            {banner.map((banner, index) => (
                <div key={index} className="banner-container">
                    <a href={banner.link}>
                    <img 
                        className="offerImages" 
                        src={`https://parnetslink.xyz/image/${banner.banner}`} 
                        alt={`offer-${index}`} 
                        style={{height:'300px'}}
                    />
                    </a>
                </div>
            ))}
        </Slider>
    )
}

export default Banner
