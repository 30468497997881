import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "./Modal";
import FeedbackForm from "./FeedbackForm";
import DeliveryLocationModal from "./Deliverylocation";
import axios from "axios";
import { SearchOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const Header = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [Data, setData] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const totalItems = useSelector((state) => state.cart?.uniqueItems);
  const totalItems1 = useSelector((state) => state.cart1?.uniqueItems);
  const total = totalItems + totalItems1;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const searchRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  console.log(suggestions, "hhh");
  const getproducts = async () => {
    try {
      let res = await axios.get(
        "https://parnetslink.xyz/api/admin/Getproductlist",{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (res.status === 200) {
        setData(
          res.data.ProductList.filter(
            (item) =>
              item.status === "Active" &&
              item.category !== "Fruits & Vegetables"
          )
        );
      }
    } catch (error) {
      console.error("Error fetching product list", error);
    }
  };

  useEffect(() => {
    getproducts();
    fetchTexts();
  }, []);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const [texts, setTexts] = useState([]);
  const fetchTexts = async () => {
    try {
      const response = await axios.get(
        `https://parnetslink.xyz/api/admin/getText`,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      setTexts(response.data?.Text || []);
    } catch (error) {
      toast.error("Failed to fetch texts");
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    if (value.length > 0) {
      const filteredSuggestions = Data.filter((item) =>
        item.productname.toLowerCase().includes(value.toLowerCase())
      ).slice(0, 5);
      setSuggestions(filteredSuggestions);
      setShowDropdown(true);
    } else {
      setSuggestions([]);
      setShowDropdown(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion.productname);
    setSuggestions([]);
    setShowDropdown(false);
  };

  const openModal = () => setIsModalOpen(true);
  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);
  const closeModal = () => setIsModalOpen(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const scrollToTop = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToTop1 = () => {
    navigate("/shop");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToTop2 = () => {
    navigate(
      `/shop-detail/${
        Data?.find((s) => s.productname === searchValue)?._id || ""
      }`
    );
    setSearchValue("");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className="sticky top-0 z-50 shadow-md"
      style={{ backgroundColor: "#F1F7E5", paddingBottom: "10px" }}
    >
      <div className="web-top-para_edited bg-[#be343b] text-white w-full p-2">
        {texts.slice(0, 1).map((e) => (
          <p className="config-para text-center text-xs sm:text-sm md:text-base mt-2">
            {e.text}
          </p>
        ))}
      </div>
      <div className="hidden md:flex md:container md:mx-auto justify-between sticky z-10">
        <div className="">
          <div onClick={() => scrollToTop()}>
            <img
              alt="logo"
              className="desktop-logo hide-mobile"
              src="../logo2.png"
              style={{
                width: "300px",
                height: "50px",
                marginLeft: "-10px",
                marginTop: "20px",
              }}
            />
          </div>
        </div>

        <div
          className="relative box-border w-full mt-2"
          style={{ alignSelf: "center" }}
          ref={searchRef}
        >
          <input
            className="border border-orange-400 w-full rounded-full pl-4 py-3"
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ paddingRight: "50px" }}
          />
          {showDropdown && suggestions.length > 0 && (
            <div
              className="absolute w-full mt-1 border border-orange-400 rounded-lg bg-white shadow-lg"
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              <ul className="list-none p-2">
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion._id}
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.productname}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <button
            className="text-white absolute top-0 right-0 py-3 px-6 border border-orange-400 font-semibold text-md"
            style={{ backgroundColor: "#5C870C", borderRadius: "20px" }}
            type="submit"
            onClick={() => {
              if (!searchValue) {
                window.location.assign("/");
              } else {
                scrollToTop2();
              }
            }}
          >
            <SearchOutlined />
          </button>
        </div>

        <ul className="flex justify-center items-center">
          <div className="flex">
            <Link to="/cart" className="flex flex-col items-center mx-2">
              <span
                className="fa fa-shopping-bag main-text p-3 relative icon-hov"
                style={{ fontSize: "1.5em" }}
              >
                <span className="absolute top-2 right-0 w-5 h-5 text-xs search flex justify-center items-center bord text-[#5c870c]">
                  {total}
                </span>
              </span>
              <span className="text-xs" style={{ marginTop: "-10px" }}>
                Cart
              </span>
            </Link>

            {user && (
              <Link to="/account" className="flex flex-col items-center mx-2">
                <span
                  className="fas fa-user main-text p-3 icon-hov"
                  style={{ fontSize: "1.5em" }}
                ></span>
                <span className="text-xs" style={{ marginTop: "-10px" }}>
                  Account
                </span>
              </Link>
            )}

            <Link to="/wallet" className="flex flex-col items-center mx-2">
              <span
                className="fas fa-wallet main-text p-3 icon-hov"
                style={{ fontSize: "1.5em" }}
              ></span>
              <span className="text-xs" style={{ marginTop: "-10px" }}>
                Wallet
              </span>
            </Link>

            {!user && (
              <Link to="/login" className="flex flex-col items-center mx-2">
                <span
                  className="fas fa-sign-in main-text p-3 icon-hov"
                  style={{ fontSize: "1.5em" }}
                ></span>
                <span className="text-xs" style={{ marginTop: "-10px" }}>
                  Login
                </span>
              </Link>
            )}

            <button
              onClick={openModal}
              className="flex flex-col items-center mx-2"
            >
              <span
                className="fas fa-star main-text p-3 icon-hov"
                style={{ fontSize: "1.5em" }}
              ></span>
              <span
                className="text-xs"
                style={{ color: "#5C870C", marginTop: "-10px" }}
              >
                Feedback
              </span>
            </button>

            <Link to="/Scheduled" className="flex flex-col items-center mx-2">
              <span
                className="fa fa-calendar main-text p-3 icon-hov"
                style={{ fontSize: "1.5em" }}
              ></span>
              <span
                className="text-xs"
                style={{ marginTop: "-10px", textAlign: "center" }}
              >
                Scheduled Delivery
              </span>
            </Link>
          </div>
        </ul>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        <div className="flex justify-between items-center p-4">
          <Link to="/">
            <img alt="logo" className="w-40 h-auto" src="../logo2.png" />
          </Link>
          <div className="flex items-center">
            <Link to="/cart" className="mr-4">
              <span className="fa fa-shopping-bag text-2xl relative">
                <span className="absolute -top-1 -right-1 w-5 h-5 text-xs bg-[#5c870c] text-white rounded-full flex items-center justify-center">
                  {total}
                </span>
              </span>
            </Link>
            <button className="text-2xl" onClick={toggleMobileMenu}>
              {mobileMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
            </button>
          </div>
        </div>

        {mobileMenuOpen && (
          <div className="p-4">
            <div className="mb-4 relative" ref={searchRef}>
              <input
                className="border border-orange-400 w-full rounded-full pl-4 py-2 pr-12"
                type="text"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearchChange}
              />

              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 h-8 w-8 bg-[#5C870C] text-white rounded-full flex items-center justify-center"
                type="submit"
                onClick={() => {
                  if (!searchValue) {
                    scrollToTop1();
                    setMobileMenuOpen(!mobileMenuOpen);
                  } else {
                    scrollToTop2();
                    setMobileMenuOpen(!mobileMenuOpen);
                  }
                }}
              >
                <SearchOutlined />
              </button>

              {showDropdown && suggestions.length > 0 && (
                <div className="absolute w-full mt-1 border border-orange-400 rounded-lg bg-white shadow-lg max-h-48 overflow-y-auto z-10">
                  <ul className="list-none p-2">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion._id}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.productname}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="flex flex-wrap justify-between">
              {user && (
                <Link
                  to="/account"
                  className="w-1/3 flex flex-col items-center mb-4"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="fas fa-user text-2xl mb-1"></span>
                  <span className="text-xs">Account</span>
                </Link>
              )}
              <Link
                to="/wallet"
                className="w-1/3 flex flex-col items-center mb-4"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <span className="fas fa-wallet text-2xl mb-1"></span>
                <span className="text-xs">Wallet</span>
              </Link>
              {!user && (
                <Link
                  to="/login"
                  className="w-1/3 flex flex-col items-center mb-4"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="fas fa-sign-in text-2xl mb-1"></span>
                  <span className="text-xs">Login</span>
                </Link>
              )}
              <button
                onClick={openModal}
                className="w-1/3 flex flex-col items-center mb-4"
              >
                <span className="fas fa-star text-2xl mb-1"></span>
                <span className="text-xs text-[#5C870C]">Feedback</span>
              </button>
              <Link
                to="/Scheduled"
                className="w-1/3 flex flex-col items-center mb-4"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <span className="fa fa-calendar text-2xl mb-1"></span>
                <span className="text-xs text-center">Scheduled Delivery</span>
              </Link>
            </div>
          </div>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <FeedbackForm />
      </Modal>

      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <DeliveryLocationModal onClose={closeModal1} />
      </Modal>
    </div>
  );
};

export default Header;
