import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Form,
  DatePicker,
  Modal,
  message,
  Typography,
  Space,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";
import { FileSpreadsheet } from "lucide-react";
const { Title } = Typography;
const Couponlist = () => {
  const token = sessionStorage.getItem("token");

  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCoupon, setEditingCoupon] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getallpromocode",{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (res.status === 200) {
        setCoupons(res.data?.promocode);
        setFilteredCoupons(res.data?.promocode);
      }
    } catch (error) {
      console.error("Error fetching coupons:", error);
      message.error("Failed to fetch coupons");
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = coupons.filter((coupon) =>
      Object.values(coupon).some((val) =>
        val?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredCoupons(filtered);
  };

  const showModal = (coupon = null) => {
    setEditingCoupon(coupon);
    if (coupon) {
      form.setFieldsValue({
        ...coupon,
        startdate: moment(coupon.startdate),
        enddate: moment(coupon.enddate),
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingCoupon(null);
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      const data = {
        ...values,
        startdate: values.startdate.format("YYYY-MM-DD"),
        enddate: values.enddate.format("YYYY-MM-DD"),
      };

      let res;
      if (editingCoupon) {
        res = await axios.post(
          `https://parnetslink.xyz/api/admin/editpromocode/${editingCoupon._id}`,
          data ,{ headers: {
            Authorization: `Bearer ${token}`,
          },}
        );
      } else {
        res = await axios.post(
          "https://parnetslink.xyz/api/admin/addpromocode",
          data ,{ headers: {
            Authorization: `Bearer ${token}`,
          },}
        );
      }

      if (res.status === 200) {
        message.success(
          `Coupon ${editingCoupon ? "updated" : "added"} successfully`
        );
        fetchCoupons();
        handleCancel();
      }
    } catch (error) {
      console.error("Error saving coupon:", error);
      message.error("Failed to save coupon");
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.post(
        `https://parnetslink.xyz/api/admin/deletepromocode/${id}`,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (res.status === 200) {
        message.success("Coupon deleted successfully");
        fetchCoupons();
      }
    } catch (error) {
      console.error("Error deleting coupon:", error);
      message.error("Failed to delete coupon");
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredCoupons.map(
      ({ _id, __v, used, ...rest }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Coupons");
    XLSX.writeFile(workbook, "Coupons.xlsx");
  };

  const columns = [
    { title: "Name", dataIndex: "promocode", key: "promocode" },
    {
      title: "Discount (%)",
      dataIndex: "discountpercentage",
      key: "discountpercentage",
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      key: "startdate",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "enddate",
      key: "enddate",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => showModal(record)} />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id)}
            style={{ marginLeft: 8 }}
          />
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>

      <div
        style={{
          marginBottom: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
              <Title level={3}>Coupon Management</Title>
        <Space>
          <Input
            placeholder="Search coupons"
            onChange={(e) => handleSearch(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />

          <Button type="primary" onClick={exportToExcel}   icon={<FileSpreadsheet size={16} />}>
            Export to Excel
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          >
            Add Coupon
          </Button>
        </Space>
      </div>
      <Table columns={columns} dataSource={filteredCoupons} rowKey="_id" />
      <Modal
        title={editingCoupon ? "Edit Coupon" : "Add Coupon"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="promocode"
            label="Coupon Name"
            rules={[
              { required: true, message: "Please input the coupon name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="discountpercentage"
            label="Discount (%)"
            rules={[
              {
                required: true,
                message: "Please input the discount percentage!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="startdate"
            label="Start Date"
            rules={[
              { required: true, message: "Please select the start date!" },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="enddate"
            label="End Date"
            rules={[{ required: true, message: "Please select the end date!" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingCoupon ? "Update" : "Add"} Coupon
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Couponlist;
