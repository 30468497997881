import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Form,
  Rate,
  Modal,
  message,
  Typography,
  Space,
  Upload,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import * as XLSX from "xlsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FileSpreadsheet } from "lucide-react";

const { Title } = Typography;

const AddTestimonial = () => {
  const token = sessionStorage.getItem("token");

  const [testimonials, setTestimonials] = useState([]);
  const [filteredTestimonials, setFilteredTestimonials] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingTestimonial, setEditingTestimonial] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const res = await axios.get("https://parnetslink.xyz/api/admin/getTestimonials",{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      if (res.status === 200) {
        setTestimonials(res.data.testimonials);
        setFilteredTestimonials(res.data.testimonials);
      }
    } catch (error) {
      console.error("Error fetching testimonials:", error);
      message.error("Failed to fetch testimonials");
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = testimonials.filter((testimonial) =>
      Object.values(testimonial).some((val) =>
        val?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredTestimonials(filtered);
  };

  const showModal = (testimonial = null) => {
    setEditingTestimonial(testimonial);
    if (testimonial) {
      form.setFieldsValue(testimonial);
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingTestimonial(null);
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key === 'profileImage') {
          if (values[key] && values[key].fileList && values[key].fileList.length > 0) {
            formData.append(key, values[key].fileList[0].originFileObj);
          }
        } else {
          formData.append(key, values[key]);
        }
      });

      let res;
      if (editingTestimonial) {
        res = await axios.post(
          `https://parnetslink.xyz/api/admin/editTestimonial/${editingTestimonial._id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },{ headers: {
            Authorization: `Bearer ${token}`,
          },}
        );
      } else {
        res = await axios.post(
          "https://parnetslink.xyz/api/admin/addTestimonial",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },{ headers: {
            Authorization: `Bearer ${token}`,
          },}
        );
      }

      if (res.status === 200) {
        message.success(
          `Testimonial ${editingTestimonial ? "updated" : "added"} successfully`
        );
        fetchTestimonials();
        handleCancel();
      }
    } catch (error) {
      console.error("Error saving testimonial:", error);
      message.error("Failed to save testimonial");
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.post(
        `https://parnetslink.xyz/api/admin/deleteTestimonial/${id}`,{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (res.status === 200) {
        message.success("Testimonial deleted successfully");
        fetchTestimonials();
      }
    } catch (error) {
      console.error("Error deleting testimonial:", error);
      message.error("Failed to delete testimonial");
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredTestimonials.map(
      ({ _id, __v, profileImage, ...rest }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Testimonials");
    XLSX.writeFile(workbook, "Testimonials.xlsx");
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Profession", dataIndex: "profession", key: "profession" },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (rating) => <Rate disabled defaultValue={rating} />,
    },
    {
      title: "Profile Image",
      dataIndex: "profileImage",
      key: "profileImage",
      render: (image) => (
        <img
          src={`https://parnetslink.xyz/image/${image}`}
          alt="profile"
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => showModal(record)} />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title level={3}>Testimonial</Title>
        <Space>
          <Input
            placeholder="Search testimonials"
            onChange={(e) => handleSearch(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
          <Button type="primary"   icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>
            Export to Excel
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          >
            Add Testimonial
          </Button>
        </Space>
      </div>
      <Table columns={columns} dataSource={filteredTestimonials} rowKey="_id" />
      <Modal
        title={editingTestimonial ? "Edit Testimonial" : "Add Testimonial"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="profession"
            label="Profession"
            rules={[{ required: true, message: "Please input the profession!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true, message: "Please input the message!" }]}
          >
            <CKEditor
              editor={ClassicEditor}
              data={editingTestimonial?.message || ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldsValue({ message: data });
              }}
            />
          </Form.Item>
          <Form.Item
            name="rating"
            label="Rating"
            rules={[{ required: true, message: "Please rate!" }]}
          >
            <Rate />
          </Form.Item>
          <Form.Item name="profileImage" label="Profile Image">
          <Upload
            listType="picture"
            maxCount={1}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
        </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingTestimonial ? "Update" : "Add"} Testimonial
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddTestimonial;