import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Modal, message, Space, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Trash2, Edit, FileSpreadsheet } from 'lucide-react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const API_BASE_URL = 'https://parnetslink.xyz/api/admin';
const { Title } = Typography;

const AddText = () => {
  const token = sessionStorage.getItem("token");

  const [texts, setTexts] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingText, setEditingText] = useState(null);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [filteredTexts, setFilteredTexts] = useState([]);

  useEffect(() => {
    fetchTexts();
  }, []);

  useEffect(() => {
    setFilteredTexts(
      texts.filter((text) =>
        Object.values(text).some(
          (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [texts, searchText]);

  const fetchTexts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getText`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      setTexts(response.data?.Text || []);
    } catch (error) {
      message.error('Failed to fetch texts');
    }
  };

  const handleAddText = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/addText`, {
        text: values.text
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Text added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchTexts();
    } catch (error) {
      message.error('Failed to add text');
    }
  };

  const handleEditText = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/editText/${editingText._id}`, {
        text: values.text
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Text updated successfully');
      setIsEditModalVisible(false);
      form.resetFields();
      fetchTexts();
    } catch (error) {
      message.error('Failed to update text');
    }
  };

  const handleDeleteText = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/deletetext/${id}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Text deleted successfully');
      fetchTexts();
    } catch (error) {
      message.error('Failed to delete text');
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredTexts.map(({ _id, text }) => ({
      'Text': text,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Texts');
    XLSX.writeFile(workbook, 'texts.xlsx');
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serialNo',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      width:600
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<Edit color="blue" />}
            onClick={() => {
              setEditingText(record);
              setIsEditModalVisible(true);
              form.setFieldsValue({ text: record.text });
            }}
          />
          <Button
            type="text"
            icon={<Trash2 color="red" />}
            onClick={() => handleDeleteText(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Title level={3} className="title">Texts</Title>
        <Space>
          <Input
            placeholder="Search texts"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
            Add Text
          </Button>
          <Button  type="primary" icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>Export to Excel</Button>
        </Space>
      </div>

      <Table 
        columns={columns} 
        dataSource={filteredTexts} 
        rowKey="_id" 
        scroll={{ x: "max-content" }}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
      />

      <Modal
        text="Add Text"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddText} layout="vertical">
          <Form.Item
            name="text"
            label="Text"
            rules={[{ required: true, message: 'Please enter a text' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Text
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        text="Edit Text"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditText} layout="vertical">
          <Form.Item
            name="text"
            label="Text"
            rules={[{ required: true, message: 'Please enter a text' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Text
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddText;