import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/CartSlice";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "antd";

const Bestseller = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const cart = useSelector((state) => state.cart);
  const token = sessionStorage.getItem("token");
  const [bestseller, setbestseller] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [selectedWeight, setSelectedWeight] = useState({});

  const [itemQuantities, setItemQuantities] = useState({});

  const handleQuantityChange = (item, change) => {
    setItemQuantities(prev => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 1) + change, 1)
    }));
  };

  const handleAddToCart = (item) => {
    const weightToAdd = selectedWeight[item._id] || 
      (!user?.club ? item.weight[0] : item.Clubweight[0]);
    
    const quantity = itemQuantities[item._id] || 1;
    toast.info("Product added successfully")
    dispatch(addToCart({ 
      ...item, 
      selectedWeight: weightToAdd, 
      quantity 
    }));
  };
  const handleRemoveFromCart = (item) => {
    const weightToRemove = selectedWeight[item._id] || 
      (!user?.club ? item.weight[0] : item.Clubweight[0]);
    
    dispatch(removeFromCart({
      _id: item._id,
      selectedWeight: weightToRemove
    }));

    // Update the itemQuantities state
    setItemQuantities(prev => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 0) - 1, 0)
    }));
  };

  const getproducts = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/Getproductlist",{ headers: {
        Authorization: `Bearer ${token}`,
      },}
    );
    if (res.status === 200) {
      const activeProducts =  res.data.ProductList.filter(
        (e) =>
          e.bestsellerstatus === true &&
          e.status === "Active" &&
          e.category !== "Fruits & Vegetables" &&
          e.subscribe !== "Subscription"
      )
      setbestseller(activeProducts);
      const initialSelectedWeight = {};
      activeProducts.forEach((product) => {
        initialSelectedWeight[product._id] = !user?.club
          ? product.weight?.[0]
          : product.Clubweight?.[0];
      });
      setSelectedWeight(initialSelectedWeight);
    }
  };

  useEffect(() => {
    getproducts();
  }, []);

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedBestseller = chunkArray(bestseller, 6);

  const handleAddToCart1 = (item) => {
    const weightToAdd =
      selectedWeight || (!user?.club ? item.weight[0] : item.Clubweight[0]);
    dispatch(addToCart({ ...item, selectedWeight: weightToAdd }));
  };
  const scrollToTop = (val) => {
    navigation(`/shop-detail/${val?._id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="mt-4 md:mt-10">
      <ToastContainer />
      <div className="px-4 md:px-10">
        <h1
          className="text-2xl md:text-2xl lg:text-2xl font-bold text-center mb-6"
          style={{ color: "#45595b" }}
        >
          Bestseller Products
        </h1>
        <Carousel
          showArrows={true}
          showThumbs={false}
          showIndicators={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          showStatus={false}
        >
          {chunkedBestseller.map((productChunk, index) => (
            <div
              key={index}
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
            >
              {productChunk.map((val) => {
                const currentSelectedWeight = selectedWeight[val._id];
                const itemQuantity = itemQuantities[val._id] || 1;
                return (
                  <Badge.Ribbon text={val.tag} color="orange" key={val._id}>
                    <div className="flex flex-col justify-between items-center p-2 bg-[#f4f6f8] rounded-lg shadow-sm bg-white">
                      <div className="w-full h-24 sm:h-28 mb-2 sm:mb-4" style={{cursor:'pointer'}} onClick={() => scrollToTop(val)}>
                        {/* <Link to={`/shop-detail/${val._id}`}> */}
                          <img
                            className="w-full h-full object-contain rounded-md"
                            src={`https://parnetslink.xyz/product/${val.Photos[0].file}`}
                            alt={val.productname}
                          />
                        {/* </Link> */}
                      </div>
                      <div onClick={() => scrollToTop(val)} style={{cursor:'pointer'}}>
                        <h5 className="text-xs sm:text-sm font-semibold mb-2 text-[#45595b]">
                          {val.productname.slice(0, 20)}
                        </h5>
                      </div>
                      <select
                      name="option[504]"
                      id="input-option504"
                      className="form-control1 weight resp-select-box"
                      style={{
                        maxWidth: "200px",
                        fontSize: "12px",
                        border: "1px solid #88ac46",
                      }}
                      onChange={(e) => {
                        const selectedWeightData = JSON.parse(e.target.value);

                        const selectedDiscount =
                          selectedWeightData.discountType === "discount"
                            ? val.discount
                            : val.clubdiscount;
                        setSelectedWeight((prev) => ({
                          ...prev,
                          [val._id]: {
                            ...selectedWeightData,
                            discount: selectedDiscount,
                            discountType: selectedWeightData.discountType,
                          },
                        }));
                      }}
                      value={JSON.stringify(currentSelectedWeight)}
                    >
                      {!user?.club &&
                        val.weight?.map((e) => (
                          <option
                            key={`weight-${e.id}`}
                            value={JSON.stringify({
                              ...e,
                              discount: val.discount,
                              discountType: "discount",
                            })}
                            style={{ fontSize: "12px" }}
                          >
                            {e.value} {e.units} - Rs{" "}
                            {(e.price - e.price * (val.discount / 100)).toFixed(
                              2
                            )}
                          </option>
                        ))}

                      {val.Clubweight?.map((e) => (
                        <option
                          key={`clubweight-${e.id}`}
                          value={JSON.stringify({
                            ...e,
                            discount: val.clubdiscount,
                            discountType: "clubdiscount",
                          })}
                          disabled={!user || user.club === false}
                          style={{
                            color:
                              !user || user.club === false ? "#aaa" : "inherit",
                            fontSize: "12px",
                          }}
                        >
                          {e.value} {e.units} - Rs{" "}
                          {(
                            e.price -
                            e.price * (val.clubdiscount / 100)
                          ).toFixed(2)}
                          {!user?.club && " (Club Members Only)"}
                        </option>
                      ))}
                    </select>
                      <div className="flex-grow flex flex-col sm:flex-row justify-center items-center mt-2 sm:mt-3 w-full">
                        <div className="flex items-center mb-2 sm:mb-0 sm:mr-2">
                          <button
                            className="px-2 py-1 text-xs sm:text-sm font-semibold"
                            style={{
                              backgroundColor: "#ffb524",
                              color: "#fff",
                              borderRadius: "50%",
                            }}
                            onClick={() => handleRemoveFromCart(val)}
                          >
                            -
                          </button>
                          <span className="px-2 sm:px-3">{itemQuantity}</span>
                          <button
                            className="px-2 py-1 text-xs sm:text-sm font-semibold"
                            style={{
                              backgroundColor: "#ffb524",
                              color: "#fff",
                              borderRadius: "50%",
                            }}
                            onClick={() => handleQuantityChange(val, 1)}
                          >
                            +
                          </button>
                        </div>
                        <div className="flex items-center border rounded-full border-orange-400 p-1 sm:p-2">
                          <button
                            onClick={() => handleAddToCart(val)}
                            className="text-xs sm:text-sm font-semibold text-[#5C870C]"
                          >
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  </Badge.Ribbon>
                );
              })}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Bestseller;