import React, { useEffect, useState } from "react";

import { message, Table, Input, Button, Typography, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@material-ui/icons";
import * as XLSX from "xlsx";
import {
  deleteAEnquiry,
  getEnquiries,
  resetState,
  updateAEnquiry,
} from "../features/enquiry/enquirySlice";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import axios from "axios";
import { FileSpreadsheet } from "lucide-react";
const { Title } = Typography;
const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
];

const Enquiries = () => {
  const [data1, setdata1] = useState([]);
  const token = sessionStorage.getItem("token");
  const getfeedbacklist = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/getallfeedback",{ headers: {
        Authorization: `Bearer ${token}`,
      },}
    );
    if ((res.status = 200)) {
      setdata1(res.data);
    }
  };
  useEffect(() => {
    getfeedbacklist();
  }, []);
  const transformData = (data1) => {
    return data1?.map((feedback, i) => {
      return {
        key: i + 1,
        name: feedback.name,
        email: feedback.email,
        mobile: feedback.mobile,
        message: feedback.message,
      };
    });
  };
  const [filteredData, setFilteredData] = useState([]);
  const [data2, setData2] = useState([]);
  useEffect(() => {
    if (data1 && data1.length > 0) {
      const transformedData = transformData(data1);
      setData2(transformedData);
      setFilteredData(transformedData);
    }
  }, [data1]);
  const exportToExcel = () => {
    const dataToExport = data2.map(({ _id, __v, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback");
    XLSX.writeFile(workbook, "Feedback.xlsx");
  };
  const ExportToExcelButton = ({ dataSource, columns, filename }) => {
    const exportToExcel = () => {
      const dataToExport = dataSource.map(
        ({ RegisteredDate, ...rest }) => rest
      );
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <Button
        type="primary"
        onClick={exportToExcel}
        style={{
          margin: "10px",
          alignSelf: "flex-end",
          backgroundColor: "#1677ff",
          color: "white",
        }}
      >
        Export to Excel
      </Button>
    );
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handles = (value) => {
    setSearchTerm(value);

    const filteredDataSource = data2.filter((record) => {
      return Object.values(record).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredDataSource);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    setFilteredData(data2);
  };

  const searchInput = (
    <Input
      placeholder="Search"
      value={searchTerm}
      style={{ width: "90%" }}
      onChange={(e) => handles(e.target.value)}
      suffix={
        searchTerm && (
          <span
            onClick={onClearSearch}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Clear
          </span>
        )
      }
      prefix={<SearchOutlined />}
    />
  );

  const pageSize = 5;
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title level={3}>Feedback</Title>
        <Space>
          <Input
            placeholder="Search"
            onChange={(e) => handles(e.target.value)}
            style={{ width: 200 }}
            prefix={<SearchOutlined />}
          />

          <Button type="primary"   icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>
            Export to Excel
          </Button>
        </Space>
      </div>
      <Table
        className="custom-table"
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        columns={columns}
        dataSource={filteredData}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
      />
    </div>
  );
};

export default Enquiries;
