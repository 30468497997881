import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, Table, Modal, message, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Trash2 } from 'lucide-react';
import axios from 'axios';
const { Title } = Typography;
const API_BASE_URL = 'https://parnetslink.xyz/api/admin';

const AddDiscountBanner = () => {
  const token = sessionStorage.getItem("token");

  const [banners, setBanners] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getalldiscountbanner`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBanners(response.data.banner);
    } catch (error) {
      message.error('Failed to fetch banners');
    }
  };

  const handleAddBanner = async (values) => {
    try {
      const formData = new FormData();
      formData.append('banner', values.banner.file);
      formData.append('link', values.type);

      await axios.post(`${API_BASE_URL}/adddiscountbanner`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Banner added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchBanners();
    } catch (error) {
      message.error('Failed to add banner');
    }
  };

  const handleDeleteBanner = async (id) => {
    try {
      await axios.post(`${API_BASE_URL}/deletediscountbanner/${id}`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Banner deleted successfully');
      fetchBanners();
    } catch (error) {
      message.error('Failed to delete banner');
    }
  };

  const columns = [
    { title: 'Serial No.', dataIndex: 'serialNo', key: 'serialNo' },
    { title: 'Link', dataIndex: 'link', key: 'link' },
    {
      title: 'Banner',
      dataIndex: 'banner',
      key: 'banner',
      render: (banner) => (
        <img src={`https://parnetslink.xyz/image/${banner}`} alt="Banner" style={{ width: 200,height:100 }} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type="text"
          icon={<Trash2 color="red" />}
          onClick={() => handleDeleteBanner(record._id)}
        />
      ),
    },
  ];

  const dataSource = banners.map((banner, index) => ({
    key: banner._id,
    serialNo: index + 1,
    link: banner.link,
    banner: banner.banner,
    _id: banner._id,
  }));

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
      <Title level={3} className="title">Discount Banners</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
          Add Banner
        </Button>
      </div>

      <Table columns={columns} dataSource={dataSource}  pagination={{
              pageSize: 5,
              showSizeChanger: true, 
            }}
            scroll={{ x: "max-content" }}
            components={{
              header: {
                cell: (props) => (
                  <th
                    {...props}
                    style={{
                      ...props.style,
                      background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                      color: "white",
                    }}
                  />
                ),
              },
            }}/>

      <Modal
        title="Add Banner"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddBanner} layout="vertical">
          <Form.Item
            name="banner"
            label="Banner Image"
            rules={[{ required: true, message: 'Please upload a banner image' }]}
          >
            <Upload accept="image/*" listType="picture-card" maxCount={1} beforeUpload={() => false}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item
            name="type"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Banner
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddDiscountBanner;