import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createCoupon,
  getACoupon,
  resetState,
  updateACoupon,
} from "../features/coupon/couponSlice";
import axios from "axios";


const AddCoupon = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getCouponId = location.pathname.split("/")[3];
  useEffect(() => {
    if (getCouponId !== undefined) {
      dispatch(getACoupon(getCouponId));
    } else {
      dispatch(resetState());
    }
  }, [getCouponId]);
  const [enddate, setenddate] = useState("");
  const [coupon, setcoupon] = useState("");
const token = sessionStorage.getItem("token");

  const [startdate, setstartdate] = useState("");
  const [discount, setdiscount] = useState();

  const Register1 = async (e) => {
    e.preventDefault();
    if(!startdate ||  !coupon || !enddate || !discount){
      toast.info("All fields are required")
    }else {
      

      try {
        const config = {
          url: "/addpromocode",
          method: "post",
          baseURL: "https://parnetslink.xyz/api/admin",

          headers: { "content-type": "application/json" },
          
            headers: {
              Authorization: `Bearer ${token}`,
            },
          
          data: {startdate: startdate,
          promocode: coupon,
          enddate: enddate,
          discountpercentage: discount},
        };
        await axios(config).then(function (response) {
          if (response.status === 200) {
            toast.info("Coupon added successfully")
            setenddate("")
            setdiscount()
            setstartdate("")
            setcoupon("")
          }
        });
      } catch (error) {
        console.error(error);
        
      }
    }
  };

  const Editcoupon = async (e) => {


      

      try {
        const config = {
          url: `/editpromocode/${getCouponId}`,
          method: "post",
          baseURL: "https://parnetslink.xyz/api/admin",

          headers: { "content-type": "application/json" },
          
            headers: {
              Authorization: `Bearer ${token}`,
            },
          
          data: {startdate: startdate,
          promocode: coupon,
          enddate: enddate,
          discountpercentage: discount},
        };
        await axios(config).then(function (response) {
          if (response.status === 200) {
            toast.info("Coupon updated successfully")

            setenddate("")
            setdiscount()
            setstartdate("")
            setcoupon("")
            navigate("/admindashboard/coupon-list")
          }
        });
      } catch (error) {
        console.error(error);
        
      
    }
  };
  
  return (
    <div>
       <ToastContainer />
      <h3 className="mb-4 title">
      {getCouponId !== undefined ? "Edit" : "Add"} Coupon
      </h3>
      <div>
     
          <CustomInput
            type="text"
            name="name"
            onChng={(e)=>setcoupon(e.target.value)}
          
            val={coupon}
            label="Enter Coupon Name"
            id="name"
          />
       
           <CustomInput
            type="date"
            name="expiry"
            onChng={(e)=>setstartdate(e.target.value)}
          
            val={startdate}
            label="Enter Start Date"
            id="date"
          />
          <CustomInput
            type="date"
            name="expiry"
            onChng={(e)=>setenddate(e.target.value)}
          
            val={enddate}
            label="Enter End Date"
            id="date"
          />
         
          <CustomInput
            type="number"
            name="discount"
            onChng={(e)=>setdiscount(e.target.value)}
          
            val={discount}
            label="Enter Discount (%)"
            id="discount"
          />
      
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
            onClick={getCouponId !== undefined ? Editcoupon : Register1}
          >
           {getCouponId !== undefined ? "Edit" : "Add"} Coupon
          </button>
    
      </div>
    </div>
  );
};

export default AddCoupon;
