import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, Table, Modal, message, Space, Select, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Trash2, Edit, FileSpreadsheet } from 'lucide-react';
import axios from 'axios';
import * as XLSX from 'xlsx';
const { Title } = Typography;
const API_BASE_URL = 'https://parnetslink.xyz/api/admin';

const AddSubCategory = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingSubCategory, setEditingSubCategory] = useState(null);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const subcategoryCount = subCategories.length;
  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
  }, []);

  useEffect(() => {
    setFilteredSubCategories(
      subCategories.filter((subCategory) =>
        Object.values(subCategory).some(
          (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [subCategories, searchText]);
  const token = sessionStorage.getItem("token");

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getSubcategory`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      setSubCategories(response.data.Subcategory);
    } catch (error) {
      message.error('Failed to fetch sub-categories');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getCategory`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      setCategories(response.data.category);
    } catch (error) {
      message.error('Failed to fetch categories');
    }
  };

  const handleAddSubCategory = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/addSubcategory`, {
        categoryName: values.categoryName,
        SubcategoryName: values.SubcategoryName,
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Sub-category added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchSubCategories();
    } catch (error) {
      message.error('Failed to add sub-category');
    }
  };

  const handleEditSubCategory = async (values) => {
    try {
      await axios.post(`${API_BASE_URL}/editSubcategory/${editingSubCategory._id}`, {
        categoryName: values.categoryName,
        SubcategoryName: values.SubcategoryName,
      },{ headers: {
        Authorization: `Bearer ${token}`,
      },});

      message.success('Sub-category updated successfully');
      setIsEditModalVisible(false);
      form.resetFields();
      fetchSubCategories();
    } catch (error) {
      message.error('Failed to update sub-category');
    }
  };

  const handleDeleteSubCategory = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/deleteSubcategory/${id}`,{headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Sub-category deleted successfully');
      fetchSubCategories();
    } catch (error) {
      message.error('Failed to delete sub-category');
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredSubCategories.map(({ _id, categoryName, SubcategoryName }) => ({
      'Category Name': categoryName,
      'Sub-Category Name': SubcategoryName,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sub-Categories');
    XLSX.writeFile(workbook, 'sub-categories.xlsx');
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serialNo',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Category Name',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },
    {
      title: `Sub-Category Name (${subcategoryCount})`,
      dataIndex: 'SubcategoryName',
      key: 'SubcategoryName',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<Edit color="blue" />}
            onClick={() => {
              setEditingSubCategory(record);
              setIsEditModalVisible(true);
              form.setFieldsValue(record);
            }}
          />
          <Button
            type="text"
            icon={<Trash2 color="red" />}
            onClick={() => handleDeleteSubCategory(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
      <Title level={3} className="title">Sub-Categories</Title>

        <Space>
          <Input
            placeholder="Search sub-categories"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
            Add Sub-Category
          </Button>
          <Button  type="primary"  icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>Export to Excel</Button>
        </Space>
      </div>

      <Table columns={columns} dataSource={filteredSubCategories} rowKey="_id" pagination={{
              pageSize: 5,
              showSizeChanger: true, 
            }}
            scroll={{ x: "max-content" }}
            components={{
              header: {
                cell: (props) => (
                  <th
                    {...props}
                    style={{
                      ...props.style,
                      background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                      color: "white",
                    }}
                  />
                ),}}}/>

      <Modal
        title="Add Sub-Category"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddSubCategory} layout="vertical">
          <Form.Item
            name="categoryName"
            label="Category Name"
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select placeholder="Select a category">
              {categories.map((category) => (
                <Select.Option key={category._id} value={category.categoryName}>
                  {category.categoryName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="SubcategoryName"
            label="Sub-Category Name"
            rules={[{ required: true, message: 'Please enter a sub-category name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Sub-Category
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Sub-Category"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditSubCategory} layout="vertical">
          <Form.Item
            name="categoryName"
            label="Category Name"
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select placeholder="Select a category">
              {categories.map((category) => (
                <Select.Option key={category._id} value={category.categoryName}>
                  {category.categoryName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="SubcategoryName"
            label="Sub-Category Name"
            rules={[{ required: true, message: 'Please enter a sub-category name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Sub-Category
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddSubCategory;