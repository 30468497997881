import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { clearCart } from "../redux/CartSlice";
import { clearCart1 } from "../redux/CartSlice1";

import { RiDeleteBack2Line } from "react-icons/ri";
import {
  FaShippingFast,
  FaCreditCard,
  FaPercent,
  FaComment,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
const token = sessionStorage.getItem("token");
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    fullName: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    number: "",
    email: "",
  });
  const [coupon, setCoupon] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const items = useSelector((state) => state.cart.items);
  const items1 = useSelector((state) => state.cart1.items1);
  const subtotal = useSelector((state) => state.cart.subtotal);
  const location = useLocation();
  const { orderType } = location.state || {};
  console.log(orderType);
  const total = useSelector((state) => state.cart.total);
  const subtotal1 = useSelector((state) => state.cart1.subtotal1);
  const total1 = useSelector((state) => state.cart1.total1);
  useEffect(() => {
    if (user) {
      getAddresses();
    }
  }, [user]);

  const getAddresses = async () => {
    try {
      const res = await axios.get(
        `https://parnetslink.xyz/api/user/address/${user._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setAddresses(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };
  

  const handleAddressChange = (e) => {
    setSelectedAddress(JSON.parse(e.target.value));
  };

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
    if (name === "fullName") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid name: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid name: Minimum 3 characters");
        return;
      }
    }
    if (name === "city") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid city: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid city: Minimum 3 characters");
        return;
      }
    }
    if (name === "street" && value.length < 3) {
      console.log("Invalid street: Minimum 3 characters");
      return;
    }
    if (name === "state") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid state: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid state: Minimum 3 characters");
        return;
      }
    }
    if (name === "country") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.log("Invalid country: Only alphabets are allowed");
        return;
      }
      if (value.length < 3) {
        console.log("Invalid country: Minimum 3 characters");
        return;
      }
    }
    if (name === "pincode" && (!/^\d*$/.test(value) || value.length > 6)) {
      console.log("Invalid pincode: Must be a number with max 6 digits");
      return;
    }
    if (name === "mobile" && (!/^\d*$/.test(value) || value.length > 10)) {
      console.log("Invalid mobile: Must be a number with max 10 digits");
      return;
    }
  };
  

  const addAddress = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://parnetslink.xyz/api/user/addaddress",
        {
          userId: user._id,
          ...newAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        toast.info("Address added successfully");
        getAddresses(); // Fetch the updated addresses list
        setNewAddress({
          fullName: "",
          street: "",
          city: "",
          state: "",
          country: "",
          pincode: "",
          number: "",
          email: "",
        });
      }
    } catch (error) {
      toast.info(error.response?.data?.error || "Error adding address");
    }
  };
  

  const deleteAddress = async (id) => {
    try {
      const res = await axios.delete(
        `https://parnetslink.xyz/api/user/deleteaddress/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        toast.info("Address deleted successfully");
        getAddresses();
      }
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  const applyCoupon = async () => {
    try {
      const res = await axios.put(
        "https://parnetslink.xyz/api/admin/getCouponBycode",{ headers: {
          Authorization: `Bearer ${token}`,
        },},
        {
          promocode: coupon,
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setCouponDiscount(res.data.success.discountpercentage);
        toast.info("Coupon applied successfully");
      }
    } catch (error) {
      toast.info(error.response.data.error);
    }
  };

  const placeOrder = async () => {
    if (!selectedAddress) {
      toast.info("Please select a shipping address");
      return;
    }
    if (!paymentMethod) {
      toast.info("Please select a payment method");
      return;
    }

    const orderData = {
      userId: user._id,
      items:
        orderType !== "scheduled"
          ? items.map((item) => ({
              productId: item._id,
              name: item.productname,
              quantity: item.quantity,
              price:
                item.selectedWeight?.price -
                item.selectedWeight?.price *
                  ((item.selectedWeight?.discount
                    ? item.selectedWeight?.discount
                    : item?.discount) /
                    100),
              selecteddiscount: item.selectedWeight?.discount
                ? item.selectedWeight?.discount
                : item?.discount,
              weight: `${item.selectedWeight.value}${item.selectedWeight.units}`,
            }))
          : items1.map((item) => ({
              productId: item._id,
              name: item.productname,
              quantity: item.quantity,
              price:
                item.selectedWeight?.price -
                item.selectedWeight?.price *
                  ((item.selectedWeight?.discount
                    ? item.selectedWeight?.discount
                    : item?.discount) /
                    100),
              selecteddiscount: item.selectedWeight?.discount
                ? item.selectedWeight?.discount
                : item?.discount,
              weight: `${item.selectedWeight.value}${item.selectedWeight.units}`,
            })),
      totalAmount: (
        total -
        total * (couponDiscount / 100) +
        (total >= 1000 ? 0 : 40)
      ).toFixed(2),
      discount: couponDiscount,
      shippingAddress: selectedAddress,
      paymentMethod: paymentMethod,
      additionalInstructions: additionalInstructions,
      orderType: orderType,
    };

    try {
      const res = await axios.post(
        "https://parnetslink.xyz/api/user/createOrder",
        orderData,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        toast.info("Order placed successfully!");
        dispatch(clearCart());
        dispatch(clearCart1());
        setTimeout(() => {
          window.location.assign("/");
        }, 2000);
      }
    } catch (error) {
      console.error("Error placing order:", error);
      toast.info("An error occurred while placing the order.");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50">
      <ToastContainer />
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">
        Checkout
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-6 flex items-center">
            <FaShippingFast className="mr-2 text-[#5c870c]" />
            Shipping Address
          </h2>
          <select
            className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
            value={JSON.stringify(selectedAddress)}
            onChange={handleAddressChange}
          >
            <option value="">Select an address</option>

            <option key={user._id} value={JSON.stringify(user)}>
              {user.name}, {user.address1}, {user.address2}
            </option>
            {addresses.map((address) => (
              <option key={address._id} value={JSON.stringify(address)}>
                {address.fullName}, {address.street}, {address.city}
              </option>
            ))}
          </select>

          {selectedAddress && (
            <div className="bg-gray-100 p-4 rounded-md mb-4">
              <h3 className="font-semibold mb-2 text-gray-700">
                Selected Address
              </h3>
              <p>
                {selectedAddress.name
                  ? selectedAddress.name
                  : selectedAddress.fullName}
              </p>
              <p>
                {selectedAddress.address1
                  ? selectedAddress.address1
                  : selectedAddress.street}
              </p>
              <p>
                {selectedAddress.address2
                  ? selectedAddress.address2
                  : selectedAddress.city}
                , {selectedAddress.state} {selectedAddress.pincode}
              </p>
              <p>{selectedAddress.country}</p>
              <p>
                Phone:{" "}
                {selectedAddress.mobile
                  ? selectedAddress.mobile
                  : selectedAddress.number}
              </p>
              <p>Email: {selectedAddress.email}</p>
            </div>
          )}

          <div className="mb-4">
            <label className="flex items-center text-gray-700">
              <input
                type="checkbox"
                checked={shipToDifferentAddress}
                onChange={() =>
                  setShipToDifferentAddress(!shipToDifferentAddress)
                }
                className="mr-2 form-checkbox h-5 w-5 text-[#5c870c]"
              />
              Ship to a different address?
            </label>
          </div>

          {shipToDifferentAddress && (
            <form onSubmit={addAddress} className="space-y-4">
              <input
                type="text"
                name="fullName"
                value={newAddress.fullName}
                onChange={handleNewAddressChange}
                placeholder="Full Name"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <input
                type="text"
                name="street"
                value={newAddress.street}
                onChange={handleNewAddressChange}
                placeholder="Street Address"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="city"
                  value={newAddress.city}
                  onChange={handleNewAddressChange}
                  placeholder="City"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
                <input
                  type="text"
                  name="state"
                  value={newAddress.state}
                  onChange={handleNewAddressChange}
                  placeholder="State"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="country"
                  value={newAddress.country}
                  onChange={handleNewAddressChange}
                  placeholder="Country"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
                <input
                  type="text"
                  name="pincode"
                  value={newAddress.pincode}
                  onChange={handleNewAddressChange}
                  placeholder="Pincode"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                  required
                />
              </div>
              <input
                type="tel"
                name="number"
                value={newAddress.number}
                onChange={handleNewAddressChange}
                placeholder="Phone Number"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <input
                type="email"
                name="email"
                value={newAddress.email}
                onChange={handleNewAddressChange}
                placeholder="Email"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
                required
              />
              <button
                type="submit"
                className="w-full bg-[#5c870c] text-white p-2 rounded-md hover:bg-[#5c870c] transition duration-300"
              >
                Add New Address
              </button>
            </form>
          )}
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-6">Order Summary</h2>
          <div className="bg-gray-100 p-4 rounded-md">
            {orderType !== "scheduled"
              ? items.map((item) => (
                  <div
                    key={item._id}
                    className="flex justify-between items-center mb-2 text-gray-700"
                  >
                    <span>
                      {item.productname} x {item.quantity}
                    </span>
                    <span>
                      ₹
                      {(
                        (item.selectedWeight?.price -
                          item.selectedWeight?.price *
                            ((item.selectedWeight?.discount
                              ? item.selectedWeight?.discount
                              : item.discount) /
                              100)) *
                        item.quantity
                      ).toFixed(2)}
                    </span>
                  </div>
                ))
              : items1.map((item) => (
                  <div
                    key={item._id}
                    className="flex justify-between items-center mb-2 text-gray-700"
                  >
                    <span>
                      {item.productname} x {item.quantity}
                    </span>
                    <span>
                      ₹
                      {(
                        (item.selectedWeight?.price -
                          item.selectedWeight?.price *
                            ((item.selectedWeight?.discount
                              ? item.selectedWeight?.discount
                              : item.discount) /
                              100)) *
                        item.quantity
                      ).toFixed(2)}
                    </span>
                  </div>
                ))}
            <div className="border-t border-gray-300 pt-4 mt-4">
              <div className="flex justify-between text-gray-700">
                <span>Subtotal</span>
                <span>
                  ₹
                  {(orderType !== "scheduled" ? subtotal : subtotal1).toFixed(
                    2
                  )}
                </span>
              </div>
              <div className="flex justify-between text-gray-700">
                <span>Shipping</span>
                <span>
                  ₹
                  {(orderType !== "scheduled" ? total : total1) >= 1000
                    ? 0
                    : 40}
                </span>
              </div>
              {couponDiscount > 0 && (
                <div className="flex justify-between text-[#5c870c]">
                  <span>Coupon Discount</span>
                  <span>
                    -₹
                    {(orderType !== "scheduled"
                      ? subtotal
                      : subtotal1 * (couponDiscount / 100)
                    ).toFixed(2)}
                  </span>
                </div>
              )}
              <div className="flex justify-between font-bold mt-4 text-lg">
                <span>Total</span>
                <span>
                  ₹
                  {(
                    (orderType !== "scheduled" ? total : total1) -
                    (orderType !== "scheduled" ? total : total1) *
                      (couponDiscount / 100) +
                    (total >= 1000 ? 0 : 40)
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="flex items-center mb-2">
              <FaPercent className="mr-2 text-[#5c870c]" />
              <h3 className="font-semibold text-gray-700">Apply Coupon</h3>
            </div>
            <div className="flex">
              <input
                type="text"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder="Enter coupon code"
                className="flex-grow p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-[#5c870c]"
              />
              <button
                onClick={applyCoupon}
                className="bg-[#5c870c] text-white p-2 rounded-r-md hover:bg-[#5c870c] transition duration-300"
              >
                Apply
              </button>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex items-center mb-2">
              <FaComment className="mr-2 text-[#5c870c]" />
              <h3 className="font-semibold text-gray-700">
                Additional Instructions
              </h3>
            </div>
            <textarea
              value={additionalInstructions}
              onChange={(e) => setAdditionalInstructions(e.target.value)}
              placeholder="Enter any special instructions or notes for your order"
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5c870c] h-24"
            />
          </div>
          <div className="mt-6">
            <div className="flex items-center mb-2">
              <FaCreditCard className="mr-2 text-[#5c870c]" />
              <h3 className="font-semibold text-gray-700">Payment Method</h3>
            </div>
            <div className="space-y-2">
              <label className="flex items-center p-2 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50 transition duration-300">
                <input
                  type="radio"
                  value="Cash On Delivery"
                  checked={paymentMethod === "Cash On Delivery"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mr-2 form-radio h-5 w-5 text-[#5c870c]"
                />
                Cash On Delivery
              </label>
              <label className="flex items-center p-2 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50 transition duration-300">
                <input
                  type="radio"
                  value="Online Payment"
                  checked={paymentMethod === "Online Payment"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mr-2 form-radio h-5 w-5 text-[#5c870c]"
                />
                Online Payment
              </label>
            </div>
          </div>

          <button
            onClick={placeOrder}
            className="bg-[#5c870c] text-white p-2 rounded-md mt-6 hover:bg-[#5c870c] transition duration-300 text-lg font-semibold"
          >
            Place Order
          </button>
        </div>
      </div>

      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Address List</h2>
            {addresses.map((address) => (
              <div
                key={address._id}
                className="mb-2 flex justify-between items-center"
              >
                <span>
                  {address.fullName}, {address.city}
                </span>
                <button
                  onClick={() => deleteAddress(address._id)}
                  className="text-red-500"
                >
                  <RiDeleteBack2Line />
                </button>
              </div>
            ))}
            <button
              onClick={() => setIsModalVisible(false)}
              className="mt-4 bg-gray-300 text-black p-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
