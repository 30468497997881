import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import { addToCart, removeFromCart } from "../redux/CartSlice";
import { Box, Card, CardContent, Chip, Paper, Tab, Tabs } from "@mui/material";
import { ShoppingCart, Calendar, Plus, Minus, Tag } from "lucide-react";
import { addToCart1, removeFromCart1 } from "../redux/CartSlice1";
import { Badge } from "antd";

const ShopDetail = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const params = useParams();
  const cart = useSelector((state) => state.cart);
  const user = JSON.parse(sessionStorage.getItem("user"));
const token = sessionStorage.getItem("token");
  const [product, setProduct] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    getProducts();
  }, []);
  const [data, setData] = useState([]);
  const getProducts = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/Getproductlist",{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        const activeProducts = res.data.ProductList.filter(
          (item) => item.status === "Active"
        );
        setProduct(activeProducts);
        setData(activeProducts);
        const initialSelectedWeight = {};
        activeProducts.forEach((product) => {
          initialSelectedWeight[product._id] = !user?.club
            ? product.weight?.[0]
            : product.Clubweight?.[0];
        });
        setSelectedWeight(initialSelectedWeight);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const initializeWeights = (products) => {
    const weights = {};
    products.forEach((product) => {
      weights[product._id] = !user?.club
        ? product.weight?.[0]
        : product.Clubweight?.[0];
    });
    setSelectedWeight(weights);
  };
  const [itemQuantities, setItemQuantities] = useState({});
  const item = product?.find((items) => items?._id === params.id);
  const currentSelectedWeight1 = selectedWeight[item?._id];
  const itemQuantity = itemQuantities[item?._id] || 1;

  useEffect(() => {
    if (item?.Photos?.length > 0) {
      setSelectedImage(item.Photos[0]?.file);
    }
  }, [item]);

  const handleQuantityChange = (item, change) => {
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 1) + change, 1),
    }));
  };

  const handleAddToCart = (item) => {
    const weightToAdd =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    const quantity = itemQuantities[item._id] || 1;
    toast.info("Product added successfully")
    dispatch(
      addToCart({
        ...item,
        selectedWeight: weightToAdd,
        quantity,
      })
    );
  };
  const handleRemoveFromCart = (item) => {
    const weightToRemove =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    dispatch(
      removeFromCart({
        _id: item._id,
        selectedWeight: weightToRemove,
      })
    );

    // Update the itemQuantities state
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 0) - 1, 0),
    }));
  };
  const handleAddToCart1 = (item) => {
    const weightToAdd =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);
    dispatch(addToCart1({ ...item, selectedWeight: weightToAdd }));
  };
  const scrollToTop = (val) => {
    navigation(`/shop-detail/${val?._id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleSubscribe = (product) => {
    if (!user) {
      toast.info("Please login to subscribe");
      return;
    }
    navigation(`/subscribe/${product._id}`, {
      state: { selectedWeight: selectedWeight[product._id] },
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!item) return null;

  return (
    <div className="max-w-7xl mx-auto p-4">
      <ToastContainer />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Image Gallery */}
        <div className="space-y-2">
          <Card className="overflow-hidden">
            <div className="relative bg-white">
              <img
                src={`https://parnetslink.xyz/product/${selectedImage}`}
                alt={item.productname}
                className="object-contain"
                style={{ height: "300px", width: "100%", margin: "10px" }}
              />
              {item.tag && (
                <span
                  className="absolute top-4 left-4 text-white px-3 py-1 rounded-full text-sm flex items-center gap-1"
                  style={{ backgroundColor: "#5C870C" }}
                >
                  {/* <Tag size={14} /> */}
                  {item.tag}
                </span>
              )}
            </div>
          </Card>

          <div className="flex gap-2 overflow-x-auto pb-2">
            {item.Photos.map((photo, index) => (
              <button
                key={index}
                onClick={() => setSelectedImage(photo.file)}
                className={`flex-shrink-0 w-16 h-16 rounded-lg overflow-hidden border-2 transition-all
                  ${
                    selectedImage === photo.file
                      ? "border-primary opacity-100"
                      : "border-transparent opacity-60 hover:opacity-100"
                  }`}
              >
                <img
                  src={`https://parnetslink.xyz/product/${photo.file}`}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </button>
            ))}
          </div>
        </div>

        {/* Product Info */}
        <div className="space-y-6">
          <div>
            <h1 className="text-2xl font-semibold mb-2">{item.productname}</h1>
            <div className="flex gap-2">
              <Chip
                label={"Category : " + item.category}
                sx={{ mr: 1, mb: 1 }}
                style={{ backgroundColor: "white" }}
              />
              <Chip
                label={"Sub-Category : " + item.subcategory}
                sx={{ mb: 1 }}
                style={{ backgroundColor: "white" }}
              />
            </div>
          </div>

          {/* <div className="flex items-baseline gap-3">
            <span className="text-2xl font-bold" style={{ color: "#5C870C" }}>
              ₹{item.offerPrice}
            </span>
            <span className="text-sm text-gray-500 line-through">
              ₹{item.price}
            </span>
          </div> */}
           <div className="flex gap-2">
          <select
            name="option[504]"
            id="input-option504"
            className="w-full p-2 border rounded-lg bg-background"
            style={{ maxWidth: "200px" }}
            onChange={(e) => {
              const selectedWeightData = JSON.parse(e.target.value);

              const selectedDiscount =
                selectedWeightData.discountType === "discount"
                  ? item.discount
                  : item.clubdiscount;
              setSelectedWeight((prev) => ({
                ...prev,
                [item._id]: {
                  ...selectedWeightData,
                  discount: selectedDiscount,
                  discountType: selectedWeightData.discountType,
                },
              }));
            }}
            value={JSON.stringify(currentSelectedWeight1)}
          >
            {!user?.club &&
              item.weight?.map((e) => (
                <option
                  key={`weight-${e.id}`}
                  value={JSON.stringify({
                    ...e,
                    discount: item.discount,
                    discountType: "discount",
                  })}
                >
                  {e.value} {e.units} - Rs{" "}
                  {(e.price - e.price * (item.discount / 100)).toFixed(2)}
                </option>
              ))}
            {item.Clubweight?.map((e) => (
              <option
                key={`clubweight-${e.id}`}
                value={JSON.stringify({
                  ...e,
                  discount: item.clubdiscount,
                  discountType: "clubdiscount",
                })}
                disabled={!user || user.club === false}
                style={{
                  color: !user || user.club === false ? "#aaa" : "inherit",
                }}
              >
                {e.value} {e.units} - Rs{" "}
                {(e.price - e.price * (item.clubdiscount / 100)).toFixed(2)}
                {!user?.club && " (Club Members Only)"}
              </option>
            ))}
          </select>

          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2 border rounded-lg p-1 bg-white">
              <button
                onClick={() => handleRemoveFromCart(item)}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <Minus size={16} />
              </button>
              <span className="w-8 text-center">{itemQuantity}</span>
              <button
                onClick={() => handleQuantityChange(item, 1)}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <Plus size={16} />
              </button>
            </div>
          </div>
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => handleAddToCart(item)}
              className="flex-1 text-white px-4 py-2 rounded-lg flex items-center justify-center gap-2 hover:bg-primary/90"
              style={{ backgroundColor: "#5C870C" }}
            >
              <ShoppingCart size={18} />
              Add to Cart
            </button>
            {item.subscribe === "Subscription" && (
              <button
                onClick={() => handleSubscribe(item)}
                className="flex-1 border border-primary px-4 py-2 rounded-lg flex items-center justify-center gap-2 hover:bg-primary/5"
                style={{ color: "white", backgroundColor: "#0c363b" }}
              >
                <Calendar size={18} />
                Subscribe
              </button>
            )}
          </div>

          <Paper elevation={3} sx={{ p: 3 }}>
            <Tabs
              value={tabValue}
              onChange={(_, newValue) => setTabValue(newValue)}
            >
              <Tab
                label="Description"
                style={{ color: "#5C870C", borderBottomColor: "#5C870C" }}
              />
            </Tabs>
            <Box sx={{ mt: 2 }}>{parse(item.description)}</Box>
          </Paper>
        </div>
      </div>
      <div>
        <h2
          className="mx-5 text-3xl font-bold mt-10 mb-4"
          style={{ color: "#45595b" }}
        >
          Recently added products
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {data.slice(0, 5)?.map((val) => {
            const currentSelectedWeight = selectedWeight[val._id];
            const itemQuantity1 = itemQuantities[val._id] || 1;
            return (
              <div
                key={val._id}
                className="box border rounded-lg border-orange-400 mx-auto w-full  max-w-xs shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white"
                style={{ height: "300px", marginBottom: "5px" }}
              >
                <Badge.Ribbon text={val.tag} color="orange">
                  <div className="relative overflow-clip">
                    <Link to={`/shop-detail/${val._id}`}>
                      <img
                        className="hover:scale-105 transition-transform duration-500 ease-in-out w-full rounded-lg shadow-lg"
                        src={`https://parnetslink.xyz/product/${val.Photos[0]?.file}`}
                        alt="Product"
                        style={{
                          height: "130px",
                          width: "350px",
                          marginTop: "10px",
                          objectFit: "contain",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                        onClick={() => scrollToTop(val)}
                      />
                    </Link>

                    {/* <div
                    className="absolute top-1 text-white py-1 px-1 rounded-xl tag"
                    style={{ fontSize: "13px" }}
                  >
                    {val.tag}
                  </div> */}
                  </div>
                </Badge.Ribbon>
                <div className="text-center">
                  <Link to={`/shop-detail/${val._id}`}>
                    <h3
                      className="text-l mt-4 font-semibold"
                      style={{ color: "#45595b" }}
                      onClick={() => scrollToTop(val)}
                    >
                      {val.productname.slice(0, 20)}
                    </h3>
                  </Link>
                  <select
                    name="option[504]"
                    id="input-option504"
                    className="form-control1 weight resp-select-box"
                    style={{
                      maxWidth: "200px",
                      fontSize: "12px",
                      border: "1px solid #88ac46",
                    }}
                    onChange={(e) => {
                      const selectedWeightData = JSON.parse(e.target.value);

                      const selectedDiscount =
                        selectedWeightData.discountType === "discount"
                          ? val.discount
                          : val.clubdiscount;
                      setSelectedWeight((prev) => ({
                        ...prev,
                        [val._id]: {
                          ...selectedWeightData,
                          discount: selectedDiscount,
                          discountType: selectedWeightData.discountType,
                        },
                      }));
                    }}
                    value={JSON.stringify(currentSelectedWeight)}
                  >
                    {!user?.club &&
                      val.weight?.map((e) => (
                        <option
                          key={`weight-${e.id}`}
                          style={{ fontSize: "12px" }}
                          value={JSON.stringify({
                            ...e,
                            discount: val.discount,
                            discountType: "discount",
                          })}
                        >
                          {e.value} {e.units} - Rs
                          {(e.price - e.price * (val.discount / 100)).toFixed(
                            2
                          )}
                        </option>
                      ))}
                    {val.Clubweight?.map((e) => (
                      <option
                        key={`clubweight-${e.id}`}
                        value={JSON.stringify({
                          ...e,
                          discount: val.clubdiscount,
                          discountType: "clubdiscount",
                        })}
                        disabled={!user || user.club === false}
                        style={{
                          color:
                            !user || user.club === false ? "#aaa" : "inherit",
                          fontSize: "12px",
                        }}
                      >
                        {e.value} {e.units} - Rs{" "}
                        {(e.price - e.price * (val.clubdiscount / 100)).toFixed(
                          2
                        )}{" "}
                         {!user?.club && " (Club Members Only)"}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <div className="flex-grow flex justify-center">
                    {val.subscribe === "Subscription" ? (
                      <button
                        onClick={() => handleAddToCart(val)}
                        className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                        style={{ backgroundColor: "#5C870C", color: "#fff" }}
                      >
                        Buy Once
                      </button>
                    ) : (
                      <>
                        <div className="flex items-center mr-2">
                          <button
                            className="px-2 py-1 text-sm font-semibold"
                            style={{
                              backgroundColor: "#ffb524",
                              color: "#fff",
                              borderRadius: "50%",
                            }}
                            onClick={() => handleRemoveFromCart(val)}
                          >
                            -
                          </button>
                          <span className="px-3">{itemQuantity1}</span>
                          <button
                            className="px-2 py-1 text-sm font-semibold"
                            style={{
                              backgroundColor: "#ffb524",
                              color: "#fff",
                              borderRadius: "50%",
                            }}
                            onClick={() => handleQuantityChange(val, 1)}
                          >
                            +
                          </button>
                        </div>
                        <button
                          onClick={() => handleAddToCart(val)}
                          className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                          style={{ backgroundColor: "#5C870C", color: "#fff" }}
                        >
                          Add to cart
                        </button>
                      </>
                    )}
                  </div>
                  {val.subscribe === "Subscription" && (
                    <button
                      onClick={() => handleSubscribe(val)}
                      className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                      style={{
                        backgroundColor: "#0c363b",
                        marginRight: "15px",
                      }}
                    >
                      Subscribe
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShopDetail;
