import React, { useState } from 'react';
import './Account.css';

function Wishlist1() {
  const [activeTab, setActiveTab] = useState('Wishlist');

  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return <Profile />;
      case 'orders':
        return <Orders />;
      case 'transactions':
        return <Transactions />;
        case 'wishlist':
        return <Wishlist />;
      default:
        return <Wishlist />;
    }
  };

  return (
    <div className="App1">
      <header className='header1'>
        <h1>Account Dashboard</h1>
      </header>
      <nav className='nav1'>
        <ul>
          <li><a href="#" onClick={() => setActiveTab('profile')}>Profile</a></li>
          <li><a href="#" onClick={() => setActiveTab('orders')}>Orders</a></li>
          <li><a href="#" onClick={() => setActiveTab('transactions')}>Transactions</a></li>
          <li><a href="#" onClick={() => setActiveTab('wishlist')}>Wishlist</a></li>
        </ul>
      </nav>
      <div className="container1">
        {renderContent()}
      </div>
    </div>
  );
}

function Profile() {
  return (
    <section className="profile">
      <h2>Profile</h2>
      <p>Name: John</p>
      <p>Email: email@gmail.com</p>
      <p>Address: 123 Main , Karnataka, India</p>
    </section>
  );
}

function Orders() {
  const orders = [
    { id: 1, date: '2024-08-01', total: 100.00, status: 'Delivered' },
    { id: 2, date: '2024-07-15', total: 75.50, status: 'Shipped' },
    { id: 3, date: '2024-07-01', total: 200.00, status: 'Processing' },
  ];

  return (
    <section className="orders">
      <h2>Orders</h2>
      <table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Date</th>
            <th>Total</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.date}</td>
              <td>₹{order.total?.toFixed(2)}</td>
              <td>{order.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

function Transactions() {
  const transactions = [
    { id: 1, date: '2024-08-01', amount: 100.00, type: 'Purchase' },
    { id: 2, date: '2024-07-15', amount: 75.50, type: 'Purchase' },
    { id: 3, date: '2024-07-01', amount: 25.00, type: 'Refund' },
  ];

  return (
    <section className="transactions">
      <h2>Transactions</h2>
      <table>
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map(transaction => (
            <tr key={transaction.id}>
              <td>{transaction.id}</td>
              <td>{transaction.date}</td>
              <td>₹{transaction.amount?.toFixed(2)}</td>
              <td>{transaction.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
function Wishlist() {
    const wishlistItems = [
      { id: 1, name: 'Milk', price: 99.99, image: 'https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=360/app/assets/products/sliding_images/jpeg/e3f63e12-631f-4193-9160-ea4ddcbd6c5c.jpg?ts=1711189562' },
      { id: 2, name: 'Apple', price: 199.99, image: 'https://freshindiaorganics.com/cdn/shop/products/Apples.jpg?v=1686739530' },
      { id: 3, name: 'onion', price: 249.99, image: 'https://m.economictimes.com/thumb/msid-107845571,width-1200,height-900,resizemode-4,imgsize-178900/how-to-shop-and-store-onion.jpg' },
    ];
  
    return (
      <section className="wishlist">
        <h2>Wishlist</h2>
        <div className="wishlist-grid">
          {wishlistItems.map(item => (
            <div key={item.id} className="wishlist-item">
              <img src={item.image} alt={item.name} style={{height:"200px"}}/>
              <h3>{item.name}</h3>
              <p>${item.price?.toFixed(2)}</p>
              <button className='button1' style={{backgroundColor:"#5C870C"}}>Add to Cart</button>
              <button className="remove-btn">Remove</button>
            </div>
          ))}
        </div>
      </section>
    )}
export default Wishlist1;