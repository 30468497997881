import React, { useState } from "react";
import "./login.css";
import axios from "axios";

import { Container, Form, Modal, Row, Table, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
export default function ClubLoginRegister() {
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState();

  const [amobile, setamobile] = useState();
  const [houseno, sethouseno] = useState("");
  const [landmark, setlandmark] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [postcode, setpostcode] = useState("");
  const [deliveryZone, setdeliveryZone] = useState("");
  const [paymentShow, setPaymentShow] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [Username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(true);
  const [successshow, setsuccessshow] = useState(false);
  const handlesuccessClose = () => setsuccessshow(false);
  const handlesuccess = () => setsuccessshow(true);

  const [errorshow, seterrorshow] = useState(false);
  const handleerrorClose = () => seterrorshow(false);
  const handleerror = () => seterrorshow(true);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const isPasswordValid = (password) => {
    if (password.length < 8) return false;
    if (!/[A-Z]/.test(password)) return false;
    if (!/[a-z]/.test(password)) return false;
    if (!/\d/.test(password)) return false;
    return true;
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@gmail\.com$/;
    return emailRegex.test(email);
  };
  const isMobileValid = (mobile) => {
    const mobileRegex = /^[6-9][0-9]{9}$/;
    return mobileRegex.test(mobile);
  };

  const isPincodeValid = (postcode) => {
    const pincodeRegex = /^[0-9]{6}$/;
    return pincodeRegex.test(postcode);
  };
  const handlePayment = () => {
    setPaymentComplete(true);
    setPaymentShow(false);
    handleClick(); 
  };

  const handleClick = async () => {
    // if (!Username?.trim()) {
    //   toast.info("Please enter your name."
    //   );
    // } else if (!mobile?.trim()) {
    //   toast.info("Please enter your mobile number."
    //   );
    // } else 
    if (!isMobileValid(mobile)) {
      toast.info("Please enter a valid mobile number."
      );
    } else if (!isMobileValid(amobile)) {
      toast.info("Please enter a valid alternate mobile number."
      );
    } else if (!isEmailValid(email)) {
      toast.info("Please enter a valid email address (e.g., user@gmail.com)."
      );
    } else if (!houseno?.trim()) {
      toast.info("Please enter your house number."
      );
    } else if (!address1?.trim()) {
      toast.info("Please enter your address line 1."
      );
    } else if (!landmark?.trim()) {
      toast.info("Please enter a landmark."
      );
    } else if (!postcode?.trim()) {
      toast.info("Please enter your postal code."
      );
    } else if (!isPincodeValid(postcode)) {
      toast.info("Please enter a valid postal code."
      );
    } else if (!isPasswordValid(password)) {
     
      toast.info("Use a minimum of 8 characters, including uppercase letters, lowercase letters, and numbers."
      );
    } else if (password !== cpassword) {
      toast.info("Passwords don't match"
      );
    } else if (!paymentComplete) {
      setPaymentShow(true); 
    } else {
      try {
        const config = {
          url: "/registerclubUser",
          method: "post",
          baseURL: "https://parnetslink.xyz/api/user",
          headers: { "content-type": "application/json" },
          data: {
            mobile,
            Amobile: amobile,
            Houseno: houseno,
            address1,
            address2,
            landmark,
            pincode: postcode,
            // deliveryzone: deliveryZone,
            email,
            password,
            name: Username,
            cpassword,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            toast.info("Registered Successfully"
            );
            window.location.assign("/login");
          }
        });
      } catch (error) {
        console.error(error);
        toast.info(error.response.data.error
        );
      }
    }
  };

  return (
    <div>
       <ToastContainer />
      <div className="logo text-center"></div>
      <div className="wrapper1">
        <div className="inner-wrapper1 text-center">
          <h4 className="title">Register Your Club Account </h4>

          <div className="input-row">
            <div className="input-group">
              <input
                className="form-control1"
                name="userName"
                id="userName"
                type="text"
                placeholder="User Name"
                onChange={(e) => setUsername(e.target.value)}
                value={Username}
              />
              <span className="lighting"></span>
            </div>

            <div className="input-group">
              <input
                className="form-control1"
                name="email"
                id="email"
                type="email"
                placeholder="Email"
                onChange={(e) => setemail(e.target.value)}
                value={email}
              />
              <span className="lighting"></span>
            </div>
            <div className="input-group">
              <input
                className="form-control1"
                name="phoneNumber"
                id="phoneNumber"
                type="tel"
                placeholder="Phone Number"
                onChange={(e) => setmobile(e.target.value)}
                value={mobile}
              />
              <span className="lighting"></span>
            </div>
          </div>
          <div className="input-row">
            <div className="input-group">
              <input
                className="form-control1"
                name="AphoneNumber"
                id="AphoneNumber"
                type="tel"
                placeholder="Alternative Phone Number"
                onChange={(e) => setamobile(e.target.value)}
                value={amobile}
              />
              <span className="lighting"></span>
            </div>
            <div className="input-group">
              <input
                className="form-control1"
                name="HouseNumber"
                id="HouseNumber"
                type="text"
                placeholder="House No*"
                onChange={(e) => sethouseno(e.target.value)}
                value={houseno}
              />
              <span className="lighting"></span>
            </div>
            <div className="input-group">
              <input
                className="form-control1"
                name="address1"
                id="address1"
                type="text"
                placeholder="Address Line 1"
                onChange={(e) => setaddress1(e.target.value)}
                value={address1}
              />
              <span className="lighting"></span>
            </div>
          </div>
          <div className="input-row">
            <div className="input-group">
              <input
                className="form-control1"
                name="address2"
                id="address2"
                type="text"
                placeholder="Address Line 2"
                onChange={(e) => setaddress2(e.target.value)}
                value={address2}
              />
              <span className="lighting"></span>
            </div>
            <div className="input-group">
              <input
                className="form-control1"
                name="landmark"
                id="landmark"
                type="text"
                placeholder="Landmark"
                onChange={(e) => setlandmark(e.target.value)}
                value={landmark}
              />
              <span className="lighting"></span>
            </div>
            <div className="input-group">
              <input
                className="form-control1"
                name="postcode"
                id="postcode"
                type="number"
                placeholder="Post Code"
                onChange={(e) => setpostcode(e.target.value)}
                value={postcode}
              />
              <span className="lighting"></span>
            </div>
          </div>
          <div className="input-row">
            {/* <div className="input-group">
              <select
                className="form-control1 delivery-zone-select"
                name="deliveryZone"
                id="deliveryZone"
                placeholder="Delivery Zone"
                onChange={(e) => setdeliveryZone(e.target.value)}
                value={deliveryZone}
              >
                <option value="">Delivery Zone</option>
                <option value="zone1">Zone 1</option>
                <option value="zone2">Zone 2</option>
                <option value="zone3">Zone 3</option>
              
              </select>
              <span className="lighting"></span>
            </div> */}
            <div
              className="input-group"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                className="form-control1"
                name="password"
                id="password"
                type={!showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
                style={{ flex: 1 }}
              />
              <i
                className={!showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              ></i>
              <span className="lighting"></span>
            </div>

            <div
              className="input-group"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                className="form-control1"
                name="cpassword"
                id="cpassword"
                type={!showPassword1 ? "text" : "password"}
                placeholder="Confirm Password"
                onChange={(e) => setcpassword(e.target.value)}
                value={cpassword}
                style={{ flex: 1 }}
              />
              <i
                className={!showPassword1 ? "fas fa-eye-slash" : "fas fa-eye"}
                onClick={togglePasswordVisibility1}
                style={{ cursor: "pointer" }}
              ></i>
              <span className="lighting"></span>
            </div>
          </div>
          <br />
          <button
            className="button1"
            style={{ width: "300px", marginLeft: "auto", marginRight: "auto" }}
            type="submit"
            id="register"
            onClick={handleClick}
          >
            Register
          </button>
        </div>
        <div className="signup-wrapper1 text-center">
          <a href="/login">
            Already have an account?{" "}
            <span className="text-primary">Sign In</span>
          </a>
        </div>
      </div>
      <Modal show={paymentShow} onHide={() => setPaymentShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Complete Your Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please make a payment of 200 INR to complete your registration.</p>
          <Button
            className="button1"
            style={{ width: "100px", padding: "10px" }}
            onClick={handlePayment}
          >
            Pay Now
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={successshow}
        onHide={handlesuccess}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handlesuccessClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src="../img/success.png"
              style={{ margin: "auto" }}
              width="100px"
            />

            <p>Login Successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        onHide={handleerror}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleerrorClose}></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src="../img/close.png"
              style={{ margin: "auto" }}
              width="100px"
            />

            <p>Error Occured</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
