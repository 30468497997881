import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, Table, Modal, message, Typography } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Trash2 } from 'lucide-react';
import axios from 'axios';

const { Title } = Typography;
const API_BASE_URL = 'https://parnetslink.xyz/api/admin';

const AddClubBanner = () => {
  const token = sessionStorage.getItem("token");
  const [clubBanners, setClubBanners] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchClubBanners();
  }, []);

  const fetchClubBanners = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getallclubbanner`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      setClubBanners(response.data.clubbanner);
    } catch (error) {
      message.error('Failed to fetch club banners');
    }
  };

  const handleAddClubBanner = async (values) => {
    try {
      const formData = new FormData();
      formData.append('clubbanner', values.clubbanner.file);
      formData.append('type', values.type);

      await axios.post(`${API_BASE_URL}/addclubbanner`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Club banner added successfully');
      setIsAddModalVisible(false);
      form.resetFields();
      fetchClubBanners();
    } catch (error) {
      message.error('Failed to add club banner');
    }
  };

  const handleDeleteClubBanner = async (id) => {
    try {
      await axios.post(`${API_BASE_URL}/deleteclubbanner/${id}`,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      message.success('Club banner deleted successfully');
      fetchClubBanners();
    } catch (error) {
      message.error('Failed to delete club banner');
    }
  };

  const columns = [
    { title: 'Serial No.', dataIndex: 'serialNo', key: 'serialNo' },
    { title: 'Title', dataIndex: 'type', key: 'type' },
    {
      title: 'Club Banner',
      dataIndex: 'clubbanner',
      key: 'clubbanner',
      render: (clubbanner) => (
        <img src={`https://parnetslink.xyz/image/${clubbanner}`} alt="Club Banner" style={{ width: 100 }} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type="text"
          icon={<Trash2 color="red" />}
          onClick={() => handleDeleteClubBanner(record._id)}
        />
      ),
    },
  ];

  const dataSource = clubBanners.map((banner, index) => ({
    key: banner._id,
    serialNo: index + 1,
    type: banner.type,
    clubbanner: banner.clubbanner,
    _id: banner._id,
  }));

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Title level={3} className="title">Club Banners</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
          Add Club Banner
        </Button>
      </div>

      <Table 
        columns={columns} 
        dataSource={dataSource}
        pagination={{
          pageSize: 5,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  ...props.style,
                  background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                  color: "white",
                }}
              />
            ),
          },
        }}
      />

      <Modal
        title="Add Club Banner"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddClubBanner} layout="vertical">
          <Form.Item
            name="clubbanner"
            label="Club Banner Image"
            rules={[{ required: true, message: 'Please upload a club banner image' }]}
          >
            <Upload accept="image/*" listType="picture-card" maxCount={1} beforeUpload={() => false}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item
            name="type"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Club Banner
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddClubBanner;