import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import parse from "html-react-parser";

const Testimonial = () => {
    const token = sessionStorage.getItem("token");
    const [data1, setdata1] = useState([]);
    const getTestimonials = async () => {
        try {
          const res = await axios.get(
            "https://parnetslink.xyz/api/admin/getTestimonials",{ headers: {
                Authorization: `Bearer ${token}`,
              },}
          );
          if (res.status === 200) {
            setdata1(res.data.testimonials);
          }
        } catch (error) {
          console.error(error);
        }
      };

    useEffect(() => {
        getTestimonials();
    }, []);

    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const testimonialChunks = chunkArray(data1, 2); // Display one testimonial per slide on mobile
    return (
        <div className='mt-10 mx-4 sm:mx-20'>
            <p className='text-center font-semibold text-xl lg:text-2xl' style={{color: '#5C870C'}}>Our Testimonial</p>
            <h1 className='text-center font-bold text-3xl mt-2 md:text-4xl lg:text-5xl' style={{color: '#45595b'}}>Our Client Saying!</h1>
           
            <Carousel 
                showArrows={true} 
                infiniteLoop={true} 
                showIndicators={false}
                autoPlay={true} 
                interval={5000} 
                showThumbs={false}
                className='mt-10'
                emulateTouch={true}
                swipeable={true}
            >
                {testimonialChunks.map((chunk, index) => (
                    <div key={index} className='flex flex-col md:flex-row md:gap-3'>
                        {chunk.map(val => (
                        <div key={val.id} className='rounded-lg w-full md:w-1/2' style={{backgroundColor: '#f4f6f8'}}>
                        <div className='flex flex-col md:flex-row justify-between items-center p-5'>
                            {/* Left side: Image, name, profession, and rating */}
                            <div className='flex items-center mb-4 md:mb-0'>
                                <img className='rounded-lg w-20 h-20 md:w-24 md:h-24' src={`https://parnetslink.xyz/image/${val.profileImage}`} alt="" />
                                <div className='ml-4'>
                                    <h3 className='text-lg font-semibold' style={{color: '#45595b'}}>{val.name}</h3>
                                    <p className='text-sm' style={{color: '#45595b'}}>{val.profession}</p>
                                    <ul className='flex'>
                                        {[...Array(5)].map((_, index) => (
                                            <span key={index} style={{color: index < val.rating ? '#5C870C' : '#45595b'}}>&#9733;</span>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                    
                            {/* Right side: Message */}
                            <div className='flex-1 text-center md:text-left'>
                                <div className='flex justify-center md:justify-end'>
                                    <span className='flex justify-center items-center'><i className='fa fa-quote-right fa-2x' style={{color: '#ffb524'}}></i></span>
                                </div>
                                <p className='mt-4 md:ml-6' style={{color: '#45595b'}}>{parse(val.message)}</p>
                            </div>
                        </div>
                    
                        <hr className='w-10/12 m-auto h-0.5 xl:h-1' style={{backgroundColor: '#ffb524'}}/>
                    </div>
                     ))}
                     </div>
                 ))}
            </Carousel>
        </div>
    );
}

export default Testimonial;
