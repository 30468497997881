import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

export default function Wallet() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [balance, setBalance] = useState(0);

  const [transactions, setTransactions] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
const token = sessionStorage.getItem("token");
  useEffect(() => {
  
    const fetchWalletData = async () => {
      try {
        const response = await axios.get(`https://parnetslink.xyz/api/user/getwallet/${user?._id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBalance(response.data.success.balance);
        setTransactions(response.data.success.transactions);
      } catch (error) {
        console.error('Error fetching wallet data:', error);
      }
    };

    fetchWalletData();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="main-content">
      <h1 className="wallet">My Wallet</h1>
      <p className="wallet-description">
        The Abhinandhan organics Wallet is a pre-paid credit account that is associated with your Abhinandhan organics account. This prepaid account allows you to pay a lump sum amount once to Abhinandhan organics and then shop multiple times without having to pay each time.
      </p>
      <div className="balance-section">
        <span>Current Balance: Rs.{balance?.toFixed(2)}</span>
      
        <button className="topup-btn" onClick={openModal}>Topup Your Wallet</button>
      </div>
      <h2>Wallet Activity</h2>
      <table className="wallet-activity">
        <thead>
          <tr>
            <th>SR NO.</th>
            <th>DATE</th>
            <th>TRANSACTION DETAIL</th>
            <th>CREDIT</th>
            <th>DEBIT</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={transaction?._id}>
              <td>{index + 1}</td>
              <td>{transaction?.date ? new Date(transaction?.date).toLocaleDateString() : 'N/A'}</td>
              <td>{transaction?.detail}</td>
              <td>{`Rs.${transaction?.credit}`}</td>
              <td>{`Rs.${transaction?.debit}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && <TopupModal closeModal={closeModal} setBalance={setBalance}  setTransactions={setTransactions} />}
    </div>
  );
}

function TopupModal({ closeModal, setBalance, setTransactions }) {
  const [amount, setAmount] = useState('');
const token = sessionStorage.getItem("token");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const handleSubmit = async () => {
    try {
      const response = await fetch('https://parnetslink.xyz/api/user/topup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        
          headers: {
            Authorization: `Bearer ${token}`,
          },
        
        body: JSON.stringify({ userId: user?._id , amount: parseFloat(amount) }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setBalance(data.balance);
          
        setTransactions((prevTransactions) => [data.transaction, ...prevTransactions]);
      } else {
        const errorData = await response.json();
        console.error('Top-up failed:', errorData.error);
        toast.info(`${errorData.error}`
        );
      }
    } catch (error) {
      console.error('Error topping up the wallet:', error);
      toast.info(`${error.message}`);
    } finally {
      closeModal();
    }
  };

  return (
    <div className="modal2-overlay">
       <ToastContainer />
      <div className="modal2">
        <h2>Topup Your Wallet</h2>
        <form>
          <label htmlFor="amount">Enter Amount (Rs):</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            min="1"
            required
          />
          <div className="modal2-buttons">
            <button type="submit" onClick={handleSubmit}>Add Money</button>
            <button type="button" onClick={closeModal}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}
