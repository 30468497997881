import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const Hero = () => {
  const token = sessionStorage.getItem("token");
  const [banner, setBanner] = useState([]);
const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const getBanner = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getallbanner",{ headers: {
          Authorization: `Bearer ${token}`,
        },}
      );
      if (res.status === 200) {
        setBanner(res.data?.banner);
      }
    } catch (error) {
      console.error("Error fetching banner:", error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);
  const scrollToTop1 = () => {
    navigate(`/shop`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="relative w-full">
      <Slider {...settings}>
        {banner?.map((item, index) => (
          <div key={index} className="relative">
            {/* Adjust height based on screen size */}
            <div className="h-[300px] sm:h-[400px] md:h-[420px] lg:[500px]">
              <img
                className="w-full h-full object-cover"
                src={`https://parnetslink.xyz/image/${item.banner}`}
                alt="Dairy Products"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4">
                <div className="text-center text-white">
                  {/* Responsive text sizes */}
                  <h4
                    className="text-lg sm:text-xl lg:text-2xl font-semibold"
                    style={{ color: "#ffb524" }}
                  >
                    100% Organic Foods
                  </h4>
                  <h1
                    className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold mt-2 md:mt-4 mb-4 md:mb-10"
                    style={{ color: "#9CCD42" }}
                  >
                    {item.type}
                  </h1>
                  <button
                    className="bg-[#5c870c] hover:bg-[#5c870c] text-white font-bold 
                                                     py-1.5 sm:py-2 px-3 sm:px-4 rounded-full text-sm sm:text-base
                                                     transition duration-300 ease-in-out transform hover:scale-105"
                    type="button"
                    onClick={() => scrollToTop1()}
                  >
                    Shop Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Hero;
