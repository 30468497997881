import React, { useEffect, useState } from "react";
import Back from "./common/Back";

import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { addToCart1, removeFromCart1 } from "../redux/CartSlice1";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Badge } from "antd";

const Scheduled = () => {
  const dispatch = useDispatch();
const token = sessionStorage.getItem("token");
  const cart = useSelector((state) => state.cart1);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const location = useLocation();
  const { title } = location?.state || "";
  const [openCategory, setOpenCategory] = useState(null);
  const [categorydata, setcategorydata] = useState([]);
  const [subcategorydata, setsubcategorydata] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(title);
  const [selectedsubCategory, setSelectedsubCategory] = useState("");

  const handleToggle = (category) => {
    setOpenCategory(openCategory === category ? null : category);
    setSelectedCategory(category);
    setSelectedsubCategory("");
    setCurrentPage(1);
  };
  const handleToggle1 = (category) => {
    setSelectedsubCategory(category);
    setCurrentPage(1);
  };
  const getcategory = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getCategory",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if ((res.status = 200)) {
      setcategorydata(
        res.data?.category.filter(
          (e) => e.categoryName !== "Fruits & Vegetables"
        )
      );
    }
  };

  const getsubcategory = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/getSubcategory",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if ((res.status = 200)) {
      setsubcategorydata(res.data?.Subcategory);
    }
  };

  useEffect(() => {
    getcategory();
    getsubcategory();
  }, []);
  const isWithinAvailableTime = () => {
    const now = new Date();
    const day = now.getDay();
    const hours = now.getHours();

    if ((day === 6 && hours >= 12) || day === 0 || (day === 1 && hours < 12)) {
      return true;
    }

    return false;
  };
  const getproducts = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/Getproductlist",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      const activeProducts = res.data.ProductList.filter(
        (item) => item.status === "Active"
      );
      setData(activeProducts);
    }
  };
  const getproducts1 = async () => {
    let res = await axios.get(
      "https://parnetslink.xyz/api/admin/Getfruitslist",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.status === 200) {
      const activeProducts = res.data.fruitsList?.filter(
        (item) => item.status === "Active"
      );

      const available = isWithinAvailableTime();
      setIsAvailable(available);

      const filteredProducts = activeProducts.filter((item) => {
        if (item.category === "Fruits & Vegetables") {
          return available;
        }
        return true;
      });

      setData1(filteredProducts);

      const initialSelectedWeight = {};
      filteredProducts.forEach((product) => {
        initialSelectedWeight[product._id] = !user?.club
          ? product.weight?.[0]
          : product.Clubweight?.[0];
      });
      setSelectedWeight(initialSelectedWeight);
    }
  };

  useEffect(() => {
    getproducts();
    getproducts1();
  }, []);
  const [selectedWeight, setSelectedWeight] = useState({});
  const [isAvailable, setIsAvailable] = useState(true);
  const [itemQuantities, setItemQuantities] = useState({});

  const handleQuantityChange = (item, change) => {
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 1) + change, 1),
    }));
  };

  const handleAddToCart = (item) => {
    const weightToAdd =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    const quantity = itemQuantities[item._id] || 1;
toast.info("Product added successfully")
    dispatch(
      addToCart1({
        ...item,
        selectedWeight: weightToAdd,
        quantity,
      })
    );
  };
  const handleRemoveFromCart = (item) => {
    const weightToRemove =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);

    dispatch(
      removeFromCart1({
        _id: item._id,
        selectedWeight: weightToRemove,
      })
    );

    // Update the itemQuantities state
    setItemQuantities((prev) => ({
      ...prev,
      [item._id]: Math.max((prev[item._id] || 0) - 1, 0),
    }));
  };
  const handleAddToCart1 = (item) => {
    const weightToAdd =
      selectedWeight[item._id] ||
      (!user?.club ? item.weight[0] : item.Clubweight[0]);
    dispatch(addToCart1({ ...item, selectedWeight: weightToAdd }));
  };
  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const data3 = [...data1, ...data];
  console.log(data1, "sddd");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data3.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data3.length / itemsPerPage);
  const navigate = useNavigate();

  const handleSubscribe = (val) => {
    const weightToAdd =
      selectedWeight[val._id] ||
      (!user?.club ? val.weight[0] : val.Clubweight[0]);
    if (!user) {
      toast.info("Please login as a user to subscribe.");
    } else {
      navigate(`/subscribe/${val._id}`, {
        state: { selectedWeight: weightToAdd },
        
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const scrollToTop = (val) => {
    navigate(`/shop-detail1/${val?._id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <ToastContainer />
      <Back title="Scheduled delivery will be delivered on Tuesday" />
      {!isAvailable && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded-lg shadow-md">
          <div
            className="flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div>
              <p className="text-sm leading-5 font-medium">
                Fruits & Vegetables orders are available from{" "}
                <strong>Saturday 12 PM</strong> to <strong>Monday 12 PM</strong>{" "}
                For orders, please contact us at <b>+91-XXXXXXXXXX</b>..
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="sm:mx-20 lg:flex 2xl:mx-40">
        <div
          className="grid grid-cols-1 gap-2 mt-10 md:grid-cols-3 xl:grid-cols-5 xl:gap-x-5 xl:gap-y-0"
          style={{ height: "auto" }}
        >
          {currentItems.map((val) => {
            const currentSelectedWeight = selectedWeight[val._id];
            const itemQuantity = itemQuantities[val._id] || 1;
            return (
              <Badge.Ribbon text={val.tag} color="orange">
                <div
                  key={val._id}
                  className="box border rounded-lg border-orange-400 mx-auto w-full  max-w-xs shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white"
                  style={{ height: "300px", marginBottom: "5px" }}
                >
                  <div
                    className="relative overflow-clip"
                  >
                    <img
                      className="hover:scale-105 transition-transform duration-500 ease-in-out w-full rounded-lg shadow-lg"
                      src={`https://parnetslink.xyz/product/${val.Photos[0]?.file}`}
                      alt="Product"
                      style={{
                        height: "130px",
                        width: "350px",
                        marginTop: "10px",
                        objectFit: "contain",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer"
                      }}
                    onClick={() => scrollToTop(val)}
                    />
                    {/* <span
                    className="absolute top-1 text-white py-1 px-1 rounded-xl"
                    style={{ backgroundColor: "#ffb524", fontSize: "13px" }}
                  >
                    {val.tag}
                  </span> */}
                  </div>
                  <div
                    className="text-center"
                  
                  >
                    <h3
                      className="text-l mt-4 font-semibold"
                      style={{ color: "#45595b",cursor: "pointer" }} 
                      onClick={() => scrollToTop(val)}
                    >
                      {val.productname.slice(0, 20)}
                    </h3>
                    <select
                      name="option[504]"
                      id="input-option504"
                      className="form-control1 weight resp-select-box"
                      style={{
                        maxWidth: "200px",
                        fontSize: "12px",
                        border: "1px solid #88ac46",
                      }}
                      onChange={(e) => {
                        const selectedWeightData = JSON.parse(e.target.value);

                        const selectedDiscount =
                          selectedWeightData.discountType === "discount"
                            ? val.discount
                            : val.clubdiscount;
                        setSelectedWeight((prev) => ({
                          ...prev,
                          [val._id]: {
                            ...selectedWeightData,
                            discount: selectedDiscount,
                            discountType: selectedWeightData.discountType,
                          },
                        }));
                      }}
                      value={JSON.stringify(currentSelectedWeight)}
                    >
                      {!user?.club &&
                        val.weight?.map((e) => (
                          <option
                            key={`weight-${e.id}`}
                            style={{ fontSize: "12px" }}
                            value={JSON.stringify({
                              ...e,
                              discount: val.discount,
                              discountType: "discount",
                            })}
                          >
                            {e.value} {e.units} - Rs{" "}
                            {(e.price - e.price * (val.discount / 100)).toFixed(
                              2
                            )}
                          </option>
                        ))}
                      {val.Clubweight?.map((e) => (
                        <option
                          key={`clubweight-${e.id}`}
                          value={JSON.stringify({
                            ...e,
                            discount: val.clubdiscount,
                            discountType: "clubdiscount",
                          })}
                          disabled={!user || user.club === false}
                          style={{
                            color:
                              !user || user.club === false ? "#aaa" : "inherit",
                            fontSize: "12px",
                          }}
                        >
                          {e.value} {e.units} - Rs{" "}
                          {(
                            e.price -
                            e.price * (val.clubdiscount / 100)
                          ).toFixed(2)}
                          {!user?.club && " (Club Members Only)"}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <div className="flex-grow flex justify-center">
                      {val.subscribe === "Subscription" ? (
                        <button
                          onClick={() => handleAddToCart(val)}
                          className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                          style={{ backgroundColor: "#5C870C", color: "#fff" }}
                        >
                          Buy Once
                        </button>
                      ) : (
                        <>
                          <div className="flex items-center mr-2">
                            <button
                              className="px-2 py-1 text-sm font-semibold"
                              style={{
                                backgroundColor: "#ffb524",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleRemoveFromCart(val)}
                            >
                              -
                            </button>
                            <span className="px-3">{itemQuantity}</span>
                            <button
                              className="px-2 py-1 text-sm font-semibold"
                              style={{
                                backgroundColor: "#ffb524",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleQuantityChange(val, 1)}
                            >
                              +
                            </button>
                          </div>
                          <button
                            onClick={() => handleAddToCart(val)}
                            className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                            style={{
                              backgroundColor: "#5C870C",
                              color: "#fff",
                            }}
                          >
                            Add to cart
                          </button>
                        </>
                      )}
                    </div>
                    {val.subscribe === "Subscription" && (
                      <button
                        onClick={() => handleSubscribe(val)}
                        className="font-semibold px-3 py-1 text-xs text-white rounded-2xl"
                        style={{
                          backgroundColor: "#0c363b",
                          marginRight: "15px",
                        }}
                      >
                        Subscribe
                      </button>
                    )}
                  </div>
                </div>
              </Badge.Ribbon>
            );
          })}
        </div>
      </div>
      <ul className="flex justify-center items-center mx-4 mt-20 next">
        <li
          className="py-2 px-4 mx-1 border border-orange-400 rounded-lg cursor-pointer"
          style={{ color: currentPage === 1 ? "#d1d5db" : "#45595b" }}
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &#8656;
        </li>
        {[...Array(totalPages)].map((_, index) => (
          <li
            key={index}
            className={`py-2 px-4 mx-1 border border-orange-400 rounded-lg cursor-pointer ${
              currentPage === index + 1 ? "bg-[#5c870c] text-white" : ""
            }`}
            style={{ color: "#45595b" }}
            onClick={() => handleClick(index + 1)}
          >
            {index + 1}
          </li>
        ))}
        <li
          className="py-2 px-4 mx-1 border border-orange-400 rounded-lg cursor-pointer"
          style={{ color: currentPage === totalPages ? "#d1d5db" : "#45595b" }}
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &#8658;
        </li>
      </ul>
    </div>
  );
};

export default Scheduled;
