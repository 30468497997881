import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Paper,
  Grid,
  Box,
  IconButton,
  Card,
  CardContent,
  Divider
} from '@mui/material';
import { Upload, Trash2, Plus, Image } from 'lucide-react';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Typography } from 'antd';
const { Title } = Typography;
const StyledDropzone = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  padding: theme.spacing(4),
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
  '&:hover': {
    borderColor: theme.palette.primary.main,
  }
}));

const PreviewItem = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const AddProduct = () => {
  // State management (keeping all original state variables)
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [tag, setTag] = useState([]);
  const [units, setUnits] = useState([]);
  const token = sessionStorage.getItem("token");
  
  // Form state (keeping all original state variables)
  const [title, setTitle] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [productTag, setProductTag] = useState('');
  const [discount, setDiscount] = useState('');
  const [clubDiscount, setClubDiscount] = useState('');
  const [stock, setStock] = useState('');
  const [miniStock, setMiniStock] = useState('');
  const [unit, setUnit] = useState('');
  const [miniUnit, setMiniUnit] = useState('');
  const [subscription, setSubscription] = useState('');
  const [productDesc, setProductDesc] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [newOption, setNewOption] = useState({ value: '', price: '', units: '' });
  const [previewList, setPreviewList] = useState([]);
  const [previewClubList, setPreviewClubList] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => setUploadedImages([...uploadedImages, ...acceptedFiles]),
    accept: 'image/*,video/*'
  });

  // API calls (keeping original functionality)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryRes, subCategoryRes, tagRes, unitRes] = await Promise.all([
          axios.get('https://parnetslink.xyz/api/admin/getCategory'),
          axios.get('https://parnetslink.xyz/api/admin/getSubcategory'),
          axios.get('https://parnetslink.xyz/api/admin/getTag'),
          axios.get('https://parnetslink.xyz/api/admin/getUnit')
        ],{ headers: {
          Authorization: `Bearer ${token}`,
        },});

        setCategoryData(categoryRes.data?.category.filter(e => e.categoryName !== 'Fruits & Vegetables'));
        setSubCategoryData(subCategoryRes.data?.Subcategory);
        setTag(tagRes.data?.Tag);
        setUnits(unitRes.data?.Unit);
      } catch (error) {
        toast.error('Error fetching data');
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    // Validation
    if (!title || !productPrice || !productDesc || !category || !subCategory || !productTag) {
      toast.info('Please fill in all required fields.');
      return;
    }

    if (previewList.length === 0 || previewClubList.length === 0) {
      toast.info('Please add weight and price options for both normal and club members');
      return;
    }

    if (uploadedImages.length < 1) {
      toast.info('Please add at least one image');
      return;
    }

    try {
      const productData = {
        productname: title,
        price: productPrice,
        description: productDesc,
        category,
        subcategory: subCategory,
        tag: productTag,
        discount,
        clubdiscount: clubDiscount,
        stock,
        unit,
        miniunit: miniUnit,
        ministock: miniStock,
        weight: previewList,
        subscribe: subscription,
        Clubweight: previewClubList,
      };

      const response = await axios.post('https://parnetslink.xyz/api/admin/addProduct', productData,{ headers: {
        Authorization: `Bearer ${token}`,
      },});
      
      if (response.status === 200) {
        await uploadFiles(response.data.product?._id);
        toast.success('Product added successfully');
      }
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error adding product');
    }
  };

  const uploadFiles = async (id) => {
    const formData = new FormData();
    try {
      for (const image of uploadedImages) {
        formData.append('id', id);
        formData.append('Photos', image);
        await fetch('https://parnetslink.xyz/api/admin/addPhotos/', {
          method: 'post',
          body: formData
        },{ headers: {
          Authorization: `Bearer ${token}`,
        },});
      }
      window.location.reload();
    } catch (error) {
      toast.error('Error uploading images');
    }
  };
  const calculateDiscountedPrice = (price, discountPercentage) => {
    const discount = (price * discountPercentage) / 100;
    return (price - discount).toFixed(2);
  };

  const addToPreview = () => {
    if (newOption.value && newOption.price && newOption.units && discount && clubDiscount) {
      const normalDiscountedPrice = calculateDiscountedPrice(
        Number(newOption.price),
        Number(discount)
      );
      
      const clubDiscountedPrice = calculateDiscountedPrice(
        Number(newOption.price),
        Number(clubDiscount)
      );

      const newPreviewItem = {
        ...newOption,
        discountedPrice: normalDiscountedPrice,
        id: Date.now()
      };

      const newClubPreviewItem = {
        ...newOption,
        discountedPrice: clubDiscountedPrice,
        id: Date.now()
      };

      setPreviewList([...previewList, newPreviewItem]);
      setPreviewClubList([...previewClubList, newClubPreviewItem]);
      setNewOption({ value: '', price: '', units: '' });
    } else {
      toast.info('Please fill in all option details and discounts');
    }
  };

  const removeFromPreview = (id) => {
    setPreviewList(previewList.filter(item => item.id !== id));
  };

  const removeFromClubPreview = (id) => {
    setPreviewClubList(previewClubList.filter(item => item.id !== id));
  };
  return (
    <Box sx={{ p: 3 }}>
     
      <Title level={3} className="title">Add Product</Title>
      <Grid container spacing={3}>
        {/* Basic Information */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Basic Information</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Category"
                  >
                    <MenuItem value="">Select Category</MenuItem>
                    {categoryData.map((cat, idx) => (
                      <MenuItem key={idx} value={cat.categoryName}>
                        {cat.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Sub-Category</InputLabel>
                  <Select
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    label="Sub-Category"
                  >
                    <MenuItem value="">Select Sub-Category</MenuItem>
                    {subCategoryData
                      .filter((sub) => sub.categoryName === category)
                      .map((sub, idx) => (
                        <MenuItem key={idx} value={sub.SubcategoryName}>
                          {sub.SubcategoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Product Type</InputLabel>
                  <Select
                    value={subscription}
                    onChange={(e) => setSubscription(e.target.value)}
                    label="Product Type"
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Subscription">Subscription</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Product Details */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Product Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Product Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Product Price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Product Tag</InputLabel>
                  <Select
                    value={productTag}
                    onChange={(e) => setProductTag(e.target.value)}
                    label="Product Tag"
                  >
                    <MenuItem value="">Select Tag</MenuItem>
                    {tag.map((t, idx) => (
                      <MenuItem key={idx} value={t.tag}>
                        {t.tag}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Stock Management */}
        {/* <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Stock Management</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Stock"
                  value={stock}
                  onChange={(e) => setStock(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Stock Units</InputLabel>
                  <Select
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    label="Stock Units"
                  >
                    <MenuItem value="">Select Units</MenuItem>
                    {units.map((u, idx) => (
                      <MenuItem key={idx} value={u.unit}>
                        {u.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Minimum Stock"
                  value={miniStock}
                  onChange={(e) => setMiniStock(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Minimum Stock Units</InputLabel>
                  <Select
                    value={miniUnit}
                    onChange={(e) => setMiniUnit(e.target.value)}
                    label="Minimum Stock Units"
                  >
                    <MenuItem value="">Select Units</MenuItem>
                    {units.map((u, idx) => (
                      <MenuItem key={idx} value={u.unit}>
                        {u.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}

        {/* Pricing Options */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Pricing Options</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Normal Member Discount (%)"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Club Member Discount (%)"
                  value={clubDiscount}
                  onChange={(e) => setClubDiscount(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Weight/Volume"
                    value={newOption.value}
                    onChange={(e) => setNewOption({ ...newOption, value: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Units</InputLabel>
                    <Select
                      value={newOption.units}
                      onChange={(e) => setNewOption({ ...newOption, units: e.target.value })}
                      label="Units"
                    >
                      <MenuItem value="">Select Units</MenuItem>
                      {units.map((u, idx) => (
                        <MenuItem key={idx} value={u.unit}>
                          {u.unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Price"
                    value={newOption.price}
                    onChange={(e) => setNewOption({ ...newOption, price: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    startIcon={<Plus />}
                    fullWidth
                    onClick={addToPreview}
                    sx={{ height: '56px' }}
                    
                    style={{backgroundColor:"#5C870C"}}
                  >
                    Add Option
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* Preview Lists */}
            {previewList.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Normal Members Preview</Typography>
                {previewList.map((option) => (
                  <PreviewItem key={option.id}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>
                        {option.value} {option.units} - Rs {option.price} (Discounted: Rs {option.discountedPrice})
                      </Typography>
                      <IconButton onClick={() => removeFromPreview(option.id)} color="error">
                        <Trash2 />
                      </IconButton>
                    </CardContent>
                  </PreviewItem>
                ))}
              </Box>
            )}
               {previewClubList.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Club Members Preview</Typography>
                {previewClubList.map((option) => (
                  <PreviewItem key={option.id}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>
                        {option.value} {option.units} - Rs {option.price} (Discounted: Rs {option.discountedPrice})
                      </Typography>
                      <IconButton onClick={() => removeFromClubPreview(option.id)} color="error">
                        <Trash2 />
                      </IconButton>
                    </CardContent>
                  </PreviewItem>
                ))}
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Description */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Product Description</Typography>
            <CKEditor
             editor={ClassicEditor}
             data={productDesc}
             onChange={(event, editor) => {
               const data = editor.getData();
               setProductDesc(data);
             }}
           />
         </Paper>
       </Grid>

       {/* Image Upload */}
       <Grid item xs={12}>
         <Paper sx={{ p: 3 }}>
           <Typography variant="h6" gutterBottom>Product Images</Typography>
           
           <StyledDropzone {...getRootProps()}>
             <input {...getInputProps()} />
             <Upload size={40} className="mx-auto mb-2" />
             <Typography>
               Drag & drop files here, or click to select files
             </Typography>
             <Typography variant="body2" color="textSecondary">
               Supports images and videos
             </Typography>
           </StyledDropzone>

           {uploadedImages.length > 0 && (
             <Grid container spacing={2}>
               {uploadedImages.map((file, index) => (
                 <Grid item xs={6} sm={4} md={3} key={index}>
                   <Card>
                     <Box sx={{ position: 'relative' }}>
                       {file.type.startsWith('video/') ? (
                         <video
                           src={URL.createObjectURL(file)}
                           controls
                           style={{
                             width: '100%',
                             height: '200px',
                             objectFit: 'cover'
                           }}
                         />
                       ) : (
                         <Box
                           component="img"
                           src={URL.createObjectURL(file)}
                           alt={`Upload ${index + 1}`}
                           sx={{
                             width: '100%',
                             height: '200px',
                             objectFit: 'cover'
                           }}
                         />
                       )}
                       <IconButton
                         sx={{
                           position: 'absolute',
                           top: 8,
                           right: 8,
                           backgroundColor: 'rgba(255, 255, 255, 0.8)',
                           '&:hover': {
                             backgroundColor: 'rgba(255, 255, 255, 0.9)'
                           }
                         }}
                         onClick={() => {
                           const newImages = [...uploadedImages];
                           newImages.splice(index, 1);
                           setUploadedImages(newImages);
                         }}
                       >
                         <Trash2 size={20} />
                       </IconButton>
                     </Box>
                   </Card>
                 </Grid>
               ))}
             </Grid>
           )}
         </Paper>
       </Grid>

       {/* Submit Button */}
       <Grid item xs={12}>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
           <Button
             variant="contained"
             size="large"
             onClick={handleSubmit}
             startIcon={<Plus />}
             
             style={{backgroundColor:"#5C870C"}}
           >
             Add Product
           </Button>
         </Box>
       </Grid>
     </Grid>

     {/* Helper functions */}
    
   </Box>
 );
};

export default AddProduct;